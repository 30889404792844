import axios from "axios";
const url = '/api';

export default class API {

    //Funcion que guarda la info en la BD 
    static async crearRegistroDeUsuario(data){
    
        const res = await axios({
            method: 'post',
            url: url + '/users',
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
        })

        return res.data;
    }
}