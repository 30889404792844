<template>
  <div class="home">
    <div class="img-sales"></div>

    <div class="container-fluid text-container pt-2">
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <button type="button" class="btn btn-danger fw-bold btn-lg"><router-link class="text-decoration-none text-reset" to="/registro">Registrarse</router-link></button>
        </div>
        <div class="col-lg-8 col-12">
          <img  class="img-fluid mx-auto d-block logoPpal" src="img/Logo.png" alt="sale">
          <h6 class="text-center mt-3 fw-semibold text-white text-uppercase">Válido del 5 al 15 de diciembre de 2024</h6>
          <p class="text-center"><small class="text-white">* Aplica términos y condiciones</small></p>
          <!-- <img  class="img-fluid mx-auto d-block mt-3" style="width: 800px;" src="img/marcas.svg" alt="AMERICANINO|CHEVIGNON|ESPRIT"> -->
        </div>
        <!-- <div class="col-12 d-flex justify-content-end pt-4">
          <a className="nav-link brown-txt text-white text-uppercase" href="#distribuidores">Conoce los distribuidores autorizados<img href="#distribuidores" src="img/arrow-down.svg" alt=""></a>
        </div> -->
      </div>
    </div>
    <div class="distribuidores py-5" id="distribuidores">
      <div class="container">
        <div class="row mx-0">
          <div class="col-12">
            <h3 class="text-center text-uppercase text-acmno mb-5">Distribuidores autorizados</h3>
            <div class="table-responsive">
              <table id="dataTable" class="table table-striped" style="width:100%">
                <thead>
                    <tr>
                        <th>Ciudad</th>
                        <th>Zona</th>
                        <th>Tienda</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(store, index) in stores" :key="index">
                        <td>{{store.ciudad}}</td>
                        <td>{{store.zona}}</td>
                        <td>{{store.nombre_cliente}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>

import { fetchStoresJSON } from '@/helpers/funcionesTiendas'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      stores: []
    }
  },
  created(){
    this.stores = fetchStoresJSON()
  },
  components: {
    Footer
  }

}
</script>
