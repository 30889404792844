export const preciosArray = [
    {
     "marca": "ESPRIT",
     "ref": "699E014",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E009",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "649E091",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "649E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E011",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E005",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E066",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E060",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E059",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E053",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E027",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E017",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E006",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E026",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E021",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E018",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "409E008",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409E007",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E019",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "1700902",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E003",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E012",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E007",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E006",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E025",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E008",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E005",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E012",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E015",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E016",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E031",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E000",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E030",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E043",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E015",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E020",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "649E003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E018",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "449E003",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E009",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E034",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "659E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E006",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "471F001",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E012",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F012",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F006",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "631F002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F091",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "631F003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "131F010",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F090",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "131F004",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "681F000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F017",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F010",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "111F000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F004",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "491F001",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F010",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "401F003",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F091",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F016",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F014",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "441F001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F001",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F012",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "171F001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F003",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F009",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "151F000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "471F003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "649E005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F013",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "441F000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "401F001",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F027",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F026",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F020",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "651F090",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F007",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F017",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F006",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F001",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F015",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F022",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "131F002",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "641F001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "631F005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F020",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "631F004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F025",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F005",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "491F007",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F022",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F019",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "681F001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F038",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F032",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F031",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "491F005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "471F004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F026",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F019",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F018",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F005",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F020",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F010",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "131F003",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "491F008",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F023",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "401F004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "101F004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F028",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631F006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F034",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F029",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "111F009",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "111F008",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "111F007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F014",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F013",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "691F002",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F024",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F033",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "401F006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F046",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F030",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F012",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F016",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F007",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "401F002",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F049",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F013",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F039",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F025",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F010",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F044",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F035",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F033",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F021",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F008",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F035",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F013",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "631F010",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "631F008",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "691F007",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F043",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "641F090",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F047",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F014",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F012",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "101F003",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "631F007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F036",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F006",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "691F003",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F090",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F024",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "109E006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "979E001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F036",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "622F000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "631F009",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F016",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F040",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F039",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "471F009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F005",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "651F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "471F005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F090",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F045",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "642F000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F017",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F009",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F001",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "441F003",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "441F002",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F015",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "402F000",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F022",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F020",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "691F006",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "632F000",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F014",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F011",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F004",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "492F001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "472F007",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F006",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F012",
     "precio_lista": 174652,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F015",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F010",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "491F012",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "401F008",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "472F000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F002",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F000",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "442F000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F004",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "632F003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F012",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "641F002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632F001",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F006",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F005",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F041",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F007",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F017",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F007",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F032",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F018",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F017",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F015",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F031",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F003",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "632F002",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F015",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "671F014",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "491F011",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "491F010",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F038",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F037",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F032",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F031",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "401F007",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "471F010",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "431F018",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F016",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F005",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "622F005",
     "precio_lista": 174652,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F002",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F008",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F014",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F003",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F004",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F000",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "622F006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F028",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F027",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F026",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F023",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F007",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "402F002",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F008",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F024",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "472F002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F017",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F016",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F007",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "632F005",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F025",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "132F002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F010",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F009",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F037",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F013",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F052",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "611F050",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "112F001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "111F002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F032",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F031",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F006",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "402F003",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F006",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "642F006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "642F005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "632F008",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "622F002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411F034",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F008",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F033",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F010",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F024",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632F006",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "622F004",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F039",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F002",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "152F000",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F008",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "632F007",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "615F001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "615F000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F042",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F036",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F006",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F001",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "172F003",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F035",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "642F002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F043",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F011",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F027",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "172F000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "171F003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F010",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "492F007",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "652F001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F020",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "132F004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "112F003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "622F001",
     "precio_lista": 174652,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F034",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F045",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F047",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "492F006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F012",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "682F000",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F030",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "492F005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F003",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F025",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F020",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "642F007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "642F003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "472F005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "472F003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F004",
     "precio_lista": 164064,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F015",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F000",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "112F004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F090",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "402F004",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "632F009",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F014",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "622F007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F029",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F048",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "472F004",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F092",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F092",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F090",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F091",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F094",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F091",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "492F008",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F014",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F013",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "492F090",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F092",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F091",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F090",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "452F090",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F093",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "622F003",
     "precio_lista": 164064,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F038",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F040",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F001",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "112F002",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F057",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F056",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F054",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F053",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F051",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "432F012",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F028",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F022",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F050",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F058",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F055",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F052",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "672F013",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F067",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F062",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F060",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F068",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F066",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F065",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F064",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F063",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F061",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F067",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F068",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F059",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "642F069",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "1000000",
     "precio_lista": 23771,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "1000001",
     "precio_lista": 21127,
     "precio_publico": 38900
    },
    {
     "marca": "ESPRIT",
     "ref": "1000900",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "101B000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "101B001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "101C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "102B000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "102C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "102C001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "103A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "103B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "109A000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "109B001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "109B002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "109B004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "109B005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "109B006",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "109B007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "109B008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "109C001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "109C003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "109D000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "109D002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "109D003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "109E001",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100000",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100002",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100900",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100901",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100902",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100903",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100904",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100907",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1116002",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "1117001",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1118003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "1118004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1118005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "111A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "111A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "111A003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "111A004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "111A005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "111A006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "111A007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "111B000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "111B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "111B002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "111B003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "111B004",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "111B005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "111B801",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "111B802",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C801",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "111D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "111D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "111D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "111D003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "111D009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "111E000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "111E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "111E002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "1124000",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "1124002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "1127006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "1128000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "1128001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1128002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "1128003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "112A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "112A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "112A003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "112A004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "112A005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "112A006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "112B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "112B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "112B003",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "112B004",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "112B005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "112B006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "112B007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "112B800",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "112B801",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C801",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "112D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "112D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "112D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "112D009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "112E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "112E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "112E006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "1137001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "1137004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "1138001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1138002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "1138003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "1138004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "113A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "113A003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "113A004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "113A005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "113B003",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "113B004",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "113B005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "113B801",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "1198000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "1198001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1198002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "119A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "119A003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "119A005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "119A006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "119A007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "119B010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "119B012",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "119B016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C014",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C015",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "119D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "119D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "119D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "119D004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "119D006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "119D007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "119D010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "119D013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "119D014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E000",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "1200901",
     "precio_lista": 131823,
     "precio_publico": 249000
    },
    {
     "marca": "ESPRIT",
     "ref": "1200903",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "121C000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "121C001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "122B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "122C000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "123B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "123B001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "129C000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "129C001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "1300000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "1300002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "1300003",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1300004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "1300050",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "1300900",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "1300901",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "1316000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1317000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1317001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1317002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1317003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1317004",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "1317006",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "1318000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1318001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1318003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1318004",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "131A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "131A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "131A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "131A004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "131B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "131B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "131B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "131B006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "131B007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "131B008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "131B009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "131C002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "131C005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "131C006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "131C007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "131C008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "131D001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "131D002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "131D007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "131D008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "131E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "131E002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "131E007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "131E008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "1327000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1327001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1327002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1327003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "1327005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1328000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1328001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1328002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1328003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1328004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1328005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "1328006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "132A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132A004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "132A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "132A007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "132B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132B005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132B006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "132B007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "132B008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "132B009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "132C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "132C001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "132C002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "132C007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "132C010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "132D003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "132D007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "132D008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "132E001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "132E002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "132E007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "132E008",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "1334000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1334002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1336000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1336001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1336003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1337000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1337001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1337002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1337003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1337005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "1337006",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "1337007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1338001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1338002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1338004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "133A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "133A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "133A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "133A004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "133A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "133A007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "133A008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "133B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "133B007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1394000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1395000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1396000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1396001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1397000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1397001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1397003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "1397005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "1397006",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "1398000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1398001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1398002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1398004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "1398006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A026",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "139A027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "139B012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139B018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "139B019",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "139B020",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "139B024",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "139C011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "139D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "139D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "139D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "139D004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "139D007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "139D008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "139D009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "139D010",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "139E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "139E004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "1418000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "141A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "141D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "1427000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1428000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "142C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "142D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "1438000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "1497000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "1498000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "149C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "1518000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "151A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "151A001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "151C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "151C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "151D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "151D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "151E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "151E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "1527000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "1528000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "152A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "152B000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "152B001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "152C000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "152C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "152C002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "152D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "152D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "152E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "152E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "1538000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "153A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "1598000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "159A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "159B003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "159B005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "159C000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "159C002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "159D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "159D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "1697000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1700000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1700050",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1700900",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "1700901",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "1718000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "1718081",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "171A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "171B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "171C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "172A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "172B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "172B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "172C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "172C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "172E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "173A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "173B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "173B001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "1798000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1798001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "179C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "179C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "179C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "179D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "179D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "179D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "179D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "191C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "191D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "192C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "192C002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "192D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "192E000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "193A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "199C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A010",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2028001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2028003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "202A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "202A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "2038002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "2038003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A002",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2097000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "2097006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2098000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2116010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2137010",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2195001",
     "precio_lista": 39653,
     "precio_publico": 74900
    },
    {
     "marca": "ESPRIT",
     "ref": "2195005",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "223A010",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2294002",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "2470046",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2616001",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2616023",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "2616024",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2617002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2617004",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2617005",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "2617006",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "2617010",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "2618003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2618009",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "2618020",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "2618021",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2618022",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "2618023",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "261A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "261A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "261A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "261A010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2625002",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2625009",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2626001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2626005",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "2627002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "2627003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "2627004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2627007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2627008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2628002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2628003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2628004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "2628006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "262A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "262A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "262A005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "2633001",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2635006",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2636000",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "2636003",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2637003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "2637005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2637008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2637022",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2638001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2638002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2638003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "2638004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2638005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "2638006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "263A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "263A002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "263A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "2695003",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "2695004",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2695006",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "2695009",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "2696003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2696004",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2696008",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2696010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2697000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2697002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "2697003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "2697004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2697005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2697006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2697009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "2697010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "2697011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2698000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "2698002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2698003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "2698004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "2698005",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "2698006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2698007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2698008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "2800008",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "ESPRIT",
     "ref": "2817010",
     "precio_lista": 39900,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "283B000",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "ESPRIT",
     "ref": "283B001",
     "precio_lista": 1429,
     "precio_publico": 2700
    },
    {
     "marca": "ESPRIT",
     "ref": "283B002",
     "precio_lista": 6829,
     "precio_publico": 12900
    },
    {
     "marca": "ESPRIT",
     "ref": "283B003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "283B005",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "283B006",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "ESPRIT",
     "ref": "283B007",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "ESPRIT",
     "ref": "283B008",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "ESPRIT",
     "ref": "283B010",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "ESPRIT",
     "ref": "2897010",
     "precio_lista": 5000,
     "precio_publico": 7900
    },
    {
     "marca": "ESPRIT",
     "ref": "289A000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "289A001",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "289A800",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "289B000",
     "precio_lista": 9477,
     "precio_publico": 17900
    },
    {
     "marca": "ESPRIT",
     "ref": "289B002",
     "precio_lista": 9477,
     "precio_publico": 17900
    },
    {
     "marca": "ESPRIT",
     "ref": "289B004",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "291A010",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B000",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "2937010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "293A010",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "ESPRIT",
     "ref": "293A011",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "ESPRIT",
     "ref": "3126017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "3798002",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "4000000",
     "precio_lista": 23771,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "4017008",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "401A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "401A002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "401A003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "401B002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "401B003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "401C002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "401C003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "401C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "401C005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "401C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "401C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "401D000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "401D001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "401D002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "401D003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "401D004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "401D005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "401D006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "401D007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "401D008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "401D009",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "401E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "401E001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "401E002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "401E009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4027000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4027001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4028001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "402A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "402A001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "402A002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "402B000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "402B002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "402B004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "402C000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "402C001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "402C002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "402C005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "402C006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "402C007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "402C008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "402D000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "402D001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "402D002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "402D003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "402D004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "402D005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "402D006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "402D007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "402D008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "402E000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "402E002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "402E003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "402E005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "402E006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "402E007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "402E009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4037001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4037005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4038000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4038002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "403B001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "403B002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "403B003",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "403B007",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "403B008",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "403B009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A003",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "409C002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "409C003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "409C004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "409C005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "409C006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "409C007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "409C008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "409C009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409D013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4118000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "411C014",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D011",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D014",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D016",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E011",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E012",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E013",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E015",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E016",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E017",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E018",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "4127000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4127005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A011",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B013",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C014",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C018",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D012",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D015",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D020",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D021",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D023",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E013",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E014",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E015",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "4134003",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4134006",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4137004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197018",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198011",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A009",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C014",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C024",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D013",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D016",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D017",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D019",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D022",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D023",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "421C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "421C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E001",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "4227000",
     "precio_lista": 370006,
     "precio_publico": 698900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "422C000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "422C001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D000",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "4237000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "423A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "429B002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C002",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C804",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "4300000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4315006",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4316000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317000",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317007",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317008",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317009",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317012",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317013",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "431B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "431B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431B005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431B006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431B007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "431C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "431C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431C005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431C006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431C007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E006",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E007",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E009",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "4326000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4326009",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4328000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "432A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "432A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "432A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "432A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "432B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "432C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "432C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "432C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "432C004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "432C006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "432C007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "432C008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "432C009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D008",
     "precio_lista": 105300,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432D011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E007",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E008",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "4336006",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4395001",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4396003",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4396004",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A026",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A028",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439B010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "439B011",
     "precio_lista": 79934,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "439B012",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C012",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D009",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D010",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D011",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D013",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "441A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "441A001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "441B001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "441C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4428000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "442A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "442C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "442C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4437001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4438000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4438001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "443A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "443A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "443B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4498001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "449A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "449A001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "449C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "451C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "471A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "471A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "471B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "471B001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "471B003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "471C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "471C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "471C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "471D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "471D001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "471D002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "471E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "471E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "471E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "471E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "472A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "472A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "472A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "472B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "472C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "472C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "472D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "472D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "472D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "472E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "472E001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "472E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "472E003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "4738000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4738001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4738002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "473A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "473A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "473B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "473B004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "479A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "479A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "479A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "479A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "479A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "479A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "479B010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "479C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "479C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "479C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "479C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "479C004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "479C005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "479C006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "479D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "479D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "479D002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "479D003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "479D004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "491B001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "491B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "491B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "491B005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "491C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "491C002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "491C003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "491C004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "491C005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "491D000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "491D001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "491D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "491D003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "491D004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "491D005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "491E000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "491E001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "491E002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "491E003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "4928000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "4928002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "492A000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "492A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "492A003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "492B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "492B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "492C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "492C001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "492C003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "492C004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "492D000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "492D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "492D003",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "492D004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "492D005",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "492D006",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "492D007",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "492D008",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "492E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "492E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "492E003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "4937000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4937001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4938002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "493A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "493A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "493A002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "493A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "493B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "493B001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "4998000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4998002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "499A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "499A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "499A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "499B002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "499B005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "499C000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "499C001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "499C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "499C003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "499C004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "499D000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "499D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "499D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "499D005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "499D006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E000",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "5067001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6017020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "601A001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601A003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601A006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601B000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "601B002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601B003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601B004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D014",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D600",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6028001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "602A001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "602A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602A003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602B001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602B003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602B005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "602B007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602B008",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "602B010",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C011",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C013",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C014",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D003",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602E003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "602E006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "602E007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602E008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "602E009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6037006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6037010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "603B000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "603B002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "603B004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "603B010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6096001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098007",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C009",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C010",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C013",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C014",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C016",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C017",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C018",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C019",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C800",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D018",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D019",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E000",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117003",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A013",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A019",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A023",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B008",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B022",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C012",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C014",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D022",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D024",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D600",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D602",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D603",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E010",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E011",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E016",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E017",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E018",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E019",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E020",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E021",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E022",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "6126029",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6128002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6128003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6128007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6128008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B008",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B018",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B019",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B020",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C010",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C018",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C020",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C021",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C024",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D011",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D016",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D017",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D019",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D024",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D025",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D026",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D027",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D028",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D029",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E006",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E011",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E012",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E014",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E016",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E017",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E018",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E020",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E021",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E022",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E023",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E024",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6134005",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137017",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137019",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137021",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137024",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A014",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A016",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B023",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B024",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B025",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6196010",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6197001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6197006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6197009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6197010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6197016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198014",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198019",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A011",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A013",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A014",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A018",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A019",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A024",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A027",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B022",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B026",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B028",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B030",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B031",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B033",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B034",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C010",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C014",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C020",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C024",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C027",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C028",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C029",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C030",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D010",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D014",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D015",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D018",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D019",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D020",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D021",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D022",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D024",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D025",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D026",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D027",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D028",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D029",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D030",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D031",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D032",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D033",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D034",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D035",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D036",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D039",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E000",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E002",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E003",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E009",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "621A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "621C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "621C002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D010",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E003",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D003",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D005",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317004",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317006",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317008",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317010",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317011",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317014",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317016",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317017",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317018",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "631B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631B004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631B005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E007",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E010",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "6326000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6326007",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6328000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6328001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "632A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "632A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "632A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C011",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D012",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D013",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D015",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E007",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E009",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E010",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "6334004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6336000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337011",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337020",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "633B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "633B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "633B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "633B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "633B006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "633B007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "633B009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "633B013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6395001",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6395004",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6396000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6396007",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6396015",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639B009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C011",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C015",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C016",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C017",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D009",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D010",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D011",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D012",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D013",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D014",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D015",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "641A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "641A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "641A002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "641B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "641B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "641C000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "641C001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "641D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "641E000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6427000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "642B003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "642B004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "642C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "642C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "642D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "642E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "642E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "642E002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "643A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "643B002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "643B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6494000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6497001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6498001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "649A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "649A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "649C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "649C002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "649D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "649D001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6518000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "651A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "651B000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "651B002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "651D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "651E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6528000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "652A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "652A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "652A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "652B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "652B002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "652E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "652E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "652E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "652E003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6538000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "653A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "653B003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "659A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "659A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "659C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "659C001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "659C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "659D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "659D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "659D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "659D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "659D004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6717001",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "671A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "671A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "671A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "671C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "671C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "671C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "671C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "671C004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "671C005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D600",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D601",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D602",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E006",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E011",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E013",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E014",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E015",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "672A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "672A004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "672C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "672C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "672C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "672C006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "672C007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "672C008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D009",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D012",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D014",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D015",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D017",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D018",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E009",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E010",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E012",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E013",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "672E014",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6734007",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6737003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6738001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "673A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "673A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "673A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "673B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "673B003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "673B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "673B005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6797005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6798000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6798001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6798002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6798003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6798005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "679A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "679A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "679A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "679A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "679B010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C012",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D010",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D011",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D012",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D013",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D014",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D015",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D016",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D017",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D018",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D020",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "691A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "691A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "691A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "691A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "691B001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "691B002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "691B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "691B004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "691C000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "691C001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "691C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "691C003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "691C004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "691C005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "691C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "691D000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "691D001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "691D002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "691D003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "691D004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "691D005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "691E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "691E001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "691E003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "692A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "692A002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "692A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "692A004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "692B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "692B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "692B003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "692B005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "692C000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "692C001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "692C003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "692C004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "692C005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "692C006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "692D000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "692D001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "692D002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "692D004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "692D005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "692E000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "692E001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "692E002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "692E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "692E004",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "6934001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6935001",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6938000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6938002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B008",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6997000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6998001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6998002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6998003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6998004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "699A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "699A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "699A003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "699B010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "699B011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "699C001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "699C002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "699C003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "699C004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "699C006",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "699C007",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "699C009",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "699C010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "699D000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "699D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "699D002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "699D003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "699D004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "699D005",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "699D006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "699D007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "699D008",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "699D009",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E000",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "9000009",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "9000010",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "9000021",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "ESPRIT",
     "ref": "9000031",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "ESPRIT",
     "ref": "9000035",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "ESPRIT",
     "ref": "9000036",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "9013001",
     "precio_lista": 46109,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "9013012",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9015900",
     "precio_lista": 13523,
     "precio_publico": 24900
    },
    {
     "marca": "ESPRIT",
     "ref": "9016900",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "9017002",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9017007",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9017028",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018010",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018019",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018021",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A007",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A017",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A900",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A901",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A902",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B010",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B017",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B021",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B023",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B025",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B026",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B800",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B801",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B803",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C010",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D018",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D020",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D600",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E009",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E015",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E016",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E018",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E019",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E020",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E021",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E022",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E024",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E025",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9026016",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9026900",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "9027021",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9027900",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9027901",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028008",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028010",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028011",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028021",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028022",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028900",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A012",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A013",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A015",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A018",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A021",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A801",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A802",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A803",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B012",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B014",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B015",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B019",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B020",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B800",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B801",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B802",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B803",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C600",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D015",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D018",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D019",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D021",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D022",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E019",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9036003",
     "precio_lista": 45023,
     "precio_publico": 82900
    },
    {
     "marca": "ESPRIT",
     "ref": "9036008",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037007",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037011",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037012",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037025",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037026",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037900",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037901",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038007",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038016",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038017",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038021",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038900",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A007",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A011",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A012",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A020",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A022",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A800",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A801",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B007",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B801",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B803",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9090008",
     "precio_lista": 46109,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "9091002",
     "precio_lista": 46109,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "9091025",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9094901",
     "precio_lista": 14615,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "9095002",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9096001",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9096012",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "ESPRIT",
     "ref": "9096023",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9097007",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9097016",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9097800",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9097900",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098014",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098023",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098025",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098026",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098027",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A007",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A010",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A011",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A017",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A019",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A024",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A801",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A802",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A803",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C015",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C019",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C025",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C026",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C027",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C028",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C029",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D009",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D015",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D019",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D022",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D025",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D026",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D027",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D028",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "909E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9117024",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9117026",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9117029",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9117030",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9117031",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118018",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118031",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A015",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A016",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A022",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A025",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A026",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A028",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B022",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B024",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B025",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B026",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B027",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B029",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B030",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B031",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B032",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B036",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B038",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B039",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B041",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B800",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B801",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C018",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C800",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C801",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C802",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D011",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D012",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D018",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D019",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D020",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911D023",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E010",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E013",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E014",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E017",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E018",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911E024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9124009",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9124018",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9124020",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9124024",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9127004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9127014",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128028",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A010",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A019",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A026",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A028",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A030",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A031",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A034",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A035",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A036",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A038",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A800",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A802",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A803",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B008",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B015",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B016",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B019",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B022",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B023",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B028",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B029",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B030",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B031",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B032",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B033",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B035",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B800",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B802",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C009",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C010",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C012",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C017",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C018",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C022",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C023",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C024",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912C025",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D004",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D008",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D014",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D015",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D018",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D019",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D022",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912D024",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E009",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E010",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E013",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E015",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E016",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E018",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "912E021",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137032",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137033",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138016",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138019",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138024",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138034",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A023",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A024",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A037",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A800",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A801",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A802",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B012",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B018",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B019",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B021",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B027",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B028",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B030",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B032",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B033",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B034",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B035",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B036",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B800",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B801",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B802",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9195004",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197012",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197015",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197026",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197039",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197040",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198026",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198028",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198029",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198030",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198032",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198034",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198036",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A018",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A022",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A024",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A026",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A028",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A029",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A030",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A031",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A032",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A034",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A035",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A036",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A038",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A800",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A801",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A802",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919B010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C003",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C016",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C022",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C028",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C029",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C030",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C031",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C033",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C034",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C036",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D016",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D020",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D025",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D026",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D027",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D028",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D029",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D030",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D031",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D032",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D033",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D034",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D035",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D036",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D037",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D038",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D041",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D042",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "919E000",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "919E003",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "919E004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "921A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "921B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "921B001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "921B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "921B005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "921B008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "921C000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "921C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "921C002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "921C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "921D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "921D001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "921D002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "921D003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "921D010",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "921D011",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "921E001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "921E002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "922B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "922B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "922B002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "922B003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "922B004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "922B006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "922C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "922C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "922C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "922C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "922C008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "922D000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "922D001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "922D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "922E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "922E001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "922E003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "9238000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "923A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "923A011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "923B000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "923B001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "923B003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "923B007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9296000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "929A000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "929A002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "929A004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "929A005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "929A006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "929A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "929C000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "929C001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "929C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "929C003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "929C004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "929D000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "929D001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "929D002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "929E000",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "9316001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9316007",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9317001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9317002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9317003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9317005",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9318000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9318001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9318002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "931A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "931A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "931A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "931A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "931A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "931A005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "931A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "931A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "931B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "931B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "931B003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "931B004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "931B005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "931B007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "931B008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "931C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "931C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "931D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "931D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "931D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "931E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "931E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "9323000",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9327000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9327001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9327002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9327004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9327005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9328002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9328003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9328004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9328005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9329003",
     "precio_lista": 70060,
     "precio_publico": 129000
    },
    {
     "marca": "ESPRIT",
     "ref": "932A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "932A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "932A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "932A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "932B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "932B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "932B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "932B003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "932B004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "932B005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "932C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "932C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "932C003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "932C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "932C015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "932D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "932D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "932D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "932D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "932E000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "932E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "932E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "932E003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "9332000",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9336001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9337000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9337001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9337002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9337003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9337004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9337006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9337007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9337008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9338000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9338001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9338002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9338003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9338004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9338005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "933A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "933A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "933A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "933A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "933A004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "933A005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "933A006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "933A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "933B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "933B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "933B004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9391000",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9391004",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9393003",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9395002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9396000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9396002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9396005",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9396006",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9396008",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9397002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9397003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9397005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9397006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9397007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9397009",
     "precio_lista": 21181,
     "precio_publico": 11000
    },
    {
     "marca": "ESPRIT",
     "ref": "9397011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9398001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9398002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9398003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9398004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9398006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9398008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "939A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "939A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "939A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "939A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "939A005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "939A006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "939A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "939A008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "939C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "939C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "939C002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "939C003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "939D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "939D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "939D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "939D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "941A000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "941A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "941B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "941B003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "941B004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "941C000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "941C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "941C002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "941C003",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "941C005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "941C006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "941D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "941D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "941D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "941D004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "941E000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "941E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9427003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "942A001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "942A002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "942A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "942B000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "942B001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "942B002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "942B003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "942B005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "942C000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "942C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "942C002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "942C003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "942C004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "942D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "942D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "942D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "942E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "942E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "942E016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9437002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "943A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "943A002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "943A003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "943A004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "943B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "943B001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "943B002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "943B003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "943B004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9496002",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9498005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "949A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "949A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "949A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "949A013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "949C000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "949C003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "949C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "949C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "949C006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "949C007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "949C008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "949D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "949D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "949D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "949D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "949D004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "949D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "949E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "9516002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9518000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "951A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "951A004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "951B000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "951B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "951C000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "951C002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "951C003",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "951D000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "951D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "951D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "951D003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "951D004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "951E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "951E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9524002",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9527003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "952A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "952B002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "952B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "952B004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "952C000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "952C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "952C002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "952D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "952D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "952D002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "952D003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "952E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9533004",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9537003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9538003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9538004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "953A001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "953A004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "953B003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9594001",
     "precio_lista": 43943,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9594003",
     "precio_lista": 58606,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9598000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9598003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9598004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "959A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "959A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "959A002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "959C000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "959C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "959C002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "959C003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "959C004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "959C005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "959D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "959D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "959D003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "959D004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "959D005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "959D006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9627001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9697001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9715401",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9716800",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9717801",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9718901",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A400",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A600",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A803",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A901",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A902",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B400",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B401",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B411",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B601",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B801",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B802",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B803",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B804",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B810",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B811",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B812",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B900",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971C021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D012",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D014",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D015",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D600",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971D601",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E007",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E010",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E011",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E012",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E013",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E014",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E016",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E018",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "971E019",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9721416",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9727602",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9727801",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A400",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A402",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A600",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A601",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A801",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A802",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A804",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B600",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B601",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B602",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B800",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B801",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B802",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B803",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B804",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B900",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B901",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C011",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972C012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D012",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D014",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D015",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D017",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D018",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D020",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D022",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E007",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E009",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E010",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E011",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E013",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E014",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E015",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E017",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "972E018",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9736600",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9736601",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9736802",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9737800",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738400",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738801",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738802",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738803",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738900",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A401",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A411",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A412",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A800",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A801",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A811",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B600",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B601",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B602",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B603",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B604",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B605",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B900",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B901",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B902",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B903",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B904",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9796600",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9796601",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9796803",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9796804",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9797803",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798800",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798801",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798803",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798804",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798812",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798814",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798904",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A400",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A401",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A600",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A601",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A602",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A603",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A800",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A801",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A802",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A803",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A900",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A901",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A902",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C011",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C012",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979C015",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D010",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D011",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D012",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D013",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D014",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D015",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D016",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D017",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D018",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D019",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D020",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D021",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D022",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D023",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D024",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D026",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D027",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "981C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "981D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "981D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "981D002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "981E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "981E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "982B000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "982B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "982B002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "982C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "982C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "982D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "982D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "982D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "982E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "982E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "982E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "983B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9898002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "989A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "989A001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "989A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "989C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "989C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "989C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "989D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "989D001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "989D002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "989D003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9918003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A007",
     "precio_lista": 104824,
     "precio_publico": 198000
    },
    {
     "marca": "ESPRIT",
     "ref": "991A008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A010",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A013",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B015",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C012",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "991C013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D010",
     "precio_lista": 142119,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D011",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "991E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "991E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "991E003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "991E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "991E005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "991E006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "991E007",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "991E008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "9927003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9927009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9928004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9928006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9928009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "992A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "992A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "992A004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "992A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "992A008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "992A013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "992A800",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "992D011",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "992E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "992E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "992E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "992E003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "992E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "992E005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "992E006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "992E007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "992E008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "992E009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9937000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9938002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9938003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9938004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9938007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9938009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9938010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9938011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9938013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "993A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "993A017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B012",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B014",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B015",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9995002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "9997008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9997009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9997012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A013",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A015",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "999B005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C015",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C020",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C021",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "999C022",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D006",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D007",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D009",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D012",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D013",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D015",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D016",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "999D017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "109E000",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "109E002",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "109E003",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "109E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100052",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100053",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100054",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100055",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "111A001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1197003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E001",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E002",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E003",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E004",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E010",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E012",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E030",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "119E031",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "1300001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1316004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "131A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "131C009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "1334001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1396002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1396003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1397002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "139E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "153A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "159E000",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1717001",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "171B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "179E000",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "179E001",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "179E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "179E004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "2027004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2037008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2037009",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2096001",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2098006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "2098007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "2131000",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2131002",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2131003",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2131004",
     "precio_lista": 39592,
     "precio_publico": 72900
    },
    {
     "marca": "ESPRIT",
     "ref": "2182000",
     "precio_lista": 17000,
     "precio_publico": 27000
    },
    {
     "marca": "ESPRIT",
     "ref": "2614001",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "2615001",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2618002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "261A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2628005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "2633000",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2634008",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2634010",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "2635004",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2638000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2682000",
     "precio_lista": 17000,
     "precio_publico": 27000
    },
    {
     "marca": "ESPRIT",
     "ref": "2691000",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2697008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2698001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "2837000",
     "precio_lista": 65000,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "283B009",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "ESPRIT",
     "ref": "3127000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "3195019",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4017006",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "4020003",
     "precio_lista": 43394,
     "precio_publico": 56000
    },
    {
     "marca": "ESPRIT",
     "ref": "4020004",
     "precio_lista": 37963,
     "precio_publico": 49000
    },
    {
     "marca": "ESPRIT",
     "ref": "4031002",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "4031021",
     "precio_lista": 45023,
     "precio_publico": 82900
    },
    {
     "marca": "ESPRIT",
     "ref": "4037000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4038004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "409E001",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409E002",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "409E003",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "409E005",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "409E006",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4119007",
     "precio_lista": 86353,
     "precio_publico": 159000
    },
    {
     "marca": "ESPRIT",
     "ref": "411B002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4121002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4129020",
     "precio_lista": 64629,
     "precio_publico": 119000
    },
    {
     "marca": "ESPRIT",
     "ref": "412B014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4131003",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4190003",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E001",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E004",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E005",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E006",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E008",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E009",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E011",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E014",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E016",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E017",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E022",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E024",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E027",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E033",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E034",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E001",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "4321002",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4321005",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4332002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4334000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4390001",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4396001",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4396007",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E002",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E009",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E015",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "449E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "4700001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "472B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4738004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4794003",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4798000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4798002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "479E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "479E001",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "479E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "479E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "479E005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "492B000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "4937002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E002",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E003",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "6029001",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6037002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6037003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6037007",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6038000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6038003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6038004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6096003",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E003",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E005",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E007",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E011",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E012",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E013",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E016",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E091",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E093",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E094",
     "precio_lista": 79358,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6110000",
     "precio_lista": 75491,
     "precio_publico": 139000
    },
    {
     "marca": "ESPRIT",
     "ref": "6117004",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117005",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117006",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117007",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117015",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118012",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6122002",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6126001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6127000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6127007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6127011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6127012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6127014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6128004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6129001",
     "precio_lista": 57026,
     "precio_publico": 105000
    },
    {
     "marca": "ESPRIT",
     "ref": "612A005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6130001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6131004",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6136004",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6136005",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6136009",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6192093",
     "precio_lista": 62946,
     "precio_publico": 115900
    },
    {
     "marca": "ESPRIT",
     "ref": "6196014",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6196015",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6197004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198015",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E004",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E005",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E011",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E012",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E013",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E014",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E015",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E016",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E019",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E020",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E021",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E022",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E023",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E024",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E025",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E026",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E028",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E029",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E031",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E032",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E033",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E035",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E036",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E041",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E042",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E045",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E047",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E048",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E051",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E052",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E065",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E069",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E070",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E090",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E091",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "6228000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "6237000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6297000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6297001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E000",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "6310004",
     "precio_lista": 75491,
     "precio_publico": 98000
    },
    {
     "marca": "ESPRIT",
     "ref": "6313000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6332002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6396003",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E001",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E002",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "641B002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "642B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "649E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "649E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "651A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "651B003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "659E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "659E091",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6717003",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "671A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6727000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6727003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B401",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D016",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6737001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6797002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6797003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "679A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E000",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E005",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E006",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E008",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E009",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E013",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E016",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E017",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E018",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E019",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E021",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E023",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E090",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E093",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E094",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E095",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "689E000",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "689E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "689E003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "692A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "692B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6930000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6990000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "699A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "699A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E003",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E005",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E008",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E090",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E092",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E093",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "7382000",
     "precio_lista": 17000,
     "precio_publico": 27000
    },
    {
     "marca": "ESPRIT",
     "ref": "8082000",
     "precio_lista": 17000,
     "precio_publico": 27000
    },
    {
     "marca": "ESPRIT",
     "ref": "8093000",
     "precio_lista": 17868,
     "precio_publico": 32900
    },
    {
     "marca": "ESPRIT",
     "ref": "9000000",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "9000001",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "9000005",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "9011000",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9011015",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9011017",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9011029",
     "precio_lista": 45023,
     "precio_publico": 82900
    },
    {
     "marca": "ESPRIT",
     "ref": "9011800",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "9012004",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9012012",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9012014",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9012017",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9012023",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9012800",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "9012805",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "9013024",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9013025",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "9013800",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "9016010",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "ESPRIT",
     "ref": "9016019",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9019002",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9019007",
     "precio_lista": 34161,
     "precio_publico": 62900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B014",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9020012",
     "precio_lista": 45023,
     "precio_publico": 59000
    },
    {
     "marca": "ESPRIT",
     "ref": "9020013",
     "precio_lista": 46109,
     "precio_publico": 60000
    },
    {
     "marca": "ESPRIT",
     "ref": "9020022",
     "precio_lista": 40678,
     "precio_publico": 53000
    },
    {
     "marca": "ESPRIT",
     "ref": "9021034",
     "precio_lista": 34161,
     "precio_publico": 62900
    },
    {
     "marca": "ESPRIT",
     "ref": "9022002",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9022010",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9022011",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9022013",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9022030",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9022805",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "9027006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9027019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9029007",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A017",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B804",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9030010",
     "precio_lista": 39592,
     "precio_publico": 72900
    },
    {
     "marca": "ESPRIT",
     "ref": "9030023",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9031012",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9031013",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9031020",
     "precio_lista": 24385,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "9031027",
     "precio_lista": 35247,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "9031029",
     "precio_lista": 34161,
     "precio_publico": 62900
    },
    {
     "marca": "ESPRIT",
     "ref": "9031035",
     "precio_lista": 34161,
     "precio_publico": 62900
    },
    {
     "marca": "ESPRIT",
     "ref": "9031036",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9031803",
     "precio_lista": 17868,
     "precio_publico": 32900
    },
    {
     "marca": "ESPRIT",
     "ref": "9032008",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9032012",
     "precio_lista": 39592,
     "precio_publico": 72900
    },
    {
     "marca": "ESPRIT",
     "ref": "9032013",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9032014",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "ESPRIT",
     "ref": "9033021",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9034019",
     "precio_lista": 39592,
     "precio_publico": 72900
    },
    {
     "marca": "ESPRIT",
     "ref": "9035003",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9035006",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9035020",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "9036010",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9039003",
     "precio_lista": 34161,
     "precio_publico": 62900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A802",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9090002",
     "precio_lista": 34161,
     "precio_publico": 62900
    },
    {
     "marca": "ESPRIT",
     "ref": "9090015",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9090024",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9090035",
     "precio_lista": 34161,
     "precio_publico": 62900
    },
    {
     "marca": "ESPRIT",
     "ref": "9090050",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "9091000",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9091013",
     "precio_lista": 51541,
     "precio_publico": 94900
    },
    {
     "marca": "ESPRIT",
     "ref": "9091022",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9091023",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9091032",
     "precio_lista": 39592,
     "precio_publico": 72900
    },
    {
     "marca": "ESPRIT",
     "ref": "9091037",
     "precio_lista": 23299,
     "precio_publico": 42900
    },
    {
     "marca": "ESPRIT",
     "ref": "9092805",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "9093028",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9095030",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9097026",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "909E001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9112001",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9112002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9112015",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9115011",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9116013",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9116025",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118020",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B017",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9122006",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9125008",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9127008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9127010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9127016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128027",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128029",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128032",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A022",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A032",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A801",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B034",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9131001",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9131002",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9131004",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9131014",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9132005",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9132015",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9135024",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137017",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137021",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138033",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9139014",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A015",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A025",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A026",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A028",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9190011",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9191010",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9191014",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9194007",
     "precio_lista": 53718,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9195009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9195029",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9196011",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197031",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197032",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198019",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198020",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198021",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198031",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198035",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A037",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A039",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C027",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "919E002",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "9210001",
     "precio_lista": 102647,
     "precio_publico": 133000
    },
    {
     "marca": "ESPRIT",
     "ref": "9214006",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9221001",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "922A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "922A004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9232001",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "923A000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "923A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9291002",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9297005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "929A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9310004",
     "precio_lista": 75491,
     "precio_publico": 98000
    },
    {
     "marca": "ESPRIT",
     "ref": "9311003",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9314000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9315002",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9315009",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9317006",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9319000",
     "precio_lista": 91784,
     "precio_publico": 169000
    },
    {
     "marca": "ESPRIT",
     "ref": "9321002",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9321003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9326000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9330006",
     "precio_lista": 37474,
     "precio_publico": 69000
    },
    {
     "marca": "ESPRIT",
     "ref": "9334000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9335000",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9335003",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9339001",
     "precio_lista": 70060,
     "precio_publico": 91000
    },
    {
     "marca": "ESPRIT",
     "ref": "9392056",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9393006",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9395008",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9395011",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9397010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9397012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9398007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "941A002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9428001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9434004",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "9437001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9497006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9498003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "949A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9515001",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "951A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9528002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "952A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9536004",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "953A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9595003",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9596001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9597000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "959A004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9611000",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9622000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9627004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9637000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9637001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9637003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9637004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9691001",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9697007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9710005",
     "precio_lista": 86353,
     "precio_publico": 112000
    },
    {
     "marca": "ESPRIT",
     "ref": "9710009",
     "precio_lista": 86353,
     "precio_publico": 112000
    },
    {
     "marca": "ESPRIT",
     "ref": "9711410",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9714401",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9714600",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9715400",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9716600",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9718601",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9718801",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9718803",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A601",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A800",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A802",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B600",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9721405",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9721406",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9722401",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9726804",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9727902",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9728803",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9729001",
     "precio_lista": 86353,
     "precio_publico": 159000
    },
    {
     "marca": "ESPRIT",
     "ref": "9729008",
     "precio_lista": 97216,
     "precio_publico": 179000
    },
    {
     "marca": "ESPRIT",
     "ref": "972A401",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D019",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9731402",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9731404",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9731602",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9734406",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9737801",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9737803",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738601",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738804",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9790403",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9790404",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9791400",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9791407",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9793408",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9795600",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9795602",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9795903",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9796602",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9797801",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9797903",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798901",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "9812003",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9812004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "981B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "982A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9831001",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9891001",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9911010",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9911013",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9911014",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9912001",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9913001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9915002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9918000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9918007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B018",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "991D006",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "9921002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9928007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9928008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992A010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "992A012",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9931012",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9932004",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9934009",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9935003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9937001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9937004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9937005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9937007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9938005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "993A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "993A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "993A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "993A010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9990001",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9997011",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A009",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "999E001",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "999E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "1000700",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "109B003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "109E005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100050",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "121B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "139E003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "151B000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4037007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "409E004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E020",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E023",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E029",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E032",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "429B200",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E004",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E011",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E013",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E014",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "4498000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "449E002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4798001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E001",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E013",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "602B000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A005",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E092",
     "precio_lista": 37005,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6123707",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E010",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E039",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E040",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E049",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E050",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E054",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E055",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E056",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E061",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E062",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E063",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E064",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E067",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E068",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E092",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E013",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "639E014",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "642A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6492705",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6498000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "649B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "649E004",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "6598000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6598001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "659E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "659E090",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6798004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679E014",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "689E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "689E004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6998000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E010",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E013",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018023",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028013",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028017",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B201",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A013",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A018",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A803",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B017",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B804",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098022",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098301",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098304",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A025",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9116027",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118019",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118021",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118022",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118027",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118030",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9118032",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A301",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B014",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128023",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128031",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B204",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9134019",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138010",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138029",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138030",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B026",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198037",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198038",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "921A000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "921B002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "921B007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "922B005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "923A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9398005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9398009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "941A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "941A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "942A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9438002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9438003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9498001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9498002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "951A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "951B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "951B002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "952A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "952A004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "953A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A801",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A303",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A803",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B902",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A600",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A601",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A900",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798501",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798601",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798602",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798603",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798902",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "982A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "983A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "983A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9898003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B200",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "993A008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F202",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F213",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F212",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F201",
     "precio_lista": 264652,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "169E202",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F206",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F205",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F218",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F207",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "121F201",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "121F200",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E321",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E320",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E319",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E318",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E317",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E308",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E307",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E306",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "641F200",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F207",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F208",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F204",
     "precio_lista": 248770,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F211",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F203",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E111",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F202",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E303",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E302",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "309E111",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E304",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E305",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E311",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E310",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E309",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F200",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F111",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E328",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E327",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E326",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E325",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E324",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E323",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E322",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E703",
     "precio_lista": 333476,
     "precio_publico": 629900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E702",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E701",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E700",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "325E305",
     "precio_lista": 333476,
     "precio_publico": 629900
    },
    {
     "marca": "ESPRIT",
     "ref": "675E302",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "675E301",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "675E300",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "375E307",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "375E306",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "375E305",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "375E304",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "675E308",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "675E307",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "675E306",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "675E305",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "675E304",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "675E303",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "365E301",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "365E300",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E335",
     "precio_lista": 59900,
     "precio_publico": 0
    },
    {
     "marca": "ESPRIT",
     "ref": "305E334",
     "precio_lista": 59900,
     "precio_publico": 0
    },
    {
     "marca": "ESPRIT",
     "ref": "305E333",
     "precio_lista": 59900,
     "precio_publico": 0
    },
    {
     "marca": "ESPRIT",
     "ref": "305E332",
     "precio_lista": 59900,
     "precio_publico": 0
    },
    {
     "marca": "ESPRIT",
     "ref": "305E331",
     "precio_lista": 59900,
     "precio_publico": 0
    },
    {
     "marca": "ESPRIT",
     "ref": "305E330",
     "precio_lista": 59900,
     "precio_publico": 0
    },
    {
     "marca": "ESPRIT",
     "ref": "305E329",
     "precio_lista": 59900,
     "precio_publico": 0
    },
    {
     "marca": "ESPRIT",
     "ref": "661F110",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F104",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F103",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E315",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E314",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E313",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E312",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E311",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E345",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E344",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E343",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E342",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E341",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E340",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E339",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E338",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E337",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E336",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "161F107",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "161F100",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E313",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E312",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E316",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E315",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E314",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F101",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F120",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E360",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E359",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E358",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E357",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E356",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E355",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E354",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E353",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E352",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E351",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E350",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E349",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E348",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E347",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E346",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "335E307",
     "precio_lista": 128594,
     "precio_publico": 242900
    },
    {
     "marca": "ESPRIT",
     "ref": "335E306",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "335E305",
     "precio_lista": 128594,
     "precio_publico": 242900
    },
    {
     "marca": "ESPRIT",
     "ref": "335E304",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E312",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E311",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E310",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E309",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E308",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E307",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E306",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662F202",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F205",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "642F200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "622F201",
     "precio_lista": 264652,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "122F200",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F223",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E318",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E317",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E316",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E322",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E321",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E320",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E319",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E318",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E317",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E327",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E326",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E325",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E324",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E323",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E322",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E321",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E320",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E319",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E330",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E329",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E328",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E327",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E326",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E325",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E324",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E323",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F106",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F105",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "161F108",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F209",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F201",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E367",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E366",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E365",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E364",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E363",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E362",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E361",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E377",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E376",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E375",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E374",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E373",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E372",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E371",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E370",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E369",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E368",
     "precio_lista": 84652,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F212",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F204",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F201",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F200",
     "precio_lista": 248770,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E332",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E331",
     "precio_lista": 52358,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F203",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E383",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E382",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E381",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E380",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E379",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E378",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "622F202",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F214",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F207",
     "precio_lista": 264652,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E700",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E705",
     "precio_lista": 370535,
     "precio_publico": 699900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E704",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F102",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F101",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F100",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "439E700",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E703",
     "precio_lista": 370535,
     "precio_publico": 699900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E702",
     "precio_lista": 423476,
     "precio_publico": 799900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E701",
     "precio_lista": 423476,
     "precio_publico": 799900
    },
    {
     "marca": "ESPRIT",
     "ref": "365E700",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "385F300",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F304",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F303",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F302",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F301",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F300",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E385",
     "precio_lista": 93123,
     "precio_publico": 175900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E384",
     "precio_lista": 93123,
     "precio_publico": 175900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F204",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F212",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "162F203",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F100",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F304",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F303",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F302",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F301",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F300",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "315F301",
     "precio_lista": 113770,
     "precio_publico": 214900
    },
    {
     "marca": "ESPRIT",
     "ref": "315F300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662F105",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "461F124",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F213",
     "precio_lista": 248770,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F212",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "662F208",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "662F100",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F205",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F204",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F100",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F209",
     "precio_lista": 164064,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F305",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F304",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F303",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F302",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F301",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F300",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F216",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "662F104",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F109",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F103",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F310",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F309",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F308",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F307",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F306",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F305",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "315F303",
     "precio_lista": 113770,
     "precio_publico": 214900
    },
    {
     "marca": "ESPRIT",
     "ref": "315F302",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F310",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F309",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F308",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F307",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F306",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "662F218",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662F206",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F124",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "162F204",
     "precio_lista": 116417,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F211",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F213",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "385F301",
     "precio_lista": 164064,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "615F301",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "615F300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F207",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F701",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "661F700",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "641F700",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F703",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F702",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F701",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "621F700",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "491F700",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F700",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "401F700",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F316",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F315",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F314",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F313",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F312",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F311",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F311",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F106",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F314",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F313",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F312",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F311",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F310",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "412F100",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "162F102",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "615F304",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "615F303",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "615F302",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "385F302",
     "precio_lista": 164064,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F316",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F315",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F314",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F313",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F312",
     "precio_lista": 92594,
     "precio_publico": 174900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F104",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "385F304",
     "precio_lista": 164064,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "385F303",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F101",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "632F100",
     "precio_lista": 249900,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F101",
     "precio_lista": 209900,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "612F100",
     "precio_lista": 209900,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "422F110",
     "precio_lista": 174652,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "332F100",
     "precio_lista": 269900,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "312F102",
     "precio_lista": 239900,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "312F101",
     "precio_lista": 239900,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F320",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F319",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F318",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F317",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "312F100",
     "precio_lista": 239900,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F106",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F319",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F317",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F316",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F315",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F109",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "615F305",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F105",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601F102",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "335F301",
     "precio_lista": 174652,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "335F300",
     "precio_lista": 174652,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "102F107",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "371F100",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F106",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F318",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F317",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F333",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F332",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F331",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F330",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F329",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F328",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F327",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F326",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F325",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F324",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F323",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F322",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "305F321",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F321",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F326",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F325",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F324",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F323",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F322",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605F320",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F325",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F324",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F323",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F322",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F321",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F320",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "345F319",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "472F100",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "462F104",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "615F306",
     "precio_lista": 127005,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "315F308",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "315F307",
     "precio_lista": 113770,
     "precio_publico": 214900
    },
    {
     "marca": "ESPRIT",
     "ref": "315F306",
     "precio_lista": 113770,
     "precio_publico": 214900
    },
    {
     "marca": "ESPRIT",
     "ref": "685F301",
     "precio_lista": 119064,
     "precio_publico": 224900
    },
    {
     "marca": "ESPRIT",
     "ref": "685F300",
     "precio_lista": 119064,
     "precio_publico": 224900
    },
    {
     "marca": "ESPRIT",
     "ref": "402F100",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "335F302",
     "precio_lista": 162476,
     "precio_publico": 306900
    },
    {
     "marca": "ESPRIT",
     "ref": "685F303",
     "precio_lista": 162476,
     "precio_publico": 306900
    },
    {
     "marca": "ESPRIT",
     "ref": "685F302",
     "precio_lista": 174652,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "162F101",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "692F700",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "642F701",
     "precio_lista": 174652,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "642F700",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "602F106",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1000901",
     "precio_lista": 40151,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1000902",
     "precio_lista": 19933,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "1015702",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "1018700",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "101D700",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "101D701",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "101D702",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "101D703",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1021702",
     "precio_lista": 22222,
     "precio_publico": 40917
    },
    {
     "marca": "ESPRIT",
     "ref": "102C300",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "102C700",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "1034701",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "103B300",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "103B700",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "103B701",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "109C700",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "109D700",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "109D701",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "109D702",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100905",
     "precio_lista": 61579,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100906",
     "precio_lista": 61579,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1118200",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "111D700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "111D701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1128200",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C700",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "112C701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1138700",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "119C702",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1200900",
     "precio_lista": 92547,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "1200902",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "1218200",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "121E201",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "122C200",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "122E200",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "122E201",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "1234700",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "123B200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "129B700",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "129B701",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "129C201",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "129C202",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "129D201",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "129D202",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "129E201",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "129E204",
     "precio_lista": 264652,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "131A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "1327200",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "1600902",
     "precio_lista": 61579,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1600903",
     "precio_lista": 46274,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "1618700",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "1634701",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "1637700",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "163B700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "169C700",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "169C701",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "169E201",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "169E204",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "171B300",
     "precio_lista": 84600,
     "precio_publico": 159800
    },
    {
     "marca": "ESPRIT",
     "ref": "171D300",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D301",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D302",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D303",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D304",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D305",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D306",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D307",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D308",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D309",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D310",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D311",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D312",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D313",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D314",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D315",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D316",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D317",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D318",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D319",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D320",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "171D321",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "171E300",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D300",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D301",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D302",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D303",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D304",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D305",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D306",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D307",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D308",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D309",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D310",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D311",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D312",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D313",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D314",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D315",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D316",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D317",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D318",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D319",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D320",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D321",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "172D322",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "179B300",
     "precio_lista": 84600,
     "precio_publico": 159800
    },
    {
     "marca": "ESPRIT",
     "ref": "179B301",
     "precio_lista": 84600,
     "precio_publico": 159800
    },
    {
     "marca": "ESPRIT",
     "ref": "179B302",
     "precio_lista": 84600,
     "precio_publico": 159800
    },
    {
     "marca": "ESPRIT",
     "ref": "179C300",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "179C301",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "179C302",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "179C303",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "179C304",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "179C700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "179D300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "179D301",
     "precio_lista": 100588,
     "precio_publico": 190000
    },
    {
     "marca": "ESPRIT",
     "ref": "179D302",
     "precio_lista": 100588,
     "precio_publico": 190000
    },
    {
     "marca": "ESPRIT",
     "ref": "179D303",
     "precio_lista": 100588,
     "precio_publico": 190000
    },
    {
     "marca": "ESPRIT",
     "ref": "179D304",
     "precio_lista": 100588,
     "precio_publico": 190000
    },
    {
     "marca": "ESPRIT",
     "ref": "179D305",
     "precio_lista": 104824,
     "precio_publico": 198000
    },
    {
     "marca": "ESPRIT",
     "ref": "179D306",
     "precio_lista": 104824,
     "precio_publico": 198000
    },
    {
     "marca": "ESPRIT",
     "ref": "179D307",
     "precio_lista": 104824,
     "precio_publico": 198000
    },
    {
     "marca": "ESPRIT",
     "ref": "179D308",
     "precio_lista": 104824,
     "precio_publico": 198000
    },
    {
     "marca": "ESPRIT",
     "ref": "179D309",
     "precio_lista": 115941,
     "precio_publico": 219000
    },
    {
     "marca": "ESPRIT",
     "ref": "179D310",
     "precio_lista": 115941,
     "precio_publico": 219000
    },
    {
     "marca": "ESPRIT",
     "ref": "179D311",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "179D312",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "1918200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "1918201",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "2000600",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "2000602",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "2018602",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2018606",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2018609",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2018900",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A601",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A602",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A603",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A605",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A607",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A608",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B600",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B601",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B602",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B603",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B604",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B605",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "2024606",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "2028601",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "202A601",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "202A603",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "202A606",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "202A607",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "202B600",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "202B601",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "202B602",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "202B603",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "202B604",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2036600",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2036609",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "2038601",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2038902",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A600",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A601",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A604",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "203B600",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "203B601",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "203B602",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "203B603",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "203B604",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "203B605",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "203B606",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "203B607",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "203B608",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2097607",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "2098603",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "209A600",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "209A603",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "209A900",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "209B600",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "209B601",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "209B602",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "209B603",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "209B604",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "209B605",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "209B606",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "2115603",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "2116605",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "2117900",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2123603",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "2126603",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2132907",
     "precio_lista": 26558,
     "precio_publico": 48900
    },
    {
     "marca": "ESPRIT",
     "ref": "2133600",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "213B600",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2193951",
     "precio_lista": 26558,
     "precio_publico": 48900
    },
    {
     "marca": "ESPRIT",
     "ref": "2196601",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "2196602",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2200600",
     "precio_lista": 13523,
     "precio_publico": 24900
    },
    {
     "marca": "ESPRIT",
     "ref": "2218601",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2218602",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "221A600",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "221A601",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "221A602",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "221A603",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "221B600",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "221B601",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "221B602",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2228600",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2228601",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "222A600",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "222A700",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "222A702",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2237602",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2237603",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2238600",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2238601",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "223A601",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "223B600",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "223B601",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "223B602",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "223B603",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2295901",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "2297600",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2297602",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2297604",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2297607",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "229A600",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "229A601",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "229B600",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "229B601",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "229B602",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "229B603",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2318900",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "231A600",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "233A600",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "233A601",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400723",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400724",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400730",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400737",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400742",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400746",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400752",
     "precio_lista": 35418,
     "precio_publico": 66900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400758",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400768",
     "precio_lista": 28730,
     "precio_publico": 52900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400770",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400774",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400776",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400778",
     "precio_lista": 35247,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400779",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400780",
     "precio_lista": 35247,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400782",
     "precio_lista": 35247,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400783",
     "precio_lista": 35247,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2407702",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2407703",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "2407704",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "2408705",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2408706",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2408708",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "2408709",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2409703",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "240A700",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "240A701",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "240A704",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "240A705",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "240A706",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "240A707",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2501748",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2503706",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2504700",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2504701",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2505702",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2505703",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2505704",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2505705",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506700",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506701",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506703",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506704",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506706",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506707",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506711",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506712",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506713",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506715",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506718",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507702",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507704",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507705",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507706",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507708",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507709",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507710",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507711",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2507713",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508700",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508701",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508702",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508703",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508704",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508705",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508706",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508707",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508708",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508709",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508710",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508711",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508712",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "2508713",
     "precio_lista": 87829,
     "precio_publico": 165900
    },
    {
     "marca": "ESPRIT",
     "ref": "250A700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "250A701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "250A702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "250A703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "250A704",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "250A705",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "250A706",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "250A707",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "250A708",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2617601",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2626600",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "281B701",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "281B702",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2825604",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "282A700",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "283B300",
     "precio_lista": 5824,
     "precio_publico": 11000
    },
    {
     "marca": "ESPRIT",
     "ref": "2918900",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2918901",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "291A600",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "291A603",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "291A604",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "291A606",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "291A607",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "291A901",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B600",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B602",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B604",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B606",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B608",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B900",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B902",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2923904",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "292A602",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "292A603",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "292A604",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "292A900",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "292A901",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B605",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B607",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B901",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B902",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B903",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B904",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B905",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "2938601",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2938603",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "2938605",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "293A600",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "293A601",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "293A603",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "293A604",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "293A902",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "293A903",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "293B600",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "293B601",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "293B602",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "293B603",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "293B604",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "293B605",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "293B606",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2997601",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2997616",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "2998600",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2998601",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "2998602",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2998603",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2998604",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2998605",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2998900",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2998901",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2998902",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "299A601",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "299A602",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "299A603",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "299A605",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "299A606",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "299A901",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "299B600",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "299B601",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "299B602",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "299B603",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "299B604",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "299B605",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "299B900",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "3000701",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "3016701",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "301A702",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B301",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B302",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B304",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B306",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B307",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B700",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B701",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B707",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B710",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C300",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C301",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C302",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C303",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C304",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C305",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C306",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C307",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C308",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C309",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C310",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C311",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C312",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C313",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C314",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C315",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "301C316",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D300",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D301",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D302",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D303",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D304",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D305",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D306",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D307",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D308",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D309",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D310",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D311",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D312",
     "precio_lista": 66653,
     "precio_publico": 125900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D313",
     "precio_lista": 66653,
     "precio_publico": 125900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D314",
     "precio_lista": 66653,
     "precio_publico": 125900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D315",
     "precio_lista": 66653,
     "precio_publico": 125900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D316",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D317",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D318",
     "precio_lista": 66653,
     "precio_publico": 125900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D319",
     "precio_lista": 66653,
     "precio_publico": 125900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D320",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D321",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D322",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D323",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D324",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D325",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D326",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D327",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D328",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D329",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "301D700",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E300",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E301",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E302",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E303",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E304",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E305",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E306",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E307",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E308",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E309",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E310",
     "precio_lista": 80418,
     "precio_publico": 151900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E311",
     "precio_lista": 80418,
     "precio_publico": 151900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E312",
     "precio_lista": 80418,
     "precio_publico": 151900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E313",
     "precio_lista": 80418,
     "precio_publico": 151900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E314",
     "precio_lista": 80418,
     "precio_publico": 151900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E315",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E316",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E317",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E318",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E319",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E320",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E321",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E322",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "302A706",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "302B700",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "302B702",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C300",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C301",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C302",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C303",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C304",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C305",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C306",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C307",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C308",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C309",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C310",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C311",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C312",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C313",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C314",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C315",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C316",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C317",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C318",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C319",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C320",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C321",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C322",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C323",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C324",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C325",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C326",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C327",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C328",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C329",
     "precio_lista": 62418,
     "precio_publico": 117900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C700",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C750",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C751",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C752",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D300",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D301",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D302",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D303",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D304",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D305",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D306",
     "precio_lista": 71418,
     "precio_publico": 134900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D307",
     "precio_lista": 71418,
     "precio_publico": 134900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D308",
     "precio_lista": 71418,
     "precio_publico": 134900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D309",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D310",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D311",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D312",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D313",
     "precio_lista": 71418,
     "precio_publico": 134900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D314",
     "precio_lista": 71418,
     "precio_publico": 134900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D315",
     "precio_lista": 71418,
     "precio_publico": 134900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D316",
     "precio_lista": 71418,
     "precio_publico": 134900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D317",
     "precio_lista": 72476,
     "precio_publico": 136900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D318",
     "precio_lista": 72476,
     "precio_publico": 136900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D319",
     "precio_lista": 72476,
     "precio_publico": 136900
    },
    {
     "marca": "ESPRIT",
     "ref": "302D320",
     "precio_lista": 72476,
     "precio_publico": 136900
    },
    {
     "marca": "ESPRIT",
     "ref": "3038704",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "303B700",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "303B701",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "303B702",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "303B703",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "303B704",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "303B705",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "303B706",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "303B707",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "303B708",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "303B710",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E300",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E301",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E302",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E303",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E304",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E305",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "3097708",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "3098702",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "3098717",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "3098723",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "3098724",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "309A708",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B300",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B301",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B302",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B303",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B304",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B305",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B306",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B307",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B308",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B309",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B310",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B311",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B312",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B313",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B314",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B315",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B316",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B317",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B700",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B701",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309B708",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C300",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C301",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C302",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C303",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C304",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C305",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C306",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C307",
     "precio_lista": 66653,
     "precio_publico": 125900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C308",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C309",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C310",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C311",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C312",
     "precio_lista": 66653,
     "precio_publico": 125900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C313",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C314",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C315",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C316",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C317",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C318",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C319",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C320",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C321",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C322",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C323",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C324",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C325",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C326",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C327",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C328",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C329",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C330",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C331",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C332",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C333",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C334",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C335",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C336",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C337",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C338",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C339",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C340",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C341",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C342",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C343",
     "precio_lista": 65065,
     "precio_publico": 122900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C344",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C345",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C346",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C347",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C348",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C349",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C350",
     "precio_lista": 66124,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C750",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C751",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C752",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C753",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C754",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D300",
     "precio_lista": 73535,
     "precio_publico": 138900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D301",
     "precio_lista": 73535,
     "precio_publico": 138900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D302",
     "precio_lista": 73535,
     "precio_publico": 138900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D303",
     "precio_lista": 73535,
     "precio_publico": 138900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D304",
     "precio_lista": 73535,
     "precio_publico": 138900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D305",
     "precio_lista": 73535,
     "precio_publico": 138900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D306",
     "precio_lista": 73535,
     "precio_publico": 138900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D307",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D308",
     "precio_lista": 73588,
     "precio_publico": 139000
    },
    {
     "marca": "ESPRIT",
     "ref": "309D309",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D310",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D311",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D312",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D313",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D314",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D315",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D316",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D317",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D318",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D319",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D320",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D321",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D322",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D323",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D324",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "3118712",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "3118713",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "311A700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311A704",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311A705",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B300",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B302",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B303",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B304",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B305",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B702",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B703",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B704",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B705",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B706",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B707",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B708",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B709",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B710",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B711",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B712",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B713",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B714",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B715",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311D300",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311D301",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "311D302",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "311D303",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311D304",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "311D305",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E300",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E301",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E302",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E303",
     "precio_lista": 94182,
     "precio_publico": 177900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E304",
     "precio_lista": 95771,
     "precio_publico": 180900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E305",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E306",
     "precio_lista": 95771,
     "precio_publico": 180900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E307",
     "precio_lista": 154535,
     "precio_publico": 291900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E308",
     "precio_lista": 154535,
     "precio_publico": 291900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E309",
     "precio_lista": 102971,
     "precio_publico": 194500
    },
    {
     "marca": "ESPRIT",
     "ref": "311E310",
     "precio_lista": 95771,
     "precio_publico": 180900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E311",
     "precio_lista": 95771,
     "precio_publico": 180900
    },
    {
     "marca": "ESPRIT",
     "ref": "3127701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "312A702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "312A703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "312A705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "312A707",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "312A708",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "312A710",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "312B700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "312C750",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D300",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D301",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D302",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D303",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D304",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D305",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D306",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D307",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D308",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D309",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "3138709",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "3138712",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A704",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A706",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A707",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A708",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A709",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A710",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A711",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A712",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313B700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313B701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "313B703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "3198701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3198706",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3198723",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "3198724",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A708",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A710",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A712",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A713",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "319B700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "319B703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C300",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C301",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C302",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C303",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C304",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C305",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C306",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C307",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C750",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C751",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C752",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C753",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "319D300",
     "precio_lista": 85182,
     "precio_publico": 160900
    },
    {
     "marca": "ESPRIT",
     "ref": "319D301",
     "precio_lista": 85182,
     "precio_publico": 160900
    },
    {
     "marca": "ESPRIT",
     "ref": "319D302",
     "precio_lista": 85182,
     "precio_publico": 160900
    },
    {
     "marca": "ESPRIT",
     "ref": "319D303",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "321B700",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "321D300",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "321E300",
     "precio_lista": 187888,
     "precio_publico": 354900
    },
    {
     "marca": "ESPRIT",
     "ref": "321E301",
     "precio_lista": 164594,
     "precio_publico": 310900
    },
    {
     "marca": "ESPRIT",
     "ref": "321E302",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "ESPRIT",
     "ref": "321E303",
     "precio_lista": 143418,
     "precio_publico": 270900
    },
    {
     "marca": "ESPRIT",
     "ref": "322C750",
     "precio_lista": 243477,
     "precio_publico": 459900
    },
    {
     "marca": "ESPRIT",
     "ref": "322D300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "322E300",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "3237702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "323A200",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "323A700",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "323A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "325E300",
     "precio_lista": 143418,
     "precio_publico": 270900
    },
    {
     "marca": "ESPRIT",
     "ref": "325E301",
     "precio_lista": 143418,
     "precio_publico": 270900
    },
    {
     "marca": "ESPRIT",
     "ref": "329B700",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "329B702",
     "precio_lista": 243000,
     "precio_publico": 459000
    },
    {
     "marca": "ESPRIT",
     "ref": "329B703",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "329C700",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "329C701",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "329C702",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "329C750",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "329C751",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "329C752",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "329D301",
     "precio_lista": 183124,
     "precio_publico": 345900
    },
    {
     "marca": "ESPRIT",
     "ref": "329D302",
     "precio_lista": 183124,
     "precio_publico": 345900
    },
    {
     "marca": "ESPRIT",
     "ref": "331B300",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "331B301",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "331D300",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "331D301",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "331E300",
     "precio_lista": 128594,
     "precio_publico": 242900
    },
    {
     "marca": "ESPRIT",
     "ref": "331E301",
     "precio_lista": 126476,
     "precio_publico": 238900
    },
    {
     "marca": "ESPRIT",
     "ref": "332A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "332A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "332B701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "332D300",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "332D301",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "332D302",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "332D303",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "335E300",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "335E301",
     "precio_lista": 128594,
     "precio_publico": 242900
    },
    {
     "marca": "ESPRIT",
     "ref": "339A700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "339A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "339B700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "339D302",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "341D300",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "341D301",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "341D302",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "341D303",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "341D304",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "341D305",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E300",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E301",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E302",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E303",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E304",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E305",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E306",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E307",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E308",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E309",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E310",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E311",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E312",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E313",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E314",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "341E315",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D300",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D301",
     "precio_lista": 49712,
     "precio_publico": 93900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D302",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D303",
     "precio_lista": 49712,
     "precio_publico": 93900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D304",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D305",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D306",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D307",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D308",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D309",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D310",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D311",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D312",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D313",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D314",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D315",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D316",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D317",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D318",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D319",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D320",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D321",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "342D322",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "342E300",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "342E301",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "342E302",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "342E303",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E300",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E301",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E302",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "349D300",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "349D301",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "349D302",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "349D303",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "349D304",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "ESPRIT",
     "ref": "349D305",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "ESPRIT",
     "ref": "349D306",
     "precio_lista": 52936,
     "precio_publico": 99990
    },
    {
     "marca": "ESPRIT",
     "ref": "349D307",
     "precio_lista": 52936,
     "precio_publico": 99990
    },
    {
     "marca": "ESPRIT",
     "ref": "349D308",
     "precio_lista": 52936,
     "precio_publico": 99990
    },
    {
     "marca": "ESPRIT",
     "ref": "349D309",
     "precio_lista": 52406,
     "precio_publico": 98990
    },
    {
     "marca": "ESPRIT",
     "ref": "349D310",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "349D311",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "3516700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "352A700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "352C750",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "352D300",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "352D301",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "352D302",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "352D303",
     "precio_lista": 97888,
     "precio_publico": 184900
    },
    {
     "marca": "ESPRIT",
     "ref": "353A700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "359A702",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "3617708",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "361D700",
     "precio_lista": 111176,
     "precio_publico": 210000
    },
    {
     "marca": "ESPRIT",
     "ref": "362A704",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "362B700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "362B701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "362C750",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "362C751",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "362C753",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "362C754",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "362C755",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "362C756",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "363A700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "363A701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "363A702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "363B700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "3697700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "369C700",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "369C702",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "369C703",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "369C750",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "369C751",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "371B300",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "371B301",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "371B702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "371C300",
     "precio_lista": 89894,
     "precio_publico": 169800
    },
    {
     "marca": "ESPRIT",
     "ref": "371C301",
     "precio_lista": 89894,
     "precio_publico": 169800
    },
    {
     "marca": "ESPRIT",
     "ref": "371C302",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "371D300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "371D301",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "371D302",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "371D303",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "371D304",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "371D305",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "371D306",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "371E300",
     "precio_lista": 111653,
     "precio_publico": 210900
    },
    {
     "marca": "ESPRIT",
     "ref": "3725703",
     "precio_lista": 89558,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "372B700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "372D300",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "372D301",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "372D302",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "372D303",
     "precio_lista": 103182,
     "precio_publico": 194900
    },
    {
     "marca": "ESPRIT",
     "ref": "379B300",
     "precio_lista": 87776,
     "precio_publico": 165800
    },
    {
     "marca": "ESPRIT",
     "ref": "379B301",
     "precio_lista": 87776,
     "precio_publico": 165800
    },
    {
     "marca": "ESPRIT",
     "ref": "379B302",
     "precio_lista": 87776,
     "precio_publico": 165800
    },
    {
     "marca": "ESPRIT",
     "ref": "379B303",
     "precio_lista": 87776,
     "precio_publico": 165800
    },
    {
     "marca": "ESPRIT",
     "ref": "379B304",
     "precio_lista": 87776,
     "precio_publico": 165800
    },
    {
     "marca": "ESPRIT",
     "ref": "379B305",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "379C300",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "379C301",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "379C302",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "379C303",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "379C304",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "379C305",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "379C750",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "379D300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "379D301",
     "precio_lista": 104824,
     "precio_publico": 198000
    },
    {
     "marca": "ESPRIT",
     "ref": "379D302",
     "precio_lista": 110647,
     "precio_publico": 209000
    },
    {
     "marca": "ESPRIT",
     "ref": "379D303",
     "precio_lista": 115941,
     "precio_publico": 219000
    },
    {
     "marca": "ESPRIT",
     "ref": "379D304",
     "precio_lista": 104824,
     "precio_publico": 198000
    },
    {
     "marca": "ESPRIT",
     "ref": "379D305",
     "precio_lista": 115941,
     "precio_publico": 219000
    },
    {
     "marca": "ESPRIT",
     "ref": "379D306",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "379D307",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "379D308",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "379D309",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "379D310",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "379D311",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "379D312",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "379D313",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "381B300",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "381E300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "381E301",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "381E302",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "381E303",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "381E304",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "381E305",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "381E306",
     "precio_lista": 164064,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "381E307",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "381E308",
     "precio_lista": 132829,
     "precio_publico": 250900
    },
    {
     "marca": "ESPRIT",
     "ref": "381E309",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "385E300",
     "precio_lista": 132829,
     "precio_publico": 250900
    },
    {
     "marca": "ESPRIT",
     "ref": "389B301",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "389C750",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "4014702",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4017712",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4018705",
     "precio_lista": 22222,
     "precio_publico": 41975
    },
    {
     "marca": "ESPRIT",
     "ref": "401A700",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "401A702",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "401A703",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "401A704",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "401A705",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "401A706",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "401A708",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "401B700",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "401B701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "401B711",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "401B712",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4027702",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4027706",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4028703",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4028704",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4028707",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4028708",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "402A700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "402A701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "402A702",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "402A703",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "402A705",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "402A706",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "402B701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "402B702",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "402B703",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "402B706",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4034200",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4034704",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4036700",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4037701",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4037703",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4037708",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4037711",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4037713",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4038704",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4038705",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4038706",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4038708",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4038712",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A701",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A704",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A705",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A706",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A707",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "403A708",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "403B700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "403B701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "403B702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "403B703",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4096719",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4097710",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4097712",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4097714",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4097715",
     "precio_lista": 22222,
     "precio_publico": 41975
    },
    {
     "marca": "ESPRIT",
     "ref": "4098701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098702",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098705",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098707",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098709",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A700",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A702",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A705",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409B700",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "409B701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409B703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409B706",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "409C200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4116705",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4117717",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4118200",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4118700",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4118701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4118705",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4118706",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4118708",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4118710",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A704",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A705",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A706",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A707",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A708",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A709",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A710",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A711",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A712",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B200",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B201",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B700",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B703",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B704",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B705",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B706",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B708",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "411B709",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D705",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D710",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E200",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E209",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E214",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E215",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E700",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E701",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "411E702",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "4127700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4127706",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4127709",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4127711",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4127712",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4127715",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128705",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128708",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128710",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128713",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128714",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128715",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A706",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A708",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A709",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412A710",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B201",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B704",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B705",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B706",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B720",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412C701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D700",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D701",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D702",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D704",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D708",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D709",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D710",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D712",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E200",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E205",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E211",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "4133704",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "4136704",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4136705",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4137703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4137704",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4137706",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4137709",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138702",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138705",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138706",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138713",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138714",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138718",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138720",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138721",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A702",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A704",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A707",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A708",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B201",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B705",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B706",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "413B708",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4196201",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4196715",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4196717",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197705",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197712",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197717",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197719",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197722",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198700",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198703",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198704",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198705",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198708",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198715",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198716",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198718",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198719",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A704",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A705",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A706",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A709",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A710",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A711",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B200",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B701",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B704",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B705",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B706",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B708",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B709",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B710",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B711",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B714",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B715",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B716",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B717",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419B718",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C200",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C201",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C203",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C205",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C206",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D215",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D700",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D702",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D703",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "419D704",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "419E219",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "4217204",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "4218200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4218201",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4218204",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "4218206",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "421A200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "421A204",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "421A700",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B202",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B203",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B206",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B700",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B701",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B702",
     "precio_lista": 243477,
     "precio_publico": 459900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B704",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B706",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B707",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B708",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B709",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "421C203",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "421C204",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "421C206",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "421C207",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "421C208",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D202",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D203",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D204",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D205",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D208",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D211",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D707",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D709",
     "precio_lista": 312300,
     "precio_publico": 589900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D712",
     "precio_lista": 365241,
     "precio_publico": 689900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D713",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E200",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E203",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E204",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E205",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E207",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E208",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E209",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E214",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E222",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E225",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E700",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E701",
     "precio_lista": 423477,
     "precio_publico": 799900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E702",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E703",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E704",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "422A202",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "422A702",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "422A704",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "422A705",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B201",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B203",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B701",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B704",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B705",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B712",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "422C200",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "422C201",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "422C206",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "422C207",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "422C209",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D203",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D204",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D205",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D207",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D208",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D209",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D210",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D211",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D214",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D215",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E200",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E201",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E204",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E205",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E206",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E207",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E208",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E210",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E211",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E221",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E701",
     "precio_lista": 423477,
     "precio_publico": 799900
    },
    {
     "marca": "ESPRIT",
     "ref": "4238703",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "4238705",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "4238713",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "423A700",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "423A701",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "423A704",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "423A705",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "423A707",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B201",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B202",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B203",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B207",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B209",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B700",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B701",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B704",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B705",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "423B707",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "ESPRIT",
     "ref": "4295206",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "4295208",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "4297202",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "4298701",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "4298704",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "4298705",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "4298710",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "4298715",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A200",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A201",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A202",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A203",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A205",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A207",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A700",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A701",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A702",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A703",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429B700",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "429B701",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "429B702",
     "precio_lista": 243477,
     "precio_publico": 459900
    },
    {
     "marca": "ESPRIT",
     "ref": "429B703",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "429B705",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "429B706",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429B707",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C200",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C202",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C203",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C204",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C205",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C207",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C208",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C209",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C210",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C211",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C212",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C702",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C800",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C801",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D201",
     "precio_lista": 217006,
     "precio_publico": 409900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D202",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D203",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D204",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D205",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D207",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D208",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D209",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D210",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D211",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D212",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D213",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D214",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D215",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D216",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D220",
     "precio_lista": 217006,
     "precio_publico": 409900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D225",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D702",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D705",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D707",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D708",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D709",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D710",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E202",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E204",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E208",
     "precio_lista": 248770,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E213",
     "precio_lista": 248770,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E228",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "4313708",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317700",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317703",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317704",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317705",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317707",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318701",
     "precio_lista": 22222,
     "precio_publico": 41975
    },
    {
     "marca": "ESPRIT",
     "ref": "4318702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318705",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "431A703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431B200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "431B700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431B702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431C700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D703",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D704",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D706",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E200",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "431E700",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "4326703",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327703",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327704",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327705",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327706",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327707",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327708",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4327709",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4328700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4328701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4328703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4328704",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4328706",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4328707",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "432A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432A703",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "432B205",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "432B700",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "432B701",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "432B702",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "432B703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "432B704",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "432B706",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "432B707",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "4332714",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4336707",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337701",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337704",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4337705",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338703",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338705",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338706",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338707",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338708",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338709",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338710",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338712",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A704",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A705",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A706",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433A707",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B200",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B202",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B704",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B706",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B707",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "433B708",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4391723",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4394200",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4396701",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4396708",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4396712",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4396713",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397703",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397704",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397707",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397711",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4397712",
     "precio_lista": 22222,
     "precio_publico": 41975
    },
    {
     "marca": "ESPRIT",
     "ref": "4398702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398703",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398704",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398705",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398706",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398707",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A201",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A704",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A705",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A706",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "439A707",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "439B700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439B702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439B703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439B704",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "439B708",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439C201",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "439D700",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "4418702",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "441B700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "441B702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "441E200",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "4428700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "442A700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "442B708",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4437701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4438700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4438701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4438702",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "443A700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "443A701",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "443A702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "449A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "449B700",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "449B701",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "449B702",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "449D200",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "4618200",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4618202",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4618204",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "461B202",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "461B700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "461B701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "461B703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "461B704",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "461B705",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "461C201",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "461C204",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "461C700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D201",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D203",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D700",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D701",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D702",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D704",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D705",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D706",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D707",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D709",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E200",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E201",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E202",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E203",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E204",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E207",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E208",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E209",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E210",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E212",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E701",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E702",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "4627706",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4628700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4628705",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4628710",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "462A700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "462A701",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "462B203",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "462B700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "462C202",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D202",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D203",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D204",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D701",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D704",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D706",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "462E203",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "462E204",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "462E205",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "462E206",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "462E207",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "462E208",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "462E224",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "4638703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4638705",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "463A701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "463A704",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "463A705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "463A706",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "463B201",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "463B202",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "463B203",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "463B700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "463B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "463B703",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "463B706",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4696703",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4696707",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4697705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4698705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "469A201",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "469A700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "469A701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "469A704",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469B200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "469B202",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469B203",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "469B700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "469B702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C201",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C202",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C208",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C209",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C211",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D200",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D201",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D203",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D204",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D208",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D212",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D220",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D221",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D223",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D700",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D704",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D705",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D706",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E200",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E201",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E202",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E207",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E209",
     "precio_lista": 174652,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E210",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E211",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E213",
     "precio_lista": 174652,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E214",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "4718700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "471A700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "471C700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4727700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4728700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4728701",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "472A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "472A701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "472B700",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "4738700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "473A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "473A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4798700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4917704",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4918200",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "4918201",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4918203",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "4918702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A202",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A203",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A701",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A704",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "491B701",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "491B702",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "491B703",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "491C700",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "491C701",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "491E204",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "4927710",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "4928701",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "4928702",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "4928703",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "492A700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "492B700",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "492B701",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "492B703",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "492B711",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "492E204",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "4937702",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "4937713",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "4938701",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4938703",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "4938708",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4938709",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4938710",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "493A200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "493A701",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "493A703",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "493A704",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "493A705",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "493A706",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "493B700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "493B711",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "4996701",
     "precio_lista": 157446,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "4997702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "4997703",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "4997705",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "4997716",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "4997717",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "4998700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4998701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4998702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4998703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "499A700",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "499A702",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "499A703",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "499A704",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "499A705",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "499A706",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "499A707",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "499B701",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "499B702",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "499C200",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "499D200",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "499D700",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "499E209",
     "precio_lista": 301711,
     "precio_publico": 569900
    },
    {
     "marca": "ESPRIT",
     "ref": "5067702",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "5067703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "5067704",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "5067709",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "5067715",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "5067722",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "5067725",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "5167700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "5167702",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "5167704",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "5167707",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "5167708",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "5167715",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "5167719",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "5167720",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "5167724",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "5167725",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "5367700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "5367701",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "5367703",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "5367704",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "5367705",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "5667701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "5667705",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "5767703",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "5967705",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "5967711",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "6017703",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601A700",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601A701",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "601A702",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601A703",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "601A706",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "601B700",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "601B701",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601B702",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C200",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C700",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D300",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D301",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D302",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D303",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D304",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D305",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D306",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D307",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D308",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D309",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D310",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D311",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D312",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D313",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D314",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601D315",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E300",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E301",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E302",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E303",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E304",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E305",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E306",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E307",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E308",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E309",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E310",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E311",
     "precio_lista": 48653,
     "precio_publico": 91900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E312",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E313",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E314",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E315",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E316",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E317",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E318",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E319",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "ESPRIT",
     "ref": "601E320",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "ESPRIT",
     "ref": "6027702",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6028701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6028703",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6028704",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "602A702",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "602A703",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602A704",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602A706",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "602A707",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602B718",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C700",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C702",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "602C703",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D301",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D302",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D303",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D304",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D305",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D306",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D307",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D308",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D309",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D310",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D311",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D312",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D313",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D314",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D315",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D700",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D701",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D702",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D704",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "6037700",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "6038705",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6038708",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A200",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A701",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A702",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A703",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A705",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "603B200",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "603B700",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "603B702",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "603B705",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6097705",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "6097709",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6097712",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098702",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098704",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098705",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098706",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098707",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098708",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A701",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A704",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A706",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609B700",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609B702",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609B703",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "609B704",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "609B705",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609B706",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C700",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C701",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C702",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D300",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D301",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D302",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D303",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D304",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D305",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D306",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D307",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D700",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D702",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D704",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D705",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118200",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118203",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118208",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118704",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118709",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118710",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118712",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118715",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A201",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A702",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A703",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A704",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A707",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A709",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A710",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A711",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A713",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A714",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B201",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B203",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B702",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B704",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B707",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B708",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B709",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B710",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B711",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B713",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B714",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B716",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B717",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B718",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D704",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D709",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D712",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D714",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D715",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E216",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E300",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E301",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E302",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E303",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E304",
     "precio_lista": 93124,
     "precio_publico": 175900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E305",
     "precio_lista": 86771,
     "precio_publico": 163900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E306",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E307",
     "precio_lista": 85182,
     "precio_publico": 160900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E308",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E309",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E310",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E311",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E312",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E313",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6127718",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6128700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6128703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6128704",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6128707",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A702",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A703",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A704",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A706",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A709",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A710",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A711",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A714",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B202",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B203",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B702",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B705",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B708",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B713",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B714",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B717",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B720",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C201",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C203",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C204",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C710",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C751",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C753",
     "precio_lista": 90477,
     "precio_publico": 170900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D316",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D317",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D318",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D319",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D320",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D321",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D322",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D323",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D324",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D325",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D326",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D327",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D328",
     "precio_lista": 82006,
     "precio_publico": 154900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D703",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D704",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D705",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D706",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D707",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D708",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E219",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "6134716",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6136704",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138703",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138704",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138707",
     "precio_lista": 73535,
     "precio_publico": 138900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138709",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138712",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138714",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138718",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A201",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A703",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A704",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A705",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A706",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A707",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A708",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A709",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A712",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A714",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A715",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A716",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A717",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A718",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A719",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B200",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B203",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B704",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B706",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B709",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B711",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B718",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B719",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B722",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6195707",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6196716",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6196717",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6197200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6197711",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198705",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198706",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198707",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198708",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198711",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198712",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198713",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198714",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198716",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198717",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198720",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198721",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A704",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A705",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A706",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A709",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A710",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A711",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A712",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A714",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A715",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A717",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A718",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A719",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A720",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A721",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B202",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B703",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B704",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B705",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B706",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B707",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B708",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B709",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B710",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B711",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B712",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B713",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619B714",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C202",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C203",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C205",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C712",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C713",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C714",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C715",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D200",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D301",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D302",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D303",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D315",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D316",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D317",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D318",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D700",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D701",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D702",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D704",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D705",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D706",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D707",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D708",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D709",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "619E208",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "6218200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6218711",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "ESPRIT",
     "ref": "621A205",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "621A207",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "621A701",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "621A707",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "621A712",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B201",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B202",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B203",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B204",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B205",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B206",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B207",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B700",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B702",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B703",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B704",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B710",
     "precio_lista": 243477,
     "precio_publico": 459900
    },
    {
     "marca": "ESPRIT",
     "ref": "621C202",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "621C203",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "621C204",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D200",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D205",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D700",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D702",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D705",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D706",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D716",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D717",
     "precio_lista": 528829,
     "precio_publico": 998900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D720",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D721",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D722",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D723",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D724",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D726",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D728",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D729",
     "precio_lista": 528829,
     "precio_publico": 998900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D731",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D732",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D733",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E200",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E202",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E203",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E204",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E206",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E210",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E300",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E301",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E302",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E303",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E304",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E305",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E306",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E307",
     "precio_lista": 166712,
     "precio_publico": 314900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E308",
     "precio_lista": 165124,
     "precio_publico": 311900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E700",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E701",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E702",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E703",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E704",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "621E717",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "6225202",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6228707",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A201",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A202",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A700",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A701",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A704",
     "precio_lista": 333476,
     "precio_publico": 629900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A706",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A707",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A708",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A709",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A711",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B200",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B201",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B206",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B701",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B702",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B703",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B704",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B706",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B707",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B710",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B713",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B714",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B716",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B719",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C200",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C201",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C203",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C204",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C205",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C206",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C207",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C705",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C716",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "622C718",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D203",
     "precio_lista": 217006,
     "precio_publico": 409900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D204",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D205",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D700",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D707",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D715",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D718",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D720",
     "precio_lista": 285829,
     "precio_publico": 539900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D722",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D723",
     "precio_lista": 217006,
     "precio_publico": 409900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E200",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E203",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E204",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E205",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E206",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E207",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E700",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E701",
     "precio_lista": 370535,
     "precio_publico": 699900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E702",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E703",
     "precio_lista": 370535,
     "precio_publico": 699900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E704",
     "precio_lista": 423477,
     "precio_publico": 799900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E707",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E708",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E714",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "6237204",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "6237714",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "6238200",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "6238205",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "6238700",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "6238706",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A202",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A203",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A204",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A206",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A207",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A208",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A209",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A701",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A702",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A705",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A707",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B203",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B204",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B205",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B207",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B208",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B209",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B700",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B702",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B715",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B718",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "6297200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6297700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6298702",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A201",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A202",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A204",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A205",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A206",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A207",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A209",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A211",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A700",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "629B700",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "629B701",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "ESPRIT",
     "ref": "629B702",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "629B703",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "629B704",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "629B705",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C200",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C201",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C202",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C203",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C204",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C205",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C206",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C207",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C208",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C209",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C700",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C702",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C703",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C704",
     "precio_lista": 243477,
     "precio_publico": 459900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C714",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C715",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C716",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C717",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C718",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C719",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C720",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C723",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C724",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C725",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C726",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C727",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C801",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C803",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C811",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C813",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D200",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D201",
     "precio_lista": 243477,
     "precio_publico": 459900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D202",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D204",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D205",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D207",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D300",
     "precio_lista": 166712,
     "precio_publico": 314900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D301",
     "precio_lista": 153000,
     "precio_publico": 289000
    },
    {
     "marca": "ESPRIT",
     "ref": "629D302",
     "precio_lista": 316535,
     "precio_publico": 597900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D304",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D305",
     "precio_lista": 187888,
     "precio_publico": 354900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D307",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D700",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D701",
     "precio_lista": 476418,
     "precio_publico": 899900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D702",
     "precio_lista": 217006,
     "precio_publico": 409900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D703",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D705",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D707",
     "precio_lista": 476418,
     "precio_publico": 899900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D709",
     "precio_lista": 397006,
     "precio_publico": 749900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D710",
     "precio_lista": 502888,
     "precio_publico": 949900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D717",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D718",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D719",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D720",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D721",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D722",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E200",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E207",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "6313709",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6316706",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317700",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317701",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317703",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317704",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317705",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6317706",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318200",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318704",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318705",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6318706",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A200",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A704",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A705",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A706",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631A707",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "631B700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631B701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "631B702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "631B706",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "631B707",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D700",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D702",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D703",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D705",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D706",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D707",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D708",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "631D709",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "631E700",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "6325700",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327703",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327704",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327706",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327707",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327708",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6327709",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6328701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6328702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6328703",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6328704",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6328705",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6328706",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "632A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "632A701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632A702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "632A703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "632A704",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B700",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B701",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B703",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "632B718",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "632C750",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D302",
     "precio_lista": 104771,
     "precio_publico": 197900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D701",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D702",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "632E200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "6334706",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337704",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6337705",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6338704",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "633A703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "633B700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "633B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6392712",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6393774",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6396701",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6396704",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6396706",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397703",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397704",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397707",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397708",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397709",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6397710",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398704",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398705",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398707",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398708",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398709",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A713",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639B701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "639B702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "639B704",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D704",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D705",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "6418700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "641A700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "641A701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "641A702",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "641B700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "641B701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "641B702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "641C700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "641E700",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "641E702",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "641E703",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "642A700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "642A701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "642B710",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "643A200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "643A201",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "643A700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "643B200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "643B201",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "649A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "649B700",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "649B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "651D701",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "652C700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "652C701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6597703",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6598700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "659A700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "659A701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "659A703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6617203",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6618203",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "661A701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "661B200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "661B700",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "661B703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D202",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D203",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D700",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D701",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D702",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D703",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D704",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D705",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D706",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D708",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D709",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D710",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D711",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D713",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D714",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D715",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D717",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D718",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D720",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D721",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D722",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D724",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D725",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D726",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D728",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D730",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E201",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E202",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E204",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E205",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E206",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E208",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E213",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E700",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E701",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E702",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E703",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E704",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E705",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E706",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E707",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E708",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E709",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E711",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E712",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E713",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E714",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E715",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "6627706",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6628704",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "662A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "662A701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662A704",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "662A707",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "662A708",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "662B200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "662B202",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "662B203",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "662B700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "662B701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C202",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C203",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C205",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C701",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C704",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C705",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D202",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D204",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D210",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D709",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D711",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D712",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D713",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D714",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D715",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D716",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D717",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D718",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D719",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D720",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D721",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D722",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D723",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D724",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D726",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E200",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E201",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E202",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E204",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E205",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E206",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E216",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E225",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E700",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E701",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E702",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "6637709",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6638702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6638707",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "663A709",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "663A711",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "663A713",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "663A714",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "663B200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "663B202",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "663B204",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "663B700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "663B701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "663B702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "663B706",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6697703",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6697706",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6698700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6698708",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6698709",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "669A200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "669A201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "669A701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "669A702",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B202",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B203",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B703",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B704",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B705",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C202",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C203",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C204",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C206",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C208",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C209",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C210",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C702",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C704",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C705",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C707",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C708",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C710",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D201",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D204",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D205",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D206",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D208",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D211",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D700",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D701",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D702",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D704",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D705",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D706",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D707",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D708",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D709",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D710",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D711",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D712",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D713",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D714",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D715",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D716",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D717",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D718",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D719",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D726",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E201",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E202",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E203",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E204",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E206",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E207",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E212",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E215",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "6718700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "671A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671A701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "671A702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671A703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671A704",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B704",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B705",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "671C700",
     "precio_lista": 107853,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "671D701",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "6728701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6728702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6728706",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "672A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672A702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B300",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B302",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B700",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B701",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B710",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B712",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B770",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B772",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B773",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D700",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D702",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D704",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D705",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D706",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D707",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D708",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D709",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D710",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D711",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D712",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D713",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D714",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "6737703",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "6738200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6738700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6738702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6738704",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "673A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "673A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "673A702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "673B700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "673B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "673B703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "673B705",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "673B771",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "673B772",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "6796712",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6797701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6797702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6797704",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "6798704",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "679A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679A701",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "679A702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "679B700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679B702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679B703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679B704",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "679B705",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "679D702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "681E300",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "681E301",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "681E302",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "681E303",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "681E304",
     "precio_lista": 160359,
     "precio_publico": 302900
    },
    {
     "marca": "ESPRIT",
     "ref": "681E305",
     "precio_lista": 119065,
     "precio_publico": 224900
    },
    {
     "marca": "ESPRIT",
     "ref": "682E300",
     "precio_lista": 119065,
     "precio_publico": 224900
    },
    {
     "marca": "ESPRIT",
     "ref": "685E300",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "685E301",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "6917706",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6917709",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "6918701",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "691A200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "691A203",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "691A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "691B201",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "691B700",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "691C700",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "692B710",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "692D700",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "6934701",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6937700",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "6938704",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A201",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A202",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A203",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A701",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "693A703",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B200",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "693B700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "699A700",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "699A702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "699A705",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "699B700",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "699B702",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "699B704",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E204",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "700C300",
     "precio_lista": 23771,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "700C301",
     "precio_lista": 23771,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "700C302",
     "precio_lista": 23771,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "7047032",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068100",
     "precio_lista": 9580,
     "precio_publico": 19900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068101",
     "precio_lista": 13317,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068102",
     "precio_lista": 17248,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068103",
     "precio_lista": 24110,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068104",
     "precio_lista": 11444,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068105",
     "precio_lista": 13672,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068106",
     "precio_lista": 15894,
     "precio_publico": 41900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068107",
     "precio_lista": 18044,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068108",
     "precio_lista": 19539,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068111",
     "precio_lista": 11100,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068112",
     "precio_lista": 11918,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068113",
     "precio_lista": 18800,
     "precio_publico": 41900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068119",
     "precio_lista": 17158,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068120",
     "precio_lista": 15990,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068121",
     "precio_lista": 13000,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "7068124",
     "precio_lista": 18696,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "7147008",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168100",
     "precio_lista": 14082,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168101",
     "precio_lista": 18218,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168102",
     "precio_lista": 21222,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168103",
     "precio_lista": 23714,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168105",
     "precio_lista": 25600,
     "precio_publico": 62900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168106",
     "precio_lista": 23500,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168107",
     "precio_lista": 13597,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168108",
     "precio_lista": 20900,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168110",
     "precio_lista": 15500,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168111",
     "precio_lista": 20800,
     "precio_publico": 54900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168114",
     "precio_lista": 16513,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "7168116",
     "precio_lista": 23000,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "7268100",
     "precio_lista": 22889,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "7268102",
     "precio_lista": 25962,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "7268103",
     "precio_lista": 25900,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "7268104",
     "precio_lista": 26000,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "7346016",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "7368100",
     "precio_lista": 29000,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "7468100",
     "precio_lista": 20000,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "7468101",
     "precio_lista": 20990,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "7468102",
     "precio_lista": 24592,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "7468103",
     "precio_lista": 28000,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "7568101",
     "precio_lista": 24500,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "770C300",
     "precio_lista": 89894,
     "precio_publico": 169800
    },
    {
     "marca": "ESPRIT",
     "ref": "770C301",
     "precio_lista": 89894,
     "precio_publico": 169800
    },
    {
     "marca": "ESPRIT",
     "ref": "770C302",
     "precio_lista": 89894,
     "precio_publico": 169800
    },
    {
     "marca": "ESPRIT",
     "ref": "770C303",
     "precio_lista": 89894,
     "precio_publico": 169800
    },
    {
     "marca": "ESPRIT",
     "ref": "7758004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "7758009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "7758012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "7768100",
     "precio_lista": 30569,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "7768102",
     "precio_lista": 34458,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "7768103",
     "precio_lista": 12000,
     "precio_publico": 9900
    },
    {
     "marca": "ESPRIT",
     "ref": "7768104",
     "precio_lista": 37800,
     "precio_publico": 84900
    },
    {
     "marca": "ESPRIT",
     "ref": "7868100",
     "precio_lista": 14500,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "7868101",
     "precio_lista": 16472,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "7868102",
     "precio_lista": 19527,
     "precio_publico": 53900
    },
    {
     "marca": "ESPRIT",
     "ref": "7868103",
     "precio_lista": 21278,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "7868104",
     "precio_lista": 25990,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "7868106",
     "precio_lista": 45000,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "7868107",
     "precio_lista": 12000,
     "precio_publico": 9990
    },
    {
     "marca": "ESPRIT",
     "ref": "7868108",
     "precio_lista": 19790,
     "precio_publico": 53900
    },
    {
     "marca": "ESPRIT",
     "ref": "7868109",
     "precio_lista": 17650,
     "precio_publico": 53900
    },
    {
     "marca": "ESPRIT",
     "ref": "7868110",
     "precio_lista": 18850,
     "precio_publico": 53900
    },
    {
     "marca": "ESPRIT",
     "ref": "7968100",
     "precio_lista": 19000,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "7968102",
     "precio_lista": 30566,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "8037201",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "8037207",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "8037208",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "8037209",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "8037210",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "8038203",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "8096252",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "8096253",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "8337202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "8337204",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "8338207",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018200",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018302",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "901A700",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B200",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B701",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B703",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9027300",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9027302",
     "precio_lista": 28006,
     "precio_publico": 52900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028304",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B200",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9035300",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "9036301",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9036303",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037200",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037301",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038300",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038301",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038302",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A200",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "903A701",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B201",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B700",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9096301",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "ESPRIT",
     "ref": "9097204",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9097304",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098300",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098302",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098303",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "909A202",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "909B700",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909B701",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C200",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C202",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D300",
     "precio_lista": 48124,
     "precio_publico": 90900
    },
    {
     "marca": "ESPRIT",
     "ref": "9117200",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9117700",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A300",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A700",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A702",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B203",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C200",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C202",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C203",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "911C204",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9127204",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128203",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128204",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A202",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A203",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A204",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912A205",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B201",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B202",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B205",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B206",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137201",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137206",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137209",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138203",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A202",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A203",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A204",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B201",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B203",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B207",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E300",
     "precio_lista": 79412,
     "precio_publico": 150000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E301",
     "precio_lista": 79412,
     "precio_publico": 150000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E302",
     "precio_lista": 90000,
     "precio_publico": 170000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E303",
     "precio_lista": 90000,
     "precio_publico": 170000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E304",
     "precio_lista": 90000,
     "precio_publico": 170000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E305",
     "precio_lista": 79412,
     "precio_publico": 150000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E306",
     "precio_lista": 79412,
     "precio_publico": 150000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E307",
     "precio_lista": 84706,
     "precio_publico": 160000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E308",
     "precio_lista": 84706,
     "precio_publico": 160000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E309",
     "precio_lista": 78353,
     "precio_publico": 148000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E310",
     "precio_lista": 78353,
     "precio_publico": 148000
    },
    {
     "marca": "ESPRIT",
     "ref": "915E311",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E312",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E313",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E314",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E315",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E316",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E317",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E323",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E324",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E325",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "915E326",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9195302",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197203",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197206",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9197208",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198204",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198701",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198702",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A201",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A202",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A203",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A206",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A207",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A210",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "919B700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "919C201",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9218701",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "921A201",
     "precio_lista": 126529,
     "precio_publico": 239000
    },
    {
     "marca": "ESPRIT",
     "ref": "921B201",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "921B202",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "921B700",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "921B701",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "921C200",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "921D300",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "921D301",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "9228200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "9228202",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "922A202",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "922B200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "922C200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "922E200",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "9237207",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "923A201",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "923A202",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "923A700",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "923A701",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "9295201",
     "precio_lista": 157446,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "9297200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9297204",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298200",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298201",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298202",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298205",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298702",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "929A201",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "929A206",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "929C200",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "929C800",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "929C803",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "929D200",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "929D201",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "9318702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "931A700",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9328700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9328701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "932A700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "932A701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "932A702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "932A703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9331701",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9338700",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9397700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9397701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9498200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "949B700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "952D300",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "952D301",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9537200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9597200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9597201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9597700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9597701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "959A700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "961A200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "961A202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "961A204",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "961B200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "961B203",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "961B205",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "961D200",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "962A202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "962A203",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "962B200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "962B201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "962B202",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "962B203",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "962B206",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "962C200",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "962E200",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "962E201",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "963A200",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "963A201",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "963A202",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "963A204",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "963B200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "963B202",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "963B203",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "963B204",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "963B700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "963B701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9698200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9698201",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "969A200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "969A202",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "969A203",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "969B700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "969C200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "969C202",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "969D200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "9716303",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9717300",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9718300",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9718701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A301",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A302",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A303",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B300",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B301",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B302",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B303",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B304",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B311",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B312",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B701",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9726302",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9727303",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9728300",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9728600",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A302",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B300",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B301",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B302",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B700",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9737302",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9737303",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738301",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A300",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A301",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A303",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B300",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B301",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B302",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B303",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B304",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9796300",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9797300",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9797600",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9797700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798302",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798304",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798305",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A300",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A301",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A302",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A303",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A304",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A704",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "981A200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "981A702",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "983A200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "983B201",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "991A200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "991B201",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "992A202",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B202",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C200",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "992C300",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9937200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "993B200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9997201",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9997202",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998201",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998202",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998203",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998204",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A202",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A203",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "101C100",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "101C101",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "103A100",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C100",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "111C102",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "113A100",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "1300104",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "1328100",
     "precio_lista": 24300,
     "precio_publico": 45900
    },
    {
     "marca": "ESPRIT",
     "ref": "1600900",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "1600901",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "161A100",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "162A100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "162C101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "163A100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "163A101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "163B103",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "163B104",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "163B105",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "169A102",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "169B102",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "169C100",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "169C101",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "169C102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "169D101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "171A107",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "171A108",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A100",
     "precio_lista": 10588,
     "precio_publico": 20000
    },
    {
     "marca": "ESPRIT",
     "ref": "201B111",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "201D109",
     "precio_lista": 132353,
     "precio_publico": 250000
    },
    {
     "marca": "ESPRIT",
     "ref": "201D113",
     "precio_lista": 206471,
     "precio_publico": 390000
    },
    {
     "marca": "ESPRIT",
     "ref": "202B102",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "209A102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "209A103",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "209A105",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "219A100",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "261B102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "261B104",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "261B105",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "261B110",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "261B111",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "261B112",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "261B113",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "261B114",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "262B100",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "262B103",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "263A100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "263A103",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "269A100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "269A102",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "269A103",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "281B100",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "281B101",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "281B300",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "281E100",
     "precio_lista": 42353,
     "precio_publico": 80000
    },
    {
     "marca": "ESPRIT",
     "ref": "281E101",
     "precio_lista": 42353,
     "precio_publico": 80000
    },
    {
     "marca": "ESPRIT",
     "ref": "281E300",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "281E301",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "281E302",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "281E303",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "282D100",
     "precio_lista": 23771,
     "precio_publico": 44900
    },
    {
     "marca": "ESPRIT",
     "ref": "282D109",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "ESPRIT",
     "ref": "289D101",
     "precio_lista": 97941,
     "precio_publico": 185000
    },
    {
     "marca": "ESPRIT",
     "ref": "289D300",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "289D301",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "289D302",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "289D303",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "289D304",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "289D306",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B100",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B101",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B102",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B103",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B104",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B105",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B106",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B107",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B108",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B109",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B110",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "299A100",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "301A113",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "301A116",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "301A118",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301A121",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "301A122",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "301A123",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "ESPRIT",
     "ref": "301A124",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "301A125",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B101",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B103",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B104",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "ESPRIT",
     "ref": "301E500",
     "precio_lista": 52835,
     "precio_publico": 99800
    },
    {
     "marca": "ESPRIT",
     "ref": "302A105",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "302B102",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "302B105",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "302B108",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C103",
     "precio_lista": 49071,
     "precio_publico": 92691
    },
    {
     "marca": "ESPRIT",
     "ref": "302C104",
     "precio_lista": 52941,
     "precio_publico": 100000
    },
    {
     "marca": "ESPRIT",
     "ref": "302C105",
     "precio_lista": 52941,
     "precio_publico": 100000
    },
    {
     "marca": "ESPRIT",
     "ref": "3038141",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "3098100",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "3098117",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "3098119",
     "precio_lista": 74594,
     "precio_publico": 140900
    },
    {
     "marca": "ESPRIT",
     "ref": "309A105",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "309A106",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309A108",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "309A109",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "309D500",
     "precio_lista": 52835,
     "precio_publico": 99800
    },
    {
     "marca": "ESPRIT",
     "ref": "311B100",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311B101",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E101",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "311E102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "312B102",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "312B103",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "312B106",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D111",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "312D112",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "3138128",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3138137",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A102",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "3198102",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "3198103",
     "precio_lista": 87300,
     "precio_publico": 164900
    },
    {
     "marca": "ESPRIT",
     "ref": "3198104",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "3198105",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "3198110",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3198114",
     "precio_lista": 80947,
     "precio_publico": 152900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A103",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A104",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A105",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A106",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A108",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A109",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "319A110",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "319D500",
     "precio_lista": 90000,
     "precio_publico": 170000
    },
    {
     "marca": "ESPRIT",
     "ref": "319D501",
     "precio_lista": 90000,
     "precio_publico": 170000
    },
    {
     "marca": "ESPRIT",
     "ref": "3298100",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "331A104",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "331A105",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "331B100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "331B101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "332B100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "332B101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "332B102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "3338108",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "333A101",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "3398107",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "339A101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "339A103",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "339D500",
     "precio_lista": 100588,
     "precio_publico": 190000
    },
    {
     "marca": "ESPRIT",
     "ref": "352B102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "371E111",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "372B100",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "372B101",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "372D111",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "379A103",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "381A105",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "381A106",
     "precio_lista": 76712,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "383A100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "389A100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "409C100",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E111",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "419C100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "421E110",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D101",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D104",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "429D100",
     "precio_lista": 439359,
     "precio_publico": 829900
    },
    {
     "marca": "ESPRIT",
     "ref": "461A104",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "461C100",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "461C101",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D100",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D101",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "461D103",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E105",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "461E106",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "462A100",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "462A101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "462B100",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "462B101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "462C100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "462C101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "462C102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D102",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D103",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D104",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D110",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D112",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D116",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "462E101",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "462E102",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "4638100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "463A102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "463B101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "463B102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "469A102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "469A103",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C102",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C103",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C104",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C105",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C106",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "469C110",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D102",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D105",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D107",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D109",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D110",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D111",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D120",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D121",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "469D122",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "471B102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "472D100",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "472D101",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C100",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C101",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C102",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C103",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "601C104",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6026100",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D300",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "609C100",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "609D500",
     "precio_lista": 42247,
     "precio_publico": 79800
    },
    {
     "marca": "ESPRIT",
     "ref": "609E100",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E101",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "609E102",
     "precio_lista": 68770,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117100",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B110",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C104",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C105",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611C108",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E101",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "611E103",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C102",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D101",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D103",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D300",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D303",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D304",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D305",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D306",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D307",
     "precio_lista": 114300,
     "precio_publico": 215900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D308",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D309",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D310",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D311",
     "precio_lista": 114300,
     "precio_publico": 215900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D312",
     "precio_lista": 114300,
     "precio_publico": 215900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D313",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "612D314",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "612E109",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A100",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B102",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A102",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C100",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C102",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619C103",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D304",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D305",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D306",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D308",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D310",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D311",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D312",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D313",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "619D500",
     "precio_lista": 84706,
     "precio_publico": 160000
    },
    {
     "marca": "ESPRIT",
     "ref": "619D501",
     "precio_lista": 84706,
     "precio_publico": 160000
    },
    {
     "marca": "ESPRIT",
     "ref": "621D100",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D102",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D100",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D102",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D103",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D104",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D111",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "622D112",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E100",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D100",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D103",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D106",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C102",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "631C103",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "639C100",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "639D500",
     "precio_lista": 100588,
     "precio_publico": 190000
    },
    {
     "marca": "ESPRIT",
     "ref": "651C100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "651C101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C101",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C103",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C104",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C105",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C106",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C107",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C108",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C109",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C111",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C112",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C113",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "661C115",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D101",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E103",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E109",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E112",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E113",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E115",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E300",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "661E301",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662B100",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C103",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C104",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C105",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C106",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D100",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D101",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D102",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D103",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D104",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D105",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D111",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D114",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D115",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D118",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E103",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E107",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E108",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E109",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "663A100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "663A102",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "663A103",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "663B100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "663B101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "663B102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "669A100",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "669A102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "669A103",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "669A105",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B101",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C100",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C102",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C103",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C104",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C105",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C106",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "669C109",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D103",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D107",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D109",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D110",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D111",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D113",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D120",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D124",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D125",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D300",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B101",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B110",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "671B111",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "671C100",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "671C101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "671E111",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B101",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B110",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B111",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "672B502",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D100",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D101",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D102",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D103",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D104",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D105",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "672D111",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "673A100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "679C111",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "691C100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018301",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C100",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "901C101",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D100",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D101",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901D102",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E100",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E101",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "901E102",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B100",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B101",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B102",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902B103",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C100",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "902C101",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D101",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902D102",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "902E101",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9036900",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "9036901",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038101",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038108",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038109",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038901",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B100",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B101",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B102",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B103",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B104",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C100",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909C101",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D100",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D101",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D102",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "909D103",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138102",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138108",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138109",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138115",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B105",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9198100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "919D101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "922D100",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "961C100",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "961C101",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9628100",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "962B100",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "962B101",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "962D100",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9638101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9638103",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9638105",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "969C100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "969C101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "969D100",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "9717500",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9718100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A500",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A502",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971A503",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B100",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B101",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B500",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B501",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B502",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "971B503",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9726500",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9726503",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9727101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9728101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9728500",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A501",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A502",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B102",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B500",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B501",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B502",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D100",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "972D101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9736500",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9736501",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738100",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738500",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9738502",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A500",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A502",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973A503",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B102",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B400",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B401",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B500",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B501",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "973B503",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9795100",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9796101",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9797100",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798502",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798503",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798510",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798511",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A102",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A500",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A501",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A504",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "979D100",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "981C100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "1000710",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "1012700",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "1021710",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "1023705",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100700",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "1100701",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "1124702",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "1234701",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "1294700",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "129E202",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "129E203",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "1600701",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "161B101",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "169E105",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "169E106",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "169E107",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "169E108",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "169E109",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "169E110",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "169E200",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "2000100",
     "precio_lista": 529,
     "precio_publico": 1000
    },
    {
     "marca": "ESPRIT",
     "ref": "2000101",
     "precio_lista": 529,
     "precio_publico": 1000
    },
    {
     "marca": "ESPRIT",
     "ref": "2000102",
     "precio_lista": 3176,
     "precio_publico": 6000
    },
    {
     "marca": "ESPRIT",
     "ref": "2018601",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2018605",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "2018608",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2018611",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A604",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B102",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B105",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B107",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B108",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2021401",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "2021600",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "ESPRIT",
     "ref": "202A600",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2031305",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2031312",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2031601",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "2031602",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "2031901",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "2038901",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A602",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A603",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "2091603",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2096604",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "209A100",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "209A601",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2116607",
     "precio_lista": 32532,
     "precio_publico": 68900
    },
    {
     "marca": "ESPRIT",
     "ref": "2121601",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2131303",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "2131305",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2131603",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2131605",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2131609",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2190603",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2191407",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2218600",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2291666",
     "precio_lista": 8092,
     "precio_publico": 14900
    },
    {
     "marca": "ESPRIT",
     "ref": "2312600",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2397602",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400732",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400744",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400767",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400771",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2400775",
     "precio_lista": 35247,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2408703",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2408704",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "240A702",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "ESPRIT",
     "ref": "2501737",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2503710",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2504702",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2504703",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "2505700",
     "precio_lista": 72178,
     "precio_publico": 132900
    },
    {
     "marca": "ESPRIT",
     "ref": "2505706",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506709",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506714",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506717",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "2632699",
     "precio_lista": 91975,
     "precio_publico": 169350
    },
    {
     "marca": "ESPRIT",
     "ref": "2700603",
     "precio_lista": 172164,
     "precio_publico": 317000
    },
    {
     "marca": "ESPRIT",
     "ref": "2700710",
     "precio_lista": 136319,
     "precio_publico": 251000
    },
    {
     "marca": "ESPRIT",
     "ref": "282D101",
     "precio_lista": 132353,
     "precio_publico": 250000
    },
    {
     "marca": "ESPRIT",
     "ref": "282D102",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "282D103",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "282D105",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "282D106",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "ESPRIT",
     "ref": "282D110",
     "precio_lista": 18476,
     "precio_publico": 34900
    },
    {
     "marca": "ESPRIT",
     "ref": "282D111",
     "precio_lista": 81900,
     "precio_publico": 154700
    },
    {
     "marca": "ESPRIT",
     "ref": "282E100",
     "precio_lista": 59505,
     "precio_publico": 59505
    },
    {
     "marca": "ESPRIT",
     "ref": "2912403",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "2917201",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "2918600",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2918601",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2918906",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B901",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "2931605",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2937610",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2937900",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2991400",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "2997904",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "299A900",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "299A902",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "299A903",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "3000700",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "3000711",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "3011703",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B703",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "3021700",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "3021701",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "3021704",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "302B701",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E306",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E307",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E308",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E309",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E310",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E311",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E312",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E313",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E314",
     "precio_lista": 82535,
     "precio_publico": 155900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E315",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "305E316",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "309C755",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "3112703",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3112707",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "3112709",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3112712",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "3112719",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3112720",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3112728",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "3112733",
     "precio_lista": 67834,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "3121707",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "3121708",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3125704",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "3125712",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "3130708",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "3131707",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3137705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "3190706",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3190710",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3190716",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3190720",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "3190721",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "319C754",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "3225700",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "3235703",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "325E302",
     "precio_lista": 143418,
     "precio_publico": 270900
    },
    {
     "marca": "ESPRIT",
     "ref": "325E303",
     "precio_lista": 146065,
     "precio_publico": 275900
    },
    {
     "marca": "ESPRIT",
     "ref": "325E304",
     "precio_lista": 203771,
     "precio_publico": 384900
    },
    {
     "marca": "ESPRIT",
     "ref": "3297700",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "329C753",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "329C754",
     "precio_lista": 423477,
     "precio_publico": 799900
    },
    {
     "marca": "ESPRIT",
     "ref": "329D300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "329D303",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "3312703",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "3318702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "331B703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "3320702",
     "precio_lista": 91784,
     "precio_publico": 119000
    },
    {
     "marca": "ESPRIT",
     "ref": "3327700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "3330700",
     "precio_lista": 80922,
     "precio_publico": 149000
    },
    {
     "marca": "ESPRIT",
     "ref": "335E302",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "335E303",
     "precio_lista": 128594,
     "precio_publico": 242900
    },
    {
     "marca": "ESPRIT",
     "ref": "3390702",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "3390704",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "3390707",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "339D300",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "339D301",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E303",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E304",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "345E305",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "3530702",
     "precio_lista": 70060,
     "precio_publico": 129000
    },
    {
     "marca": "ESPRIT",
     "ref": "3590706",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "359A703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "361D701",
     "precio_lista": 132353,
     "precio_publico": 250000
    },
    {
     "marca": "ESPRIT",
     "ref": "369C752",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "369C753",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "369C754",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "369C755",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "369C756",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "375E300",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "375E301",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "375E302",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "375E303",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "3790702",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "3798103",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "385E301",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "385E302",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "389C751",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "389C752",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "ESPRIT",
     "ref": "4010709",
     "precio_lista": 53713,
     "precio_publico": 70000
    },
    {
     "marca": "ESPRIT",
     "ref": "4011704",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4011711",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4011712",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4012701",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4012703",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4012704",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4012706",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "4012710",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "4012712",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "4012713",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4012714",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "4013703",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4017714",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4017721",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4018204",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4027701",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "402A704",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "402E700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4031703",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "4031704",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4031708",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4031709",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4037704",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4038700",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4038711",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4091707",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4091718",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "4091719",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "4091721",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4095718",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4096717",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4097700",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098704",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098706",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A701",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A704",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "409A706",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4111702",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4112702",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4112704",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4112705",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4112708",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4112711",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4117701",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4117703",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4117708",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4117715",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4118707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D707",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "411D708",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4120714",
     "precio_lista": 64629,
     "precio_publico": 84000
    },
    {
     "marca": "ESPRIT",
     "ref": "4121712",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4121721",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4123711",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4125709",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4127710",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128706",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128709",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "412B700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "412D711",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "412E700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "4131702",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4131703",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4131704",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4131705",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4131706",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4131715",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4134706",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4135707",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4136701",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4137714",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4138704",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A709",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "413A710",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4190714",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4190715",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4191706",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4191712",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4191717",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4191721",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "4191724",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4192718",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4196701",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "4196703",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4196709",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4196710",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4196723",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4197716",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198710",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198711",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198714",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A707",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "419A708",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B201",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B703",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D710",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D711",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "421D714",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "4227709",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "422A700",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B700",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B702",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B706",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B707",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B708",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B720",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "422D701",
     "precio_lista": 688182,
     "precio_publico": 1299900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E700",
     "precio_lista": 423476,
     "precio_publico": 799900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E706",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E707",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "422E708",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "4238710",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "4298706",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A204",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "429A704",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C704",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C802",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C803",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C806",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "429C807",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E107",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E114",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E200",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E201",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E203",
     "precio_lista": 248770,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E205",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E206",
     "precio_lista": 264652,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E210",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E212",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E215",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E216",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E217",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E222",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "4312701",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4317701",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4318703",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "431D707",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "4320709",
     "precio_lista": 97216,
     "precio_publico": 126000
    },
    {
     "marca": "ESPRIT",
     "ref": "432A702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E700",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "432E701",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "4330706",
     "precio_lista": 102647,
     "precio_publico": 189000
    },
    {
     "marca": "ESPRIT",
     "ref": "4331702",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4331713",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4338714",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4391706",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4391716",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4391724",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4398700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4418700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "441B200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "449A700",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "4612700",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4615700",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "4617200",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4617201",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "461A703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "461B702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4621720",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4622700",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4627700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4627702",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4628706",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "462A102",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "462C750",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "462D121",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "4630700",
     "precio_lista": 86353,
     "precio_publico": 159000
    },
    {
     "marca": "ESPRIT",
     "ref": "463A101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "463A104",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "463A702",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4697708",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "4698702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "469A104",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "469A105",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "469A705",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E102",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E106",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E203",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E204",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E212",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "4721720",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4912700",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4912701",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4912706",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4917200",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4917701",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "491B704",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "4920704",
     "precio_lista": 80922,
     "precio_publico": 105000
    },
    {
     "marca": "ESPRIT",
     "ref": "4937701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4937706",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "4937712",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "4990710",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "4991713",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "4991721",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "4997701",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "5190703",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "5310702",
     "precio_lista": 91784,
     "precio_publico": 169000
    },
    {
     "marca": "ESPRIT",
     "ref": "5320711",
     "precio_lista": 97216,
     "precio_publico": 179000
    },
    {
     "marca": "ESPRIT",
     "ref": "6012700",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6012701",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6012704",
     "precio_lista": 34161,
     "precio_publico": 62900
    },
    {
     "marca": "ESPRIT",
     "ref": "6012706",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "6012707",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "6012720",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "6017700",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6017702",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6017707",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6018202",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "601A705",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "602A705",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "602D600",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "6031704",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "6031718",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "6032702",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "6037701",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6038706",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A704",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E300",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E301",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E302",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E303",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E304",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E305",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E306",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E307",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E308",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E309",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "605E310",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "ESPRIT",
     "ref": "6090711",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "6091717",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "6091719",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "6096700",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098703",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A700",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "609A702",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6111706",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112700",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112701",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112703",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112704",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112705",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112706",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112707",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112708",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112709",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112710",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6112712",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117200",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117703",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117708",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117714",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117715",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117726",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6117727",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6118705",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A701",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A705",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A715",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A716",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B706",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "611B712",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "611D717",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6120702",
     "precio_lista": 64629,
     "precio_publico": 84000
    },
    {
     "marca": "ESPRIT",
     "ref": "6125709",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6125710",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6127705",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612A712",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B704",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "612B706",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C750",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "612C752",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6130701",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6131707",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6131716",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6131720",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6131721",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6135703",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6135705",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6135712",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6136702",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6136714",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137705",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6137716",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138201",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138708",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138713",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138716",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6138721",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "613A710",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6190730",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6190736",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6190751",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6191713",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6191714",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6191724",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6191725",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "6191730",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6191731",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6196701",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6196705",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6196710",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6196711",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6197714",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6197716",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198709",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198719",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A708",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "619A713",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6217205",
     "precio_lista": 157446,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "6217700",
     "precio_lista": 244342,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "6217707",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "621A203",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "621A204",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B701",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B705",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B706",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B707",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B708",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D701",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D725",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D727",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "621D734",
     "precio_lista": 317065,
     "precio_publico": 598900
    },
    {
     "marca": "ESPRIT",
     "ref": "6227201",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "6227701",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "6227711",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "6228708",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A705",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A710",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A712",
     "precio_lista": 217006,
     "precio_publico": 409900
    },
    {
     "marca": "ESPRIT",
     "ref": "622A713",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B712",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "ESPRIT",
     "ref": "622E715",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "ESPRIT",
     "ref": "6231703",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6231706",
     "precio_lista": 152015,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "6231723",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6237201",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "6237704",
     "precio_lista": 397006,
     "precio_publico": 749900
    },
    {
     "marca": "ESPRIT",
     "ref": "6237705",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "6237706",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "ESPRIT",
     "ref": "6237712",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "6238201",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A205",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "623A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "625E300",
     "precio_lista": 152947,
     "precio_publico": 288900
    },
    {
     "marca": "ESPRIT",
     "ref": "625E301",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "6292720",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6298706",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A200",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A203",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "629A210",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C721",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C800",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C802",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C804",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C805",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C806",
     "precio_lista": 217006,
     "precio_publico": 409900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C807",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C808",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C809",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C810",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "ESPRIT",
     "ref": "629C812",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D303",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D306",
     "precio_lista": 187888,
     "precio_publico": 354900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E202",
     "precio_lista": 264652,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E203",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E204",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E206",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "ESPRIT",
     "ref": "629E220",
     "precio_lista": 211711,
     "precio_publico": 399900
    },
    {
     "marca": "ESPRIT",
     "ref": "6311701",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6311703",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6312700",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "6312704",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6312710",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6312711",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6330708",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6330715",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6331717",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "6333707",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6335701",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6336704",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6390722",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6394713",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6395705",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "639A700",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6431700",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6490708",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "659A702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6612707",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6617201",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6617707",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "661A101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "661A702",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "661A703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "661A704",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "661A706",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "661B201",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "661B701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "661B704",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D723",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "661D729",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "662A702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662A705",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "662B703",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662B704",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "662B706",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "662B718",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C751",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C752",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C753",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C754",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C755",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "662C756",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "ESPRIT",
     "ref": "662D113",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "662E703",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "6632701",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6637710",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "663A702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "663A706",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6691717",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6691718",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6691731",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6696201",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6696704",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "6698706",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "669A101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "669B102",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "669D115",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E100",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E101",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E105",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E109",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E110",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E112",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E113",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E208",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E210",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E211",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E213",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E218",
     "precio_lista": 121711,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E221",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "6711701",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6712701",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6717707",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6721703",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6731708",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6731710",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6736707",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6737700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "673A703",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "6790708",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6790716",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "6798703",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6816701",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "691A201",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "691B202",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6928700",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6931703",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "6938702",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "6991709",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6996706",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "6996707",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "699A704",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "ESPRIT",
     "ref": "699E215",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "8037204",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "8037206",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "8337200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "8337201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "8337203",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "8337205",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "8337207",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "8396258",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9017701",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "9017702",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B702",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A200",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "902A700",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9037202",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9038107",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "903B200",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "9097305",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "911A302",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "911B200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9127200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9127203",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "912B700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137204",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137207",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9137211",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138104",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138201",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9138701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "913B100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9196200",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "919A209",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "9227202",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "9236205",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "9237205",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "9237210",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9238200",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "9296700",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "929C802",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "9317700",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9391700",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "949A200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9596700",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9598700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9617700",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "961B201",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "961B204",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9628101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9628202",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9638102",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9695203",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9696203",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9698102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9698103",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9698104",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9713508",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9714502",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9715506",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9721502",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9722503",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9723502",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9727700",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9727701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A301",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972A704",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9730500",
     "precio_lista": 67834,
     "precio_publico": 124900
    },
    {
     "marca": "ESPRIT",
     "ref": "9730504",
     "precio_lista": 97216,
     "precio_publico": 179000
    },
    {
     "marca": "ESPRIT",
     "ref": "9730509",
     "precio_lista": 86353,
     "precio_publico": 159000
    },
    {
     "marca": "ESPRIT",
     "ref": "9731500",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9735100",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9735103",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "9735104",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9735300",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9735308",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9737300",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "9737903",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9790504",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9790700",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9791514",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9792500",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798303",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798500",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798512",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9798700",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A503",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "992B201",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "9990711",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "999A200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A600",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "201A606",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "202A602",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "202A604",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "202A605",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "ESPRIT",
     "ref": "2098601",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "2098602",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2098604",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "222A601",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "222A701",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "2503714",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "2505701",
     "precio_lista": 72178,
     "precio_publico": 132900
    },
    {
     "marca": "ESPRIT",
     "ref": "2505711",
     "precio_lista": 72178,
     "precio_publico": 132900
    },
    {
     "marca": "ESPRIT",
     "ref": "2506708",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "ESPRIT",
     "ref": "291A903",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "ESPRIT",
     "ref": "291B605",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B606",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "292B900",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "2938608",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "301B716",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "313A713",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "321B703",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "ESPRIT",
     "ref": "322B700",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "331B702",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "333A702",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "351B701",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "359A701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "402B720",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098700",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "4098703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "411A702",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4128712",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "4198712",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "421B205",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "421C700",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "422B709",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "4298708",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "4298717",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "ESPRIT",
     "ref": "429B704",
     "precio_lista": 475412,
     "precio_publico": 898000
    },
    {
     "marca": "ESPRIT",
     "ref": "4498701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "461A702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "461B201",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "4698703",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "4698707",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A201",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "ESPRIT",
     "ref": "491A702",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "602B702",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6098701",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "611A708",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "613B700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198700",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198722",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198723",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6198724",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B200",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "ESPRIT",
     "ref": "621B709",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "ESPRIT",
     "ref": "6228700",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "ESPRIT",
     "ref": "622B708",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "ESPRIT",
     "ref": "623B706",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "ESPRIT",
     "ref": "6398706",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "6598703",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "661A705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6638706",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "6698705",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "6798701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "691A701",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "6938703",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "6998701",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "6998703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "ESPRIT",
     "ref": "9018701",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B700",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "901B704",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9028700",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "9098201",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "9128700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "913A700",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "922B203",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "ESPRIT",
     "ref": "9298703",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "941B700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9498700",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "9618701",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "ESPRIT",
     "ref": "961A201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "961A203",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "961B700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "961B701",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9628200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "962B204",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "969A206",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "979A700",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "9818702",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "982A701",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998700",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998701",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "1698100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "171A103",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "171A104",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "171A105",
     "precio_lista": 86241,
     "precio_publico": 162900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B112",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "201B114",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "203A005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "2098001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "2098004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "209A104",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "263A001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "ESPRIT",
     "ref": "292A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "2932901",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "ESPRIT",
     "ref": "302B100",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "ESPRIT",
     "ref": "302B103",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "ESPRIT",
     "ref": "302C101",
     "precio_lista": 60000,
     "precio_publico": 113333
    },
    {
     "marca": "ESPRIT",
     "ref": "329D500",
     "precio_lista": 137647,
     "precio_publico": 260000
    },
    {
     "marca": "ESPRIT",
     "ref": "381A100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "ESPRIT",
     "ref": "461A100",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "ESPRIT",
     "ref": "603A100",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "ESPRIT",
     "ref": "629D500",
     "precio_lista": 132353,
     "precio_publico": 250000
    },
    {
     "marca": "ESPRIT",
     "ref": "9038102",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "ESPRIT",
     "ref": "962A201",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9698100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "9698101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B303",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "ESPRIT",
     "ref": "972B503",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "ESPRIT",
     "ref": "9898100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "ESPRIT",
     "ref": "9998101",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F200",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "ESPRIT",
     "ref": "421F203",
     "precio_lista": 248770,
     "precio_publico": 469900
    },
    {
     "marca": "ESPRIT",
     "ref": "449E200",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E215",
     "precio_lista": 158770,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E216",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E218",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E219",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E214",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E217",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E219",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "669E220",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E300",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "315E301",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "ESPRIT",
     "ref": "429E108",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "ESPRIT",
     "ref": "469E100",
     "precio_lista": 111123,
     "precio_publico": 209900
    },
    {
     "marca": "ESPRIT",
     "ref": "632D301",
     "precio_lista": 104771,
     "precio_publico": 197900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3117004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3118002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3118003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3118004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311B002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3127002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3138000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3138002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3138003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "313A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "318A000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3197000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3197004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3208000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3217005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321B003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3227002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3228000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3228001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3228002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322A001",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "323A003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3297000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3297003",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3297006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3298003",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3298005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3298010",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329A007",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3305300",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3305401",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308500",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308502",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308503",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308601",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308602",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308603",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317107",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317110",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318300",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318503",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318505",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318906",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318908",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318917",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A501",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A900",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B300",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B301",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B400",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B606",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B902",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B903",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B905",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3325104",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3326100",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3326503",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327401",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327505",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328101",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328103",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328300",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328504",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328509",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328510",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328902",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328912",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A300",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A400",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A503",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A601",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A602",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A608",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A902",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A905",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B101",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B501",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B503",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B601",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B904",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3335501",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336105",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336506",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337500",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337506",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337908",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338400",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338501",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338502",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338608",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A301",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A604",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A902",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A903",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3394000",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396009",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396107",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396500",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396505",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396507",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397100",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397104",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397107",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397912",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397916",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397917",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398400",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398604",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A100",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A400",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A507",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A600",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A608",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A904",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A907",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A910",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3438001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "343A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3498001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3508000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3508001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "353A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "353A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3596001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3596002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3597001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3617001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "361A001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "361B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3627000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362A001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362B001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3636000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3637000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3696002",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369A002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3717000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3717002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3727002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3728000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3738000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3738001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "373A002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3798000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3798002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "379A000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "379A001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "379A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3938000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3998001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5118003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5118005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5127000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5127001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5128002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5128003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5136003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5137003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5138001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5196001",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5196002",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5197001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5198000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519A003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5205001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5208000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5217002",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521A001",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521A004",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521B000",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521B001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521B002",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521B004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522A001",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522A002",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522B002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5236000",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5238002",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5238004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "523A002",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "523A005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "523A006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5297002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5297005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298001",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298004",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298009",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298010",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A010",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5305100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5305101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5307100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308300",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308301",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308702",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317106",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317301",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317400",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318104",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318105",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318503",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318505",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318509",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318510",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318511",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318700",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318703",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318800",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318906",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A503",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A506",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B103",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B104",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B105",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B106",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B502",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B506",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B603",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B706",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B707",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B801",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B802",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B803",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B907",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5326903",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327100",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327400",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327506",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328501",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328701",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328706",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328801",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328901",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328902",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328907",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A602",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A700",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A707",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A801",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A901",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A904",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B101",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B102",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B105",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B106",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B506",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B801",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B904",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B906",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B907",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337504",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338100",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338101",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338106",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338300",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338702",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338704",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338901",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338911",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A100",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A102",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A104",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A602",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A800",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A905",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A907",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A909",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386171",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386251",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386313",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386314",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386353",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386354",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386911",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386912",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5387113",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538A100",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538A101",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538A500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538A600",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538A700",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394900",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396103",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396110",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396504",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396509",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397110",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397301",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397508",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397700",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397800",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397902",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397903",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397907",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398101",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398102",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398106",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398109",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398110",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398301",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398501",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398511",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398603",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398706",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398708",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398709",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398710",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398712",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398803",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398902",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398907",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398909",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398910",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398921",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A101",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A104",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A105",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A302",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A701",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A910",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A916",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5508000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5598000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6008000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6008500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600A000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6015012",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016007",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017017",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018011",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A007",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A009",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A011",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B007",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B009",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B011",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B018",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6025001",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026009",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027015",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A009",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A015",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B005",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B012",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B017",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B018",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6032024",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6035001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036009",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037007",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3108000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3108001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3116002",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3117000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3118001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3127003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3127005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3128000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3128002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3128003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "313A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3198004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3205000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3208001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3217004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3218001",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3218004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321A001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321A002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321A004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321A006",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321B000",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321B004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3226001",
     "precio_lista": 141153,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3228003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3228004",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322A000",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322A002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322A006",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B004",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3237002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3238000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3238001",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3238003",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "323A001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "323A004",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3297005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3298001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329A001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329A002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329A004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329A006",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329A008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3304100",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3307000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308600",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316101",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316103",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317104",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317503",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317900",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317902",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318501",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318900",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318903",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318909",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318912",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A300",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A301",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A504",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A505",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A905",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A906",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B101",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3326000",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3326500",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327101",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327301",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327500",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327502",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327900",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327902",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327903",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328503",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328505",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328506",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328904",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328905",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328908",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328911",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A101",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A604",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A606",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B301",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B400",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B500",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B604",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B605",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B900",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B905",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3335000",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3335006",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336500",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336901",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337100",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337101",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337401",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337501",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337508",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337903",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337906",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338100",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338605",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338606",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338902",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338905",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A100",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A501",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A601",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A603",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A904",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A907",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A911",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A912",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396003",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396005",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396007",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396100",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396103",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396106",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396503",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396508",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396900",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396901",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396902",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397009",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397503",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397900",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397901",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397904",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397907",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398101",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398300",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398506",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398507",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398509",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398514",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398605",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398607",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398608",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398609",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398611",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398613",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398907",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398909",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A300",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A301",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A501",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A504",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A602",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A606",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3426000",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3437000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "343A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "343A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3496000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3498003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349A005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3538000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3597003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "361A000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3627001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362B002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3638001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "363A001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3695002",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3697002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3697004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3698001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369A000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369A001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3726000",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3727000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3736000",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3797004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3798003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3905000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3998000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5117000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5117001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5118002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5137000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5138000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "518A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5197003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5198001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5198002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5201111",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5203001",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5218001",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5218003",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522A003",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5238001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "523A000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "523A001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5296002",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5297004",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5305300",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5307101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5307700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5307701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308500",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308800",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317101",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317504",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317903",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318300",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318504",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318801",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318900",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318902",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A504",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A603",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A604",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A701",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A908",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B101",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B107",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B108",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B300",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B500",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B507",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B602",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B604",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B704",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B705",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B804",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327300",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327700",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327903",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328101",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328102",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328105",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328106",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328301",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328500",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328705",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328904",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A103",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A104",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A503",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A505",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A601",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A604",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A704",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A800",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B703",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B704",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B909",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5335702",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337105",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337505",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337903",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337904",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338501",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338505",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338700",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338703",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338705",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338800",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338803",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338900",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338908",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A101",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A103",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A502",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A503",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A801",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A901",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A902",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386151",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386212",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386253",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386310",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386930",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386955",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538A300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538A501",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538A701",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538A800",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396112",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396704",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396904",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397104",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397108",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397501",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397503",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397504",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397506",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397507",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397707",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398100",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398104",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398105",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398507",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398510",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398513",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398600",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398605",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398713",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398802",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398804",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398904",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398911",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398917",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398918",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398924",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A500",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A502",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A505",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A604",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A605",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A706",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A801",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A803",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A900",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A903",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A904",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A914",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A918",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A919",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5508001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5517000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5528000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5528001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5538000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5597000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5597001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6008003",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6008501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6008502",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6013814",
     "precio_lista": 35791,
     "precio_publico": 65900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A013",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A016",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A020",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6025000",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026002",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026003",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026019",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026020",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027020",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027021",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028004",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A014",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A017",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A020",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6033007",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6035016",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036005",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036013",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037015",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037017",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037018",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037020",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3126002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3127000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3136001",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3138001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3185002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3197003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3198002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321A005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3227003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3228005",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3228006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3228007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322A005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B003",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3236001",
     "precio_lista": 141153,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3238005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "323A002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3296001",
     "precio_lista": 141153,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3297002",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3298000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3298002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3298008",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3305100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3305101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3305500",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308300",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308301",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308400",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3314008",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316003",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317100",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317102",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317103",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317105",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317108",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317400",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317507",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318508",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318905",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318911",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A302",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A400",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A502",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A901",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A902",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A903",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A907",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B602",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B604",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B605",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B906",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3323901",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327100",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327105",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327503",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327504",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327901",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328105",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328301",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328400",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328502",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328907",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328909",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328910",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A301",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A401",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A605",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A900",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A901",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A904",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B502",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B903",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B906",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3335004",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336103",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336300",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336503",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336504",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337102",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337502",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337505",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337907",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338101",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338609",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A503",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A504",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A602",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A905",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A909",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A910",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395301",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396001",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396008",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396010",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396102",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396104",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396105",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396509",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397102",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397301",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397400",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397502",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397507",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397511",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397905",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398106",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398503",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398508",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398908",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398911",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398913",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A302",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A502",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A506",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A601",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A604",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A607",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A609",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A901",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A902",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3408000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3418000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3428000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3496001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3497000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3497002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349A004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3518000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3518001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3527000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3538002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3597004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3598001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3598002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362B000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362B003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3696000",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3696001",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3696003",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3698000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3698002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3716000",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3717001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371A001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3727001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372A000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372B001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3796002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3797002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3798001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5108000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511A000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5136000",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5137002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5138002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "513A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "513A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5185001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5197005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5197007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5198004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519A005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5205000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5208001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5218002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521A000",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521A002",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521B005",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5228000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5228001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5228002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "523A003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5297001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5297003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298002",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298008",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A009",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5301212",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5305301",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5305501",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5307501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308700",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308703",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308802",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316105",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316503",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317105",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317500",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317502",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317503",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317702",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317704",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318106",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318705",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318706",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A100",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A105",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A505",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A700",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A704",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A707",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A800",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B100",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B109",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B302",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B303",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B800",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B904",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B905",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5326700",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327104",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327501",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328300",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328504",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328505",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328702",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328704",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328707",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328906",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A300",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A701",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A702",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A900",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A906",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A907",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B501",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B502",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B504",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B802",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B905",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5335701",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337702",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337704",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337802",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337900",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338103",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338801",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338904",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338905",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338907",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338909",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A604",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A605",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A903",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386210",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386250",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386272",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386312",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386315",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386370",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386910",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386950",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5387123",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538A102",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5393500",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396108",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396706",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397100",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397101",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397105",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397106",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397300",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397704",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397802",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397803",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397804",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397901",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398103",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398107",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398112",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398300",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398504",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398505",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398506",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398512",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398602",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398604",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398702",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398704",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398705",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398707",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398711",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398913",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398923",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A102",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A103",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A108",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A504",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A602",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A703",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A704",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A802",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A905",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A906",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A908",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A909",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A911",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A912",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A913",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5518001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "553A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5598001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5598002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600A500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6014002",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016002",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016008",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016011",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016012",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016017",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016018",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017023",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018018",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018021",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018022",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A010",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A017",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B017",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026000",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026007",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026011",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026017",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028009",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028020",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028030",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A022",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B008",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036004",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036006",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036017",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036019",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311A002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3127004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312B001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3135000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3137001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3137002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "313A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3194004",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3196000",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3196005",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3197001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3197005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3197006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3198000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3198001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3202000",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3205001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321A000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321A003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321A007",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321B001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321B002",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322A004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322A007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322A008",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3237004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3238002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3238004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "323A000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3297001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3297004",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3298009",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329A000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329A003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329A009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3305301",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3305400",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3305501",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308401",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316505",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316900",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317101",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317505",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317506",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318103",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318104",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318301",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318506",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318507",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3318910",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A101",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A600",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A602",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A908",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331A909",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B501",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B900",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B904",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331B907",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3326003",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327904",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328100",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328102",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328302",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328508",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328600",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3328900",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A500",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A502",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A607",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A903",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A906",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B100",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B300",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B401",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B602",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336102",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336106",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336900",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336902",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337103",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337105",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337300",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337504",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338300",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338301",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338604",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338607",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3338906",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A101",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A400",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "333A908",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396002",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396101",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396400",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396501",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396510",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397103",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397105",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397106",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397108",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397300",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397504",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397505",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397508",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397509",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397512",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397906",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397910",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397911",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398100",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398103",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398302",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398504",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398512",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398515",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398610",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398612",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398901",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398906",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3398912",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A401",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A505",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A605",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A900",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A905",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A906",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A909",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A911",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339A912",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3408001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3438000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3498004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3498005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3517001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3517002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3528000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3528001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352B002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3537000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3538001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3596000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3596003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3597000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3598000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "361B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3628000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362A000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362A002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3638000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "363A000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "363A002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371B003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3726002",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3728001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372A002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3736002",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "373A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "373A001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3798004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3917001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "399A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5117002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5118001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511B000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511B002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5127002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5127003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5127004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5128000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5128001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5138003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "513A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5196004",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5197000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5197004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5197006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5198003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5198005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519A002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519A004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5218000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521A003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521A005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522A007",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522B001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5237002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "523A004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5297000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A005",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529A011",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5301211",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5301311",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5305500",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5307500",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308302",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308701",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308801",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316801",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317102",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317300",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317506",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317701",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318100",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318102",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318103",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318507",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318704",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318903",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318905",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A101",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A102",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A103",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A104",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A106",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A300",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A600",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A602",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A702",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A705",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A706",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A801",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A802",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531A902",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B102",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B301",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B504",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B505",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038016",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A009",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A021",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095000",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096002",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096006",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096018",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096024",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096030",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096031",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096043",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097015",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097031",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097040",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098007",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098032",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B700",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B703",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B906",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531B908",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5325501",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327301",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327500",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327504",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327701",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5327900",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328100",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328103",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328104",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328107",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328503",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328800",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328900",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A100",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A101",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A705",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A706",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A902",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B100",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B103",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B104",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B300",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B503",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B505",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B602",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B604",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B701",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B800",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B903",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337104",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337501",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337502",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338104",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338105",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338504",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338506",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338802",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5338902",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A603",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A700",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A704",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A705",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A904",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "533A906",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386110",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386213",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386311",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386972",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5387510",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396301",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396513",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038005",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038017",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A007",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A025",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6088303",
     "precio_lista": 21669,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095006",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095010",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095017",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095021",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095030",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096001",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096007",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096009",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096010",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096011",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096015",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096021",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096025",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096028",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096029",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096038",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097014",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097025",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097039",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097041",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098010",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098019",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098029",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098030",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098031",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038006",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038007",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095003",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095016",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096012",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096016",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096019",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096022",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096023",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096026",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096036",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096042",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A015",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A026",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A030",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A031",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A034",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6108000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6113002",
     "precio_lista": 67834,
     "precio_publico": 124900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6114010",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116006",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117024",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118011",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6126011",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128014",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A006",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136005",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136014",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195011",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195015",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195017",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195026",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196004",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196005",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196010",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196013",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196018",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196020",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196021",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197019",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197026",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197031",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197036",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198021",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A016",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A019",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621A002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621A007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621A008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621B002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621B006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621B007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6228006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6238003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298007",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298010",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A007",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A009",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6317000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6318000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6318003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631B006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6323002",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6323009",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6324003",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6326003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6327003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6328000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6328002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632B006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6337003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6337005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6338001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "633A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6392005",
     "precio_lista": 112911,
     "precio_publico": 207900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6394003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6396002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6397001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6397002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6398001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6398006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639A005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639A007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6410002",
     "precio_lista": 48825,
     "precio_publico": 63000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6418000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397103",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397109",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397400",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397505",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397703",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397705",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397708",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397801",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397905",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5397906",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398108",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398111",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398508",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398509",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398601",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398800",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398801",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398905",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398908",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398915",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398920",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5398922",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A100",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A106",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A301",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A606",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A705",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A707",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A800",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A804",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539A907",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5527001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552B001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5538001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5538002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "553A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "553A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5597002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "599A000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6008001",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6008002",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600A001",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600A501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016014",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016022",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018015",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A018",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601B016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6025003",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026005",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026008",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026013",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026014",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027007",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A013",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A028",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A032",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "610A000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116013",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116014",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117013",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B011",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6126007",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6126015",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6126016",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127019",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A003",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B014",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B018",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B022",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136017",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195008",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196000",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196006",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196011",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196014",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196019",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196027",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196032",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196033",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196035",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197030",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197033",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197037",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6217008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6218003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028023",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028025",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028028",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A010",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A023",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B014",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B016",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036000",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036016",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036018",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036020",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037025",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037026",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A018",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "603A019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6092028",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6093001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096008",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096027",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096035",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096039",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096040",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096045",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097007",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097009",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097018",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097030",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098014",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098028",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098033",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A002",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A010",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A025",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A033",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6106001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116008",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116009",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116020",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117019",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B009",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621A005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621B001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6228005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622A004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622A009",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622B001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6235006",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6237007",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6238005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6238007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623A010",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6296010",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297009",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297013",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297016",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A013",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6308000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "630A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631B003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631B005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6326000",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6328006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6333005",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6336000",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6336001",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097021",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097026",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097036",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098016",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098023",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098026",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A017",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A023",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609A027",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "610A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6115001",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116001",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116007",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116021",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117022",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117023",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117025",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6118007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6125004",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6126014",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6338004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6338005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "633A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "633A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "633A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6394005",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6396000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6396007",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6397004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6397009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6398004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6398013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "641A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "641A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6424002",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6428001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127014",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B024",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136004",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136015",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136016",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137012",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137014",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A014",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A015",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195023",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195030",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196007",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196012",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197025",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197028",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197029",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197034",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197038",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6218007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621B005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6227010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6228009",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6234001",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6237005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6238006",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6238008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6238010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297008",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297011",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297015",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298004",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298011",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6508000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6526000",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6528000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "653A000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6598002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6628000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "662B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6717000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6717003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6717005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6717008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6718003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6727007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6728006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6728008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6733009",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6736002",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6736003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6737000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6737005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "673A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6796000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6797001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6797009",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6798002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6798008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611B016",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6126009",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6126013",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612A013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136003",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136007",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137013",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137022",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "613A008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298015",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6308001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6313010",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6315001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6316001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6316002",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6317001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6323007",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6327000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6328004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632B003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6332127",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6333008",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6336003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6337004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6338003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679A005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6808000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "680A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6818006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681A004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681B003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681B004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681B005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6828000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682A008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682B005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6837004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6838006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683A007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683A008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "691B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800A807",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8013016",
     "precio_lista": 39592,
     "precio_publico": 72900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017012",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017019",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018505",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018513",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A504",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026010",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6194017",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195009",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195014",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195018",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196009",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196016",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196023",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196024",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196026",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196028",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196038",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197003",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197014",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197021",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197024",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6197042",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198019",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6198023",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619A015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6216005",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6217006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6218008",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621A003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6228004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6228008",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6228011",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622B003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622B007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6236006",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6237008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6238009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623A011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297012",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298012",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298013",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A012",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "630A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6313013",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6317002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6317003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6318001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6327001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6327002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6328008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632B007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6333000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6336002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6337000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6337001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6337002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6337006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6338002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6338008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "633A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6392010",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6394002",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6395002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6396003",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6396005",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6397000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6397003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6397005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6397006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6398009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6414000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6417000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "641A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "641A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6428000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "642A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "642A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "643A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6496000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "649A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6518000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "651A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6528001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652A002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652B002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6536001",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6537000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6595002",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6596002",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6597000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6597002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "661B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "663A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6696000",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6697000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "669A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "669A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6716007",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6717007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6726004",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6727003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672B004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6733007",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6735002",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6737001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6738003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "673A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6796013",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6796015",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6797004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6797005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6798004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6798009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6818001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681A006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681A007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6828002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6828003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6828004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6828007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682A003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6837001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6837002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6838002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6838004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689A005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689A008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8008807",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017020",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017504",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018507",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A505",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A514",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8025010",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026011",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026506",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027017",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027507",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027512",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027513",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028008",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028503",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028507",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028509",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028511",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A507",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B009",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8035014",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037506",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037509",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038504",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A504",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A507",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095019",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095026",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096008",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096515",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097023",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097513",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098023",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098505",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A504",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A505",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A510",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A513",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A515",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A526",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8108000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8108500",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8116000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8116023",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117027",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118501",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118504",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118507",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118513",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A501",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B500",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B502",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126014",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127016",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127026",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128503",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128511",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A509",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B507",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B509",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8133002",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136004",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136018",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136021",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137030",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138510",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A508",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A511",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A512",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186104",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186108",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186109",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8194004",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196005",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196013",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196016",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196020",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196023",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196039",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196041",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196042",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196043",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197500",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197502",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197511",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197518",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197520",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197523",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198512",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198515",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198517",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6436000",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6494000",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6496001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6497000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6498000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6498005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "649A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6518001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6518002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "651B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6527000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "653A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "659A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "661B001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6713008",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6716001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6717001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6717002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6717006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671A005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671B003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6727000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6727001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6727002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6727005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6728004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6728009",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6736006",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6737004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "673A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6796003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6796009",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6796010",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6797008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028504",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A504",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A509",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A510",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A513",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8034016",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037512",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A506",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8088300",
     "precio_lista": 27100,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095022",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096000",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096025",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096510",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096521",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097011",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097014",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097022",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097504",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097505",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097512",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097515",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097518",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097524",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6338009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "633A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6393007",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6395005",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6395006",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6396004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6396006",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6398003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6398005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6398008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639A002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6418001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098503",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098504",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098515",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "642B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6437000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6438000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6497001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6498003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6515001",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6517000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6528002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6596001",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6596005",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6597001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6598000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "659A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6618000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "662B000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "662B001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6638000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6696001",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6717009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6718002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6728005",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6728007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6799002",
     "precio_lista": 27155,
     "precio_publico": 50000
    },
    {
     "marca": "AMERICANINO",
     "ref": "679A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6808001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "680A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "680A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6817008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6818002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6818003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6827000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682A006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682A009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682B003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6837000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6837006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683A001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6996000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6997000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6998000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8008001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8008002",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017503",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017508",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017510",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018506",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018508",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018511",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018514",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018515",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A506",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A510",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A512",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026003",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026009",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026505",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036013",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036016",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037510",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037513",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038011",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038507",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038508",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038510",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038511",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038512",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A014",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A505",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A510",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A513",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096005",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096022",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096027",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096500",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096505",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097017",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097025",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097026",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097509",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097510",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097511",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097517",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097520",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A516",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "810A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "810A500",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8115022",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117029",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118500",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118508",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118512",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A500",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A505",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A506",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A509",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B503",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126015",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098017",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098508",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098511",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098516",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098517",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098518",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A508",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A525",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8106000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8106001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8115000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8115005",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8116015",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8116019",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118516",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118517",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118518",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B504",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B509",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8125019",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126010",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126016",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126020",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127024",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127027",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127029",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128504",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128508",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128509",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A505",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136010",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136028",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137016",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137028",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138500",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138503",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138508",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A500",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A503",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A504",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A505",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126019",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126023",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126028",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127028",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128505",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128507",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A500",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A502",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A508",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B504",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B508",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136015",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136016",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136027",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136033",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137025",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137027",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138504",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138506",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138509",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672B003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672B005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6736007",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6737002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6738000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6738002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6796011",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6797000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6797002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6797003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6797007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6798001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6798003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6798006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6798007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186114",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8194018",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195025",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196024",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196025",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196028",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197018",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197505",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197512",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197526",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197528",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679A004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "680A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "680A004",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6817000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6818000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6818005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681A005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681B006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681B007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682B004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682B006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682B007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6838003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A510",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186101",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186111",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195018",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196008",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196015",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196017",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196021",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196027",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196038",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196040",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196044",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197506",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197510",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197515",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197517",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197519",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197524",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198508",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198516",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A502",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A503",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A507",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A510",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8218007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A007",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A011",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A016",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8308001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8318004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831B003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8327000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8327001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198503",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198505",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198510",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198511",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198518",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6918000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6937000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7473000",
     "precio_lista": 34400,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8008000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800A808",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017511",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018509",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8018512",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A500",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A507",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A508",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026016",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026508",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028007",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028510",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A505",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A511",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B017",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B018",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037508",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038509",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A503",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A507",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A509",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A511",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A506",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095503",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096503",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097507",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098502",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098506",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098513",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098519",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098521",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A504",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A506",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A509",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8297002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8297005",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8305100",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8306101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8317003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8318000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8328000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8335001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8336000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8327004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8328002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8335000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8335004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8336002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A505",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A508",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A513",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A011",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8237006",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8238001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A011",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A012",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8305000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8306100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8317000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8317001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8317002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8317004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831A002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831B001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8324000",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8326001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8327005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8328003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832A004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832B006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A500",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A503",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A507",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A509",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A514",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A517",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "810A501",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8116011",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117024",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117026",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117028",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118509",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8118511",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A503",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A504",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B505",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B506",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B508",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126007",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126022",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126024",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8337001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8337003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8337005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8338001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "833A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "833A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "833A005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8396002",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8396008",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8397000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8398001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8398002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8398004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8398006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8517000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8518000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8527000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8537000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8538000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8538001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "853A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8596005",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8597000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8336006",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8337002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8337007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8338002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8338004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "833A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "833A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8397004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8398005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8398009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839A001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B007",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8517001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8517002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8538002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "853A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "853A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8594001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8597001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8598005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128502",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128506",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128510",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A503",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A504",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A507",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B500",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137024",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8137026",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138502",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138505",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A502",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A506",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186100",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186106",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186115",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186116",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186117",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8186118",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8187100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196007",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196009",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196018",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196019",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196022",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196030",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196034",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196035",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196036",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196045",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197016",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197503",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197504",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197507",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197516",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197521",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197525",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197527",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198500",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198502",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198504",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198506",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198507",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198509",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A500",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8337004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8338000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "833A002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "833A006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8395002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8395005",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8396000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8396004",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8398007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839A004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8518001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8525003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8528000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8596001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A515",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821B008",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8305101",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8308000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8308002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8318002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831B005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8324002",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8327002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8327003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8328001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832B005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8336003",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8337000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8387000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8396007",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8397003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8397005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8397007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8397008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8398000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8516001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8525001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8527001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8527002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8528001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8537002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8595003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8596002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8598000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8598002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8598006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8808000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8808001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "880A001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8808002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8815004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8817000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8817003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8817005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8826001",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8826008",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8827002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882B004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8838000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8838002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8895004",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8896001",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8897004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8816003",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8818002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881A000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881A002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8825003",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8827000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8827003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8828002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8828006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8898000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889A001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889A002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8817002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8817004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8818000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881B001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8827001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8836001",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8837000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8837001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8837002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8896000",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8897001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8898002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "880A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8817001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881B005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8828000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882B002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882B005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8897005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8898001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8898003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319B052",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319B053",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3217001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B007",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B010",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329B051",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329B052",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329B055",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329B057",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3314301",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316104",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C101",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C301",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C400",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C401",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C503",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C504",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C505",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C601",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C602",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C605",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C606",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C607",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C609",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3325401",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3326104",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3327004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B504",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3334300",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396903",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B552",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B555",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B652",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B950",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3595001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369B052",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "379B051",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511C000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511C001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511C002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512B001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "513B002",
     "precio_lista": 83580,
     "precio_publico": 0
    },
    {
     "marca": "AMERICANINO",
     "ref": "522B009",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529B054",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529B056",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5315800",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C101",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C102",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C103",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C104",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C105",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C106",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C300",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C500",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C501",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C502",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C503",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C504",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C600",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C602",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C603",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C604",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C703",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C704",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C705",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C801",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C802",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5326902",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B801",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396804",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B151",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B160",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B552",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B655",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B751",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B853",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B951",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B953",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B956",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B957",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C007",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C009",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B055",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B060",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B062",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B066",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B068",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B069",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B070",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B071",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B072",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B073",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611C004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611C008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136006",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B059",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B061",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B062",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B063",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B064",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629B055",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6335002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639B051",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639B052",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "663B000",
     "precio_lista": 86509,
     "precio_publico": 0
    },
    {
     "marca": "AMERICANINO",
     "ref": "669B050",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6799000",
     "precio_lista": 27155,
     "precio_publico": 50000
    },
    {
     "marca": "AMERICANINO",
     "ref": "679B050",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679B053",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679B054",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683B005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689B051",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689B055",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742B006",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742B007",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B051",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B055",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B056",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B057",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B058",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B059",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B060",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B061",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B062",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B063",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C500",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C505",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8124023",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8134000",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8194003",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8194011",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8194015",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B050",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B051",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B054",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B557",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B558",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B064",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B065",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B066",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B067",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B068",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B069",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8594002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859B050",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859B052",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881C003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881C004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8895002",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889B051",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3191001",
     "precio_lista": 84127,
     "precio_publico": 154900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321C000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321C001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321C002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321C003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321C004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329B050",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3304101",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3312001",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3312102",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C402",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C608",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C904",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C905",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C906",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3321903",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3330911",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3333503",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3391301",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396300",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3399911",
     "precio_lista": 92273,
     "precio_publico": 119000
    },
    {
     "marca": "AMERICANINO",
     "ref": "341C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "361C000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521C000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521C002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521C004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C100",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C301",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C303",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C601",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C605",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C606",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C702",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C800",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C900",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C901",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C903",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5321400",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5322905",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B100",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B101",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B301",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B700",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B701",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B702",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B703",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B800",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394703",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C010",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C012",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C014",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C015",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604B001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B074",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611C002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611C006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621C005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "651C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "651C002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "661C000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679B052",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "744B000",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8023019",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "804B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094018",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096009",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096519",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B064",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B065",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B066",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B067",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B068",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B069",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B070",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8116001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C502",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C504",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8123031",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8193027",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8194035",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B070",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B071",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8513001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8516000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8595001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889B057",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889B058",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321C005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C501",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521C001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521C003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531C701",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C013",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611C003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611C009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "661C001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881C002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6594001",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6517001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6592003",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6592002",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3517000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3534003",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3594002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3534002",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6581400",
     "precio_lista": 32586,
     "precio_publico": 60000
    },
    {
     "marca": "AMERICANINO",
     "ref": "3581400",
     "precio_lista": 32586,
     "precio_publico": 60000
    },
    {
     "marca": "AMERICANINO",
     "ref": "8534000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5526000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8595000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8581400",
     "precio_lista": 38017,
     "precio_publico": 70000
    },
    {
     "marca": "AMERICANINO",
     "ref": "8536002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7294003",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7233501",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7296008",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7997001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6895001",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8881401",
     "precio_lista": 54310,
     "precio_publico": 100000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6881400",
     "precio_lista": 43448,
     "precio_publico": 80000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6881401",
     "precio_lista": 54310,
     "precio_publico": 100000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6815003",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6892010",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8825007",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8834000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6194018",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195019",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6124009",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195031",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6115005",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6114011",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6194023",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195029",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195007",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6114005",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6125017",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6193000",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3113000",
     "precio_lista": 68377,
     "precio_publico": 125900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3135002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6181401",
     "precio_lista": 38017,
     "precio_publico": 70000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6192007",
     "precio_lista": 59144,
     "precio_publico": 108900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6192022",
     "precio_lista": 62946,
     "precio_publico": 115900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6192006",
     "precio_lista": 62946,
     "precio_publico": 115900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6181402",
     "precio_lista": 43448,
     "precio_publico": 80000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6192016",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6192015",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6192008",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6113007",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195000",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8181401",
     "precio_lista": 43448,
     "precio_publico": 80000
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126031",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8134004",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8134007",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6194024",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195016",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8181402",
     "precio_lista": 48879,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6117006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126029",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8115010",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8125007",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8135018",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8115013",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8134024",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195025",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6124007",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6193024",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6114003",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6114007",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6192003",
     "precio_lista": 59144,
     "precio_publico": 108900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6014017",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095019",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016900",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096003",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6035021",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6094000",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6014024",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095025",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8024005",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8034005",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094003",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6094008",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026015",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6094019",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6035008",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095005",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6035003",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6015014",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096041",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095023",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6032008",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6032014",
     "precio_lista": 39592,
     "precio_publico": 72900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6023026",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095032",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016004",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "314B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "314B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B902",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372B000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "392B000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "514B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "514B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521B003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521B006",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522B000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522B006",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B600",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096037",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B023",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622B006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B016",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036008",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5216000",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6326001",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6335001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096014",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8135010",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195037",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8025011",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8093009",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8015011",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8093011",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8081401",
     "precio_lista": 38017,
     "precio_publico": 70000
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026514",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3321001",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3331903",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3391802",
     "precio_lista": 112911,
     "precio_publico": 207900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6021015",
     "precio_lista": 34704,
     "precio_publico": 63900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6031039",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6091016",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6091039",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6891012",
     "precio_lista": 41222,
     "precio_publico": 75900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7631001",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6094018",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096017",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3535000",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6034004",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6094006",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6223006",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8015014",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016016",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8093001",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8339000",
     "precio_lista": 73265,
     "precio_publico": 134900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6011018",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6014011",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6034001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6094024",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6094025",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026016",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8025024",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6035007",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8995000",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3994002",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6981400",
     "precio_lista": 32586,
     "precio_publico": 60000
    },
    {
     "marca": "AMERICANINO",
     "ref": "8991000",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8294005",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8294008",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6224004",
     "precio_lista": 141153,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5215001",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3294003",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3235000",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8281400",
     "precio_lista": 70603,
     "precio_publico": 130000
    },
    {
     "marca": "AMERICANINO",
     "ref": "8212005",
     "precio_lista": 144411,
     "precio_publico": 265900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6236005",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8217003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7381400",
     "precio_lista": 27155,
     "precio_publico": 50000
    },
    {
     "marca": "AMERICANINO",
     "ref": "7303901",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7314910",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7323910",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3613001",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3694003",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3694000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6692001",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6692002",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6692000",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6492005",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6432001",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6492001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6495001",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7528508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7522000",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3394901",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396502",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3394503",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3315502",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395509",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316503",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395503",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3317508",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3394504",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3315101",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3325101",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395100",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336104",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3393100",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3334100",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3394105",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3394104",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3394301",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3304301",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3326301",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3334301",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3394302",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3334302",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336002",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395004",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395005",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395401",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394404",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5335301",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5302301",
     "precio_lista": 78696,
     "precio_publico": 144900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5393903",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317900",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5381400",
     "precio_lista": 76034,
     "precio_publico": 140000
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337801",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395803",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394102",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8394005",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5336104",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396109",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316100",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5325102",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395103",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395110",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5336100",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5325702",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5336704",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5337703",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394704",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5396505",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394500",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394505",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394507",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5334503",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394504",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5392501",
     "precio_lista": 112911,
     "precio_publico": 207900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5392503",
     "precio_lista": 112911,
     "precio_publico": 207900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3393003",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3392400",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3322700",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3393104",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3302100",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3302101",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3303301",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3332900",
     "precio_lista": 112911,
     "precio_publico": 207900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3381400",
     "precio_lista": 70603,
     "precio_publico": 130000
    },
    {
     "marca": "AMERICANINO",
     "ref": "3392000",
     "precio_lista": 112911,
     "precio_publico": 207900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3392001",
     "precio_lista": 112911,
     "precio_publico": 207900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3393005",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3392003",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3337909",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6325003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6394006",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6332007",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6313007",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6392012",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6314000",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6332001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6381400",
     "precio_lista": 70603,
     "precio_publico": 130000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6392002",
     "precio_lista": 88472,
     "precio_publico": 162900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6332006",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6332005",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8314007",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8394010",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8381400",
     "precio_lista": 76034,
     "precio_publico": 140000
    },
    {
     "marca": "AMERICANINO",
     "ref": "8394011",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8035500",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6093041",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6094022",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026501",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036508",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094021",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036506",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096501",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096502",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036507",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8015032",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016500",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8035504",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094032",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094037",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8035013",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8025012",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8015029",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8034011",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8025008",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8024017",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8093023",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8035000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8033016",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8013022",
     "precio_lista": 41222,
     "precio_publico": 75900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8023012",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8013011",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094008",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094031",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8092032",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036514",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037519",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094039",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096504",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036510",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027508",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8034028",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8035503",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096046",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6794004",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6795005",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3794000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6781400",
     "precio_lista": 32586,
     "precio_publico": 60000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6792008",
     "precio_lista": 73808,
     "precio_publico": 135900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6792001",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6794005",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6792000",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6735006",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B009",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3302500",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C901",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331C903",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3392502",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3392903",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522B008",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5296003",
     "precio_lista": 157446,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5314105",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5323901",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6022001",
     "precio_lista": 41222,
     "precio_publico": 75900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6114019",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6193026",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6794006",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6833008",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6892011",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8024020",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036004",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B071",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8114002",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8315000",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8325003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8325005",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B102",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B103",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B302",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B502",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B602",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B704",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B705",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B706",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B802",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C302",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C400",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C401",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C502",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C504",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C505",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C507",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C602",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C606",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C607",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C904",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C100",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C103",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C503",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C603",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C705",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C102",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C105",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C505",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C506",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C602",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C604",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C607",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C701",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C703",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C707",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C801",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C803",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C804",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C008",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622C003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C500",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "744B001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629C007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "659C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689C005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689C006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C302",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C503",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C508",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C604",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C607",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C702",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C706",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C500",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C607",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C907",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C009",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829C008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "840C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "840C002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "840C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "810C000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "810C500",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C400",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C505",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C506",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C708",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C709",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C801",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C902",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "840C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C023",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689C002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C511",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C303",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C506",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C508",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C509",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C608",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C703",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C704",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C101",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C104",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C108",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C602",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C701",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629C004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C903",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C014",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889C003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C507",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C508",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C509",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C018",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329C005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C402",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629C003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C008",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C013",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C021",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C022",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C503",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C910",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C107",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C609",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C804",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "649C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "669C000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689C004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329C001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329C004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C501",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C606",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C016",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C017",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889C005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329C002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329C003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329C007",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C103",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C604",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C609",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C901",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "379C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C004",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C006",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C106",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C507",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C605",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C610",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C710",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C029",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C016",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "669C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B106",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B107",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B503",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B505",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B710",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B711",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B804",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C008",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C707",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C900",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C012",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C018",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329C000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C505",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C902",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "379C000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C109",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C509",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C711",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329C006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329C008",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369C000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369C001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C803",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C033",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "669C002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889C007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B108",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B304",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B712",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B713",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B805",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629C001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C906",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C911",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529C003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B504",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C018",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C019",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "340C000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "340C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "350C000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "350C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B603",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C905",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C610",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C612",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C110",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C713",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C613",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C612",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C714",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B110",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B111",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B604",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B606",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B717",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B806",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C611",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C403",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C510",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B516",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B517",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B518",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B519",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B520",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B521",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B522",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B523",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C611",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B506",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B507",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B605",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B716",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B119",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B120",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B723",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B810",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C712",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B117",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B514",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B724",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B725",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B118",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B308",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B515",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B726",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B811",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611D004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821D005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321D000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321D007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D502",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D503",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D708",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611D005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611D000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D605",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D607",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D910",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D911",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D912",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D913",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D914",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D915",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351D002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521D008",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D704",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D707",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D802",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D906",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D907",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D908",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D909",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D910",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D511",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D013",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621D004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "641D000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D512",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D506",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D508",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D600",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D605",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D803",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611D010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D007",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D018",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881D010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D908",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611D012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "661D001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D504",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851D005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D909",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511D002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D902",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D905",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611D008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D502",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D901",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321D003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321D004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521D006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521D009",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521D003",
     "precio_lista": 153000,
     "precio_publico": 289000
    },
    {
     "marca": "AMERICANINO",
     "ref": "361D000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521D000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D916",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "830D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322D004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D501",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D503",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D607",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D703",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D704",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D705",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522D004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D503",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D504",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D600",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D602",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D610",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D702",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D704",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D707",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612D005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612D008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D900",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D500",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D707",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D705",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D503",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D605",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D804",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D015",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682D003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682D004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "692D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D504",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D508",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612D000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682D005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D013",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D502",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D602",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D800",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D801",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D803",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D708",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D500",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D506",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D509",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D601",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D700",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D701",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D017",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822D006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D014",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512D002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D608",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D900",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D901",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D902",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D501",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622D000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612D009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D505",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622D005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D505",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D015",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D501",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D605",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612D004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D505",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D915",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D502",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D510",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D511",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D609",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D604",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D603",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D912",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522D000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D600",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D601",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D703",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D904",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600D002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600D004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D909",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D916",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D917",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D905",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522D006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512D001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D902",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D903",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D503",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D601",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D609",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D702",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "810D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "810D500",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "830D001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329D004",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329D005",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D503",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D506",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D600",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D602",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D604",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D605",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D606",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D607",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D700",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D704",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D706",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D900",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D910",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D912",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529D007",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D500",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D501",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D502",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D505",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5295000",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096024",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5317505",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3116000",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3116003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3127001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "314B002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3195000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3195002",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3195003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319B050",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319B051",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3216000",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3295000",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3295001",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3295002",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3297007",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329B053",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329B054",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329B056",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316001",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316002",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316004",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316005",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316106",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316501",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316504",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3316507",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332B606",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3335002",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3335300",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3336100",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3394401",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395000",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395001",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395007",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395008",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395009",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395101",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395102",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395103",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395104",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395105",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395300",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395302",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395400",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395504",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395505",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395506",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395507",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395508",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3395901",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396401",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3396504",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B050",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B051",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B052",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B150",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B350",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B351",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B352",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B450",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B451",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B452",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B550",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B551",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B553",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B554",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B650",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B651",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B653",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B654",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B655",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B656",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B657",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B951",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B952",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B953",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B954",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B955",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B956",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339B957",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349B050",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349B051",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3595000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3595003",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359B050",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359B051",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359B052",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3695000",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3695001",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369B050",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369B051",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3725002",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3795000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3795002",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "379B050",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5116000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5116002",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5126002",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5137001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "514B002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5195000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5195001",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519B050",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519B051",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519B053",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519B054",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5227001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522B003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522B004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529B050",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529B051",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529B052",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529B053",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529B055",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5304401",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316101",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316102",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316103",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316104",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316107",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316500",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316502",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316504",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316505",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316701",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316702",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5316800",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5318508",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5326301",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5326500",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5326504",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5326702",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5326800",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B107",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B108",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B109",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B301",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B507",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B508",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B509",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B705",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B706",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B803",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532B804",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5336107",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394104",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5394301",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395101",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395102",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395104",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395105",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395106",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395107",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395108",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395109",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395501",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395502",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395503",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395505",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395506",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395507",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395508",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395509",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395510",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395700",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395701",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395704",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395705",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395706",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395802",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395804",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395901",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395902",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5395903",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B150",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B152",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B153",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B154",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B155",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B156",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B157",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B158",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B159",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B350",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B351",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B550",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B551",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B553",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B554",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B555",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B556",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B557",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B558",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B650",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B651",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B652",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B653",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B654",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B656",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B750",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B752",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B753",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B754",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B755",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B756",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B757",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B850",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B851",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B852",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B950",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B952",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B954",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539B955",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5516000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5594000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5594001",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559B050",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559B051",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559B052",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016000",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016005",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016006",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016009",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016010",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016013",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016015",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016021",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026004",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026006",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026010",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6093000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095002",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095004",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095007",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095008",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095013",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095014",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095015",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095018",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095022",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095024",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095026",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095029",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B050",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B051",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B052",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B053",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B054",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B056",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B057",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B058",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B059",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B061",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B063",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B064",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B065",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609B067",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116002",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116005",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116010",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6116012",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6126000",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6126012",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6137019",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195002",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195005",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195010",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195012",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195013",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195016",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195020",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195021",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195027",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195028",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196015",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B050",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B051",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B052",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B053",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B054",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B055",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B056",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B057",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B058",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619B060",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6226007",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629B053",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629B054",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6316003",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6324004",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6326002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6395000",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6395001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6395003",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6395004",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639B050",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6495000",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "649B050",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6526001",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6595000",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6595001",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "659B050",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6695000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6716000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6716002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6716003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6716004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6716006",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6795006",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6795008",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6795009",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6795010",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679B051",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6816000",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6816002",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6826001",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6826002",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6827003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6895002",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6895003",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6895004",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6895009",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689B050",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689B052",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689B054",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8015002",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016002",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016003",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016004",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016006",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016007",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016011",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016014",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016017",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016018",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016019",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016501",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016504",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016506",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016507",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016508",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8025020",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026000",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026001",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026008",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026012",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026017",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026500",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026504",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027506",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094028",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095002",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095003",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095004",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095006",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095008",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095009",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095010",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095014",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095018",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095021",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095024",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095025",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095501",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095502",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095505",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095506",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095507",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095508",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095509",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095510",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095511",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095514",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096507",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096508",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096516",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B050",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B052",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B053",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809B054",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8115012",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8115024",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8116013",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8116014",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8116016",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8116025",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126008",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126009",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126013",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126017",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126026",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8136007",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195003",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195004",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195006",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195007",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195009",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195013",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195014",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195015",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195019",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195020",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195022",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195023",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195024",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195027",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195028",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195030",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195031",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195032",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195035",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195036",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195038",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195039",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195040",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195041",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B052",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B053",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B055",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B056",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B550",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B551",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B553",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B554",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B555",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819B556",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8294003",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8316000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8316001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8316002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8316003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8316004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8326000",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8395000",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8395001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8395003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8395004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8395006",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8395007",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8395008",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839B050",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839B051",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B050",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B051",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B052",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B053",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B054",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B055",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B056",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B057",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B058",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B059",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B060",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B061",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B062",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849B063",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8515003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8516002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8595004",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8596004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859B051",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8895003",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889B050",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889B052",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889B053",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889B056",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C502",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C504",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C601",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C800",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C505",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C102",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C301",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C608",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C705",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C019",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6195024",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C302",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C401",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C503",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C504",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C507",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C602",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C605",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C900",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C103",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742B004",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742B005",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C913",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512C003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512C004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522C002",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C107",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C301",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C302",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C501",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C504",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C507",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C702",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C704",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C706",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C800",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C802",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C017",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "662C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C505",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C508",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512C001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C007",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C015",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682C006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C502",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C504",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522C001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C104",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C605",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C709",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C900",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C911",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C015",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C503",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C300",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C600",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C601",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C605",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C608",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C708",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312C002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C610",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682C003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C102",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C506",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C604",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C608",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822C000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C009",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C501",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C301",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C902",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322C003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322C001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C500",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C901",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C101",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C106",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C600",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C606",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C609",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C010",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "642C001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C908",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6025016",
     "precio_lista": 43394,
     "precio_publico": "N/A"
    },
    {
     "marca": "AMERICANINO",
     "ref": "312C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322C000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322C002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322C004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C909",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C912",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522C003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C502",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C601",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C900",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C902",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C905",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C906",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C907",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C908",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C909",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C910",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "642C000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682C005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C200",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882C005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522C000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C910",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C906",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322C005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C914",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362C000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B011",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522B010",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322C006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532C904",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C905",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332C907",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362C001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372C001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522C005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B105",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B303",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B707",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B708",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B803",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352C003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352C004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552C004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096509",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C100",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C105",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C300",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C502",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C600",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C603",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C703",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C704",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C802",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C101",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C301",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C502",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C603",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339C606",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B104",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B709",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C017",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619C001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "659C000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8126015",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C034",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359C003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539C613",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8323002",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D500",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D600",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D603",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D700",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D703",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D900",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D904",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D500",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D501",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D503",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D507",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D601",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D602",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D603",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D604",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D701",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D702",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D705",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D801",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D900",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D904",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D503",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D014",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "661D000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D504",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D608",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D905",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D502",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D504",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D703",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D706",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D804",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611D009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621D002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "651D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D500",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C605",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C607",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C702",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C703",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321D005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D602",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D604",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D606",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "391D000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D505",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D606",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D800",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D005",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D011",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681D002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D906",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D019",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "641D001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "641D002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D902",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331D907",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "361D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521D002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531D700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521D007",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "361D001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D014",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611D003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611D011",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811D506",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881D005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521D001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C606",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D906",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D502",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882D006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D504",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D908",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D607",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D703",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612D002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612D003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D910",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D914",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D603",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882D005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "662D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D502",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D503",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D701",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362D000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D802",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D805",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D807",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622D003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D506",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D506",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D507",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D606",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D608",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D609",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D702",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D706",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D913",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D709",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D907",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "642D000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "642D001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D905",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D706",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D806",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D903",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D906",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322D003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322D006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612D010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612D007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322D000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D902",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332D903",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532D711",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D500",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D501",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D702",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602D020",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322D002",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522D002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522D003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522D005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362D001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522D001",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D506",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D508",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D509",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D511",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D701",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D706",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D800",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D801",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D802",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D804",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D904",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689D007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D805",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519D002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D504",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D604",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D705",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D708",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D803",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "680D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D503",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839D001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D510",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D900",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D905",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559D004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818D500",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529D000",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D508",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D701",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D501",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D505",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D507",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D601",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D713",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818D501",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818D502",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D506",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529D005",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "659D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "699D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D712",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D603",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D608",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D502",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D512",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D602",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D605",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D901",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D910",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600D005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600D006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D501",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D505",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D009",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D035",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D610",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D012",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D013",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D007",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D502",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D701",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D914",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D016",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D023",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329D003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D915",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D513",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D514",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D515",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D607",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D714",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D715",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D716",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D807",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D808",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D906",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600D000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600D009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D012",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "659D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689D001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D500",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D504",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D700",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "600D007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639D003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D900",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D003",
     "precio_lista": 84701,
     "precio_publico": 159990
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D509",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "840D000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D600",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D702",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D904",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D905",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D700",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D014",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D019",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329D006",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D600",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D603",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D606",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D707",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D909",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559D005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D025",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D026",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D502",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D021",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D500",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D600",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D701",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D801",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D908",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "669D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D908",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359D004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "669D000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "680D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "680D002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319D002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D507",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D902",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D903",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D911",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519D004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D516",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D708",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D906",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D806",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D021",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D707",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D905",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D909",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "399D000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D907",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D016",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "880D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D016",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629D005",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689D004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D904",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "379D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529D006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689D005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D022",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329D000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "510D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "550D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D705",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D710",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D711",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "810D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329D007",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689D008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E502",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E601",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E602",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E500",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E501",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E503",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E504",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E601",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E604",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E701",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E703",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E704",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E801",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529D003",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639D002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D028",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E705",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "310D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "310D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319D004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "320D002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329D001",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329D009",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D601",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D602",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D903",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D908",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369D000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "510D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "510D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519D003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529D001",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529D004",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529D008",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D501",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "550D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "550D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "550D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329D008",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D902",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D906",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D907",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D608",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D901",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D907",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D918",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "340D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "340D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529D002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D504",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D702",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D901",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D902",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D905",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D906",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619D011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629D006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629D007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "810D502",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "840D001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "850D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "880D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "880D004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "320D000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "320D001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D900",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D904",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "610D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D602",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D800",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D802",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D030",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "810D501",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D917",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D916",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529D009",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539D911",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E503",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D601",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E905",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338D500",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "350D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "350D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E505",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E603",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E700",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E706",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E901",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D511",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D709",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E506",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E709",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D606",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D708",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D800",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E702",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E501",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338D700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338D900",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338D901",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E607",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E708",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D509",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D510",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558D009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E600",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E603",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521E006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521E007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338D603",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E900",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339D919",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E904",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D710",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558D000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558D002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558D004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558D005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558D006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558D007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558D008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311E001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E908",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338D501",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338D601",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338D602",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D605",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D607",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E902",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558D003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521E002",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E804",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "330D604",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E609",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E702",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E605",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E606",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E907",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E802",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E803",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E710",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E904",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E602",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E611",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E900",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E610",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E612",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E805",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "880D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E901",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511E001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521E001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521E005",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E502",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E608",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321E000",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E903",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551E002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321E001",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E701",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521E003",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E800",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E600",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E707",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811E500",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321E005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338D600",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "421E001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681E004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321E002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321E003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "661E000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811E501",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "421E000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "421E002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811E502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611E000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "891E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E906",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "391E000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E905",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331E907",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "361E000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "641E000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611E007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E802",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E704",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E701",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E601",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E500",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E603",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831E000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "651E002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611E009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611E008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821E006",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521E008",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311E002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "651E001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611E005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611E001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681E006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611E003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621E005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671E001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E609",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E509",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E709",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E608",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E600",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E505",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E504",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E705",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E704",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E700",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E603",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E506",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E500",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E030",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E031",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621E006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E702",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E530",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E030",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E707",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E606",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E903",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E700",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E602",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E508",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E507",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E703",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E605",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512E001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E504",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322E000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682E006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632E003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E030",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E916",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E503",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E706",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E807",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531E806",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E600",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552E002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E902",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E506",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E503",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D912",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D911",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D910",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D909",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D908",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D907",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832E002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552E005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E602",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E502",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E501",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E701",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E004",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E501",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E501",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322E006",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "642E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E901",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322E004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522E006",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522E004",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322E001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832E003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E510",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "422E000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E903",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E504",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E019",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E910",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E605",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E604",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E601",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "642E001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522E000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522E001",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322E003",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882E004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E801",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E705",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E902",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "662E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622E002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E803",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E909",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E905",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E913",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E505",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "392E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352E004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E912",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E900",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362E000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E915",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E100",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E901",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522E003",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E906",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E914",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338E903",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E511",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E015",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338E904",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338E900",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338E908",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E607",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338E902",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338E901",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338E600",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332E917",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322E002",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E703",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E701",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338E907",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338E906",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338E905",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E602",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E601",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E702",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532E900",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E500",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612E012",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E031",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E603",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E503",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E703",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E702",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E606",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E604",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E600",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E506",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E505",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E504",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E503",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E708",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E707",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E706",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E918",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E917",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E701",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E600",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E508",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E502",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E500",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559E003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E803",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889E007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889E005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639E004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689E006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619E002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E510",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689E001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E521",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "659E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E805",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E705",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E608",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E603",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329E004",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329E000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "699E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "379E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889E004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889E001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E503",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839E003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619E009",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E012",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E910",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E601",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629E000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619E007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E605",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E916",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E703",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E522",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E611",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E610",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E511",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E509",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E502",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "399E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E505",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E704",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319E004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319E000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E520",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E508",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E032",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839E001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E024",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E602",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329E001",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629E003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E804",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E601",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E605",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E911",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E607",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E507",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E912",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529E003",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E902",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "399E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E913",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E801",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E612",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529E002",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "429E001",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E906",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E706",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E506",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679E000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629E004",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329E007",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819E005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E910",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "359E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619E010",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E018",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E017",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E908",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "379E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E909",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E908",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "329E003",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529E001",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "349E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E905",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639E007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "559E004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529E006",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "529E005",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "429E000",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "619E011",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E021",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E800",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E912",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E709",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "369E000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E907",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E901",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E707",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "429E002",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E708",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "519E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E900",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629E007",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E802",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "319E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "639E006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "539E909",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E509",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "339E903",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689E010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608E006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808E002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808E004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808E005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608E005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818E502",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818E501",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818E500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808E003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "550D004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888E002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "320D004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "320D003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888E005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "838E000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "858E000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608E002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848E009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "688E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "618E003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "618E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E707",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "638E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "638E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "618E002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E606",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E503",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "618E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "320D005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "520D002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "520D001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "520D000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E708",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E607",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E608",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E505",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E504",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E706",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511F002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F906",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F910",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F909",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F903",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681F004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631F000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321F002",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321F001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811F524",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551F003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551F001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521F001",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F802",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F600",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F600",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F501",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811F000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F602",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F705",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F904",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F800",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F700",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F605",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831F001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811F002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F500",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F912",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F501",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F023",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621F000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611F005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F701",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341F001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F913",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F900",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811F520",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811F502",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341F000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521F003",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351F000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881F001",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F601",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F024",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811F504",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811F500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811F003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681F001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F607",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F706",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F601",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F701",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F801",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321F003",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521F000",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F904",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F900",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351F001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881F000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "831F000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F905",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881F002",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821F006",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F901",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F505",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F504",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F028",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F029",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611F004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611F000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F707",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "551F002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F903",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F604",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F700",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F603",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F602",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "641F000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "851F001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821F005",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F804",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F704",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "351F002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F604",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F500",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631F003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F907",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321F000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F905",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F803",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F702",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F606",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F603",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341F002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511F001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671F000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371F001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671F001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "631F002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621F003",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611F006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611F003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "511F003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311F000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F902",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F914",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F502",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F703",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311F001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F506",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F502",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F911",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F503",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "531F507",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F901",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F908",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F503",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "391F000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "328E000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888F003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848F003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848F002",
     "precio_lista": 149900,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818F002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "618F002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "618F001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608F003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888F000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608F005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608F004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "528F001",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "AMERICANINO",
     "ref": "331F915",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818F000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "688F000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "528F004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "528F003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "528F000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338F600",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608F002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608F001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608F000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "371F000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "341F003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818F001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888F001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538F700",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338F900",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888F002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "688F001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "638F000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "518F000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "391F001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338F902",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818F500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "688F002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848F001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848F000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "618F000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538F701",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338F903",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "528F002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "521F004",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "558F000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "338F901",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "691F001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538F900",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F800",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F700",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F601",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512F000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F604",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F600",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F500",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F600",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F900",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "362F000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F700",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322F004",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F902",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F703",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F503",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F502",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342F001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312F000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882F021",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F602",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F605",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F023",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F524",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612F000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F801",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F603",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352F000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F602",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F501",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F022",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882F000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F604",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F012",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F502",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552F004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "392F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352F001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F702",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522F003",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352F002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852F000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822F005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552F003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F903",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882F020",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822F006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F504",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F706",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F504",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682F001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632F000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612F004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612F002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F607",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F605",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F502",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F908",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F907",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F503",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "692F000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552F001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F702",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882F001",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622F002",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F501",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "342F000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F803",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522F002",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "321F006",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882F002",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682F002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682F000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672F000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512F001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F902",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F505",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F503",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F904",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322F002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F802",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F606",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852F001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "832F001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F506",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F705",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322F001",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F904",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F804",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F704",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F608",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F909",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882F003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F523",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652F001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F701",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F507",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522F001",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F911",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F601",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "652F000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "632F001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622F003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F905",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F901",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F701",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F900",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "372F000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622F005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612F003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672F001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552F005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F606",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F603",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "552F002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F504",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F805",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F708",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F508",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F903",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F707",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F906",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F505",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352F003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F912",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322F000",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612F001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322F003",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "642F000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "352F004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F901",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "312F001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532F500",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332F501",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3187000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397914",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "512A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "528A000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328506",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328507",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "532A908",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386914",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5387521",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5926000",
     "precio_lista": 162877,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5937000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6006500",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6006501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A025",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A026",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A201",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036028",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096033",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096047",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096050",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097033",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098011",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098027",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098037",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6188000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6208000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6218001",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6218004",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621A006",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621B004",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6226008",
     "precio_lista": 325808,
     "precio_publico": 599900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6228002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6228010",
     "precio_lista": 328182,
     "precio_publico": 619900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6236000",
     "precio_lista": 168308,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6237003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6238002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623A001",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623A003",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623A006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6294001",
     "precio_lista": 141153,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6296012",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A004",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A092",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671A006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6726009",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672B010",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6796017",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6816006",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6817005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6817006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6818004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6818007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6827004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6827007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6828005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682A010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6836007",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6837007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6838000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683A006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898011",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898016",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6936000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742A003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742A006",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7498502",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8003900",
     "precio_lista": 26015,
     "precio_publico": 47900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8005700",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8007805",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8008700",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8008803",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017513",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A516",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026517",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027700",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028505",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A200",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A503",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A512",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B015",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B020",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036500",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036802",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036805",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037503",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038007",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038506",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A512",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A515",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A518",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8085104",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086110",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086119",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086121",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086122",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086134",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086145",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086151",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086153",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086160",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086166",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086169",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086171",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086172",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8087101",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8087107",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8087108",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095504",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097523",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097805",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097900",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098025",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098512",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098520",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098523",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098525",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A023",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A524",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8117030",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A510",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A024",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A200",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8187111",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8188000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196011",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A519",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8208000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8217004",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8217005",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8217006",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8218001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8218004",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8218006",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A009",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821B004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8227005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8228001",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8228003",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8228004",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8228005",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8228007",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822A003",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B001",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B005",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B008",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8236001",
     "precio_lista": 190032,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8236006",
     "precio_lista": 336670,
     "precio_publico": 619900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8237005",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8237007",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8238000",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A002",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A010",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8296001",
     "precio_lista": 173739,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8296011",
     "precio_lista": 336670,
     "precio_publico": 619900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8297008",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8297010",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298001",
     "precio_lista": 344065,
     "precio_publico": 649900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298008",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298009",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298014",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A002",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A005",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A017",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8338003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8818005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881A006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881B004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8827006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8828001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8828003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8836006",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8897006",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8898005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889A006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8994000",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "318A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397913",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "518A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5296000",
     "precio_lista": 200894,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386913",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027024",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027025",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028027",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036021",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036025",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098035",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6098036",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6099083",
     "precio_lista": 29816,
     "precio_publico": 39000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127023",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6133017",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138019",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6187001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6217002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6218006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6227001",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6227004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6227005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622B000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6236002",
     "precio_lista": 173739,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6237000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6238000",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623A002",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623A012",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6296003",
     "precio_lista": 184601,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6296006",
     "precio_lista": 173739,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6296011",
     "precio_lista": 331239,
     "precio_publico": 609900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297003",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297006",
     "precio_lista": 322888,
     "precio_publico": 609900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672B007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672B008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6808002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6816005",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681A008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6827008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682A012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6835004",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6837011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6838005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7428001",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742A000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742A002",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742A005",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A007",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A008",
     "precio_lista": 18476,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A009",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A011",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A014",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A016",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8003804",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8005802",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8008701",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8008802",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800A803",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017505",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017507",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A509",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A511",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A515",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A517",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A518",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026502",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026700",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026802",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027900",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028508",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028512",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A018",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A508",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A516",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8033601",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037507",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038505",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A016",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A516",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8085113",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8085120",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086101",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086102",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086105",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086113",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086115",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086116",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086126",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086143",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086144",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086146",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086154",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086159",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086161",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086167",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086168",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086173",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086174",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086175",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086500",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8087100",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8087103",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8087104",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8087105",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A509",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A511",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096015",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096518",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096802",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097700",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098011",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098507",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098509",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098524",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A518",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A522",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B900",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B901",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8127017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128513",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812A510",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A520",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A521",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8216008",
     "precio_lista": 331239,
     "precio_publico": 609900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8217001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8217002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8218002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8218003",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8218010",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A001",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821B001",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821B007",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821B009",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8225006",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8227000",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8227001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822A007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822A010",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B009",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8237003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8237004",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8238002",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8238006",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A001",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A004",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8295005",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8296004",
     "precio_lista": 190032,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8296007",
     "precio_lista": 162877,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8296009",
     "precio_lista": 200894,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8297004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298005",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298007",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298010",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298012",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298018",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A015",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8396006",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859A005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8817006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8818008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881B006",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8827005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8828008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882B017",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8837008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8838001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8897009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8898006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8898008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889A004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889A008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8926000",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8997000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "318A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3218000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "328A002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "332A907",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3397915",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522A000",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5298005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386916",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386956",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5387112",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6016019",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017027",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601A015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027028",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6027029",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6028026",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036022",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036024",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6038023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6096044",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6097032",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127026",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6136019",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6138018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6187002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196037",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6216000",
     "precio_lista": 162877,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6216001",
     "precio_lista": 162877,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6217000",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6217004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621B003",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6228003",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622A001",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622A002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622A008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622B002",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622B005",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6237001",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6238001",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623A000",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623A004",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6288000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6293007",
     "precio_lista": 325808,
     "precio_publico": 599900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6295007",
     "precio_lista": 325808,
     "precio_publico": 599900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297004",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298005",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298008",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298009",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298016",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A002",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "661A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "671A007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672B009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6738005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6738007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6798010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6798011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679A010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6816004",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6827005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6827006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897010",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "741B000",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742A004",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742A008",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742A009",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7498001",
     "precio_lista": 23771,
     "precio_publico": 44900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7498501",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A013",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A017",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8003803",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8006805",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8008806",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800A701",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017021",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B901",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B902",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027802",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A007",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A201",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A515",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A517",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A518",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A519",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B014",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8036700",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038503",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038514",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8085127",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086103",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086124",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086127",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086129",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086130",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086136",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086148",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086149",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086155",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086158",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086162",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086164",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086177",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8087102",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8087106",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6093030",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096900",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097503",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097508",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097519",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097526",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097802",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098009",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098019",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098514",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098522",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A506",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A511",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A519",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B510",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128514",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138507",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A514",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A517",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8188001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8198010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819A518",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8208001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8217007",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8218000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A010",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821B000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821B010",
     "precio_lista": 344065,
     "precio_publico": 649900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8226003",
     "precio_lista": 168308,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8227002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8227004",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8227006",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8228009",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822A000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822A005",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822A006",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B000",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8236004",
     "precio_lista": 179170,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8237001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8237002",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8238003",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A003",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8296006",
     "precio_lista": 162877,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8296012",
     "precio_lista": 190032,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8297001",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A001",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A003",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A013",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A014",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "852A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8598001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8808003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "880A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881A005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881B002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881B003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881B007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8838006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8896008",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8896011",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8897008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8897011",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8898004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8898009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889A007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8998000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "899A000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "318A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "518A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "518A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5237000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328108",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5328802",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5386953",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5387120",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6017026",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6018024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6026021",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602A200",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6035022",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6037022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "611A015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127022",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6127024",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6128022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612B025",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6188001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6196036",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6208001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6217001",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6217003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6218005",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621A000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621A001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621B000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621B008",
     "precio_lista": 344065,
     "precio_publico": 649900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6226001",
     "precio_lista": 173739,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6226002",
     "precio_lista": 162877,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6227000",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6227002",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6227009",
     "precio_lista": 322888,
     "precio_publico": 609900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6228000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622A003",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622B004",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6234007",
     "precio_lista": 141153,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6237002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6237004",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623A007",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6295000",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6296002",
     "precio_lista": 184601,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297000",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297001",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297005",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298001",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6298006",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629A001",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6436001",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6736009",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6738004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6796004",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679A011",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6816003",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6827001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6828006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682A013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6837010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6837013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6896010",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6897007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6898001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "740A000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7428000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742A001",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742A007",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "743A001",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A000",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A006",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A010",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749A012",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8002999",
     "precio_lista": 41222,
     "precio_publico": 75900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8003700",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8003990",
     "precio_lista": 42308,
     "precio_publico": 77900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8005805",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8005900",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8008805",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016802",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016805",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017512",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8017517",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801A503",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801B900",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026805",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027511",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028513",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8028514",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A017",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802A520",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802B003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8033902",
     "precio_lista": 26015,
     "precio_publico": 47900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8037016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8038513",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "803A517",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8085115",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086107",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086109",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086117",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086123",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086138",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086142",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086150",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086156",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086157",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086165",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086170",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086176",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086178",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8086179",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A503",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808A510",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096514",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096517",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096700",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097013",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097506",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097516",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8098026",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A520",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A521",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809A523",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811A008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811B511",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8128005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B502",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8138012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813A010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8196047",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8216005",
     "precio_lista": 162877,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8216007",
     "precio_lista": 190032,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8217000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A003",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A006",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821A008",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821B002",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821B005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8226004",
     "precio_lista": 168308,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8226007",
     "precio_lista": 331239,
     "precio_publico": 609900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8227003",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8228002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8228006",
     "precio_lista": 333477,
     "precio_publico": 629900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822A002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822A004",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B003",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B004",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B010",
     "precio_lista": 344065,
     "precio_publico": 649900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8235004",
     "precio_lista": 162877,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8237000",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8237009",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8238004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A006",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823A009",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8288000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8295011",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8296005",
     "precio_lista": 195463,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8297003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8297006",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8297007",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298006",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8298019",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A006",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829A010",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8398008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839A008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B900",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8808004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881A007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8828004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882A005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882A006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882A008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882A009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882B016",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8838003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8838007",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8838008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8897003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8897010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889A005",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889A009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8996000",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621C000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621C001",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621C002",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6236001",
     "precio_lista": 173739,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623B003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623B006",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "643B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681C004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683B008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683B012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "683B013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689B056",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689B057",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7020900",
     "precio_lista": 19497,
     "precio_publico": 35900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7021900",
     "precio_lista": 19497,
     "precio_publico": 35900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7091000",
     "precio_lista": 19497,
     "precio_publico": 35900
    },
    {
     "marca": "AMERICANINO",
     "ref": "709B050",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "709B051",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7193505",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7194500",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729B051",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729B550",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732B000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732B001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732B500",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739B050",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739B051",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739B550",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739B551",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739B552",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742B000",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742B501",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B507",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B508",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B513",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7596512",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B550",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B551",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B552",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B553",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B554",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B555",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B559",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B560",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B561",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B562",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B563",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769B050",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769B051",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769B052",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769B053",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769B550",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769B551",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779B050",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779B052",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779B550",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B006",
     "precio_lista": 285630,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B007",
     "precio_lista": 302437,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B008",
     "precio_lista": 302437,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B106",
     "precio_lista": 285630,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B107",
     "precio_lista": 285630,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B108",
     "precio_lista": 285630,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800B701",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800B702",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800B803",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800B804",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095517",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8135036",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821C000",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821C002",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821C003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821C004",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821C005",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823B002",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823B003",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823B005",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823B006",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823B007",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8296002",
     "precio_lista": 184601,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829B050",
     "precio_lista": 344065,
     "precio_publico": 649900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829B051",
     "precio_lista": 344065,
     "precio_publico": 649900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848B001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883B006",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889B054",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 21870,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 21870,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 21870,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 23085,
     "precio_publico": 95000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 21870,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 21870,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 24300,
     "precio_publico": 100000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 21870,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 21870,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 23615,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 24300,
     "precio_publico": 100000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 68040,
     "precio_publico": 280000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 55890,
     "precio_publico": 230000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 55890,
     "precio_publico": 230000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 77760,
     "precio_publico": 320000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 51030,
     "precio_publico": 210000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 48600,
     "precio_publico": 200000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 58320,
     "precio_publico": 240000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 58320,
     "precio_publico": 240000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 51030,
     "precio_publico": 210000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 58320,
     "precio_publico": 240000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 51030,
     "precio_publico": 210000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 48600,
     "precio_publico": 200000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 58320,
     "precio_publico": 240000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 58320,
     "precio_publico": 240000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 51030,
     "precio_publico": 210000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 21870,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 21870,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 23085,
     "precio_publico": 95000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 29160,
     "precio_publico": 120000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 21870,
     "precio_publico": 90000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 26730,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "N/A",
     "precio_lista": 29076,
     "precio_publico": 110000
    },
    {
     "marca": "AMERICANINO",
     "ref": "3393501",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5393402",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6033031",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "711C500",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7293504",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7514501",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7514508",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C504",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C505",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C506",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C507",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C508",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C509",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B556",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B557",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759B558",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761C000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761C001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761C500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761C501",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7738500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779B051",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779B551",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779B552",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B200",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "6036029",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8216004",
     "precio_lista": 190032,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8335010",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B201",
     "precio_lista": 277227,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B202",
     "precio_lista": 201597,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B203",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974022",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974023",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974024",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974025",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974026",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974027",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974028",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974029",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974034",
     "precio_lista": 20048,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974035",
     "precio_lista": 20048,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974036",
     "precio_lista": 20048,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974037",
     "precio_lista": 20048,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974038",
     "precio_lista": 23400,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974040",
     "precio_lista": 26753,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974041",
     "precio_lista": 26753,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974043",
     "precio_lista": 26753,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974044",
     "precio_lista": 40163,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974045",
     "precio_lista": 46868,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974046",
     "precio_lista": 53573,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976000",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976004",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976006",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976011",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976013",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976014",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976015",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976016",
     "precio_lista": 18954,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976023",
     "precio_lista": 35335,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976025",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976026",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976027",
     "precio_lista": 18477,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976028",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976029",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976030",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976031",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976032",
     "precio_lista": 18477,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976033",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976034",
     "precio_lista": 23771,
     "precio_publico": 44900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976035",
     "precio_lista": 23771,
     "precio_publico": 44900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978002",
     "precio_lista": 58765,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978003",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978004",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978005",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978006",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978007",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978500",
     "precio_lista": 58765,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8220006",
     "precio_lista": 128118,
     "precio_publico": 235900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841B013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7222008",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7250001",
     "precio_lista": 9900,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7311001",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7311004",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7312000",
     "precio_lista": 23842,
     "precio_publico": 43900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7312003",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7321001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7321004",
     "precio_lista": 52084,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7321007",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7322001",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7322002",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7331000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7331001",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7331005",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7331018",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7331019",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7331020",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7391011",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7391016",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9973000",
     "precio_lista": 4626,
     "precio_publico": 4900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9973002",
     "precio_lista": 21466,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9973003",
     "precio_lista": 26612,
     "precio_publico": 49000
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974000",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974001",
     "precio_lista": 17155,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974002",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974004",
     "precio_lista": 6517,
     "precio_publico": 11900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974005",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974006",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974007",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974008",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974009",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974010",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974014",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974016",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974017",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974018",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974020",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974021",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974033",
     "precio_lista": 3956,
     "precio_publico": 5900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9974039",
     "precio_lista": 73688,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976009",
     "precio_lista": 20105,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976017",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976018",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976019",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976020",
     "precio_lista": 52882,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976022",
     "precio_lista": 23242,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976024",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976036",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9976037",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978000",
     "precio_lista": 20924,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978009",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978010",
     "precio_lista": 13361,
     "precio_publico": 15900
    },
    {
     "marca": "AMERICANINO",
     "ref": "9978501",
     "precio_lista": 25333,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A000",
     "precio_lista": 70529,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A001",
     "precio_lista": 8678,
     "precio_publico": 14900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A002",
     "precio_lista": 58765,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A003",
     "precio_lista": 58765,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A004",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A005",
     "precio_lista": 17588,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A006",
     "precio_lista": 17588,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A007",
     "precio_lista": 18832,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A008",
     "precio_lista": 18832,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A009",
     "precio_lista": 18832,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A010",
     "precio_lista": 15050,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A011",
     "precio_lista": 22613,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A012",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "AQ3091",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "DH1006",
     "precio_lista": 75546,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "EE7245",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "EE8836",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "EF2508",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "EG4958",
     "precio_lista": 148182,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F235103",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "FL9627",
     "precio_lista": 167983,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "FL9670",
     "precio_lista": 100756,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "FM1347",
     "precio_lista": 75546,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6225003",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "700A000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "700A001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "701B000",
     "precio_lista": 29065,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "701B001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "701B002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "701B003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7023002",
     "precio_lista": 24928,
     "precio_publico": 45900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7033000",
     "precio_lista": 24928,
     "precio_publico": 45900
    },
    {
     "marca": "AMERICANINO",
     "ref": "703A000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "703A001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7092000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7092003",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7093000",
     "precio_lista": 24928,
     "precio_publico": 45900
    },
    {
     "marca": "AMERICANINO",
     "ref": "709A000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "709A001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "709A002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "709A003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7105500",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "710A000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "710A001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "710A002",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7117500",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7117501",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7117502",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7117504",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7118500",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7118501",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B204",
     "precio_lista": 252017,
     "precio_publico": 359000
    },
    {
     "marca": "AMERICANINO",
     "ref": "711A500",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "711A501",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "711A502",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "711A503",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "711B500",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "711B501",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7127501",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7127502",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7127503",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7128501",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "712A500",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "712A501",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7138500",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7138501",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7138502",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "713A501",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "713A502",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7173501",
     "precio_lista": 19900,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7196501",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7197500",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7197501",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7197502",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7198500",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7198501",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7198502",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7198503",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "719A500",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "719A501",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "719A502",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8033602",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8294006",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8825002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8003500",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016700",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8032999",
     "precio_lista": 41222,
     "precio_publico": 75900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094007",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8094034",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889B055",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A013",
     "precio_lista": 64647,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721C501",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721C500",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7628001",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8035509",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771C000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881C000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751C512",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731C001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848B019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848B018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801C016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731C000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731C500",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "BK6727",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "702C000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "712C500",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722C001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722C500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732C000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732C001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732C500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732C501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841C030",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601C017",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822C001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822C002",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7216001",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822C003",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7216501",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7216503",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7217000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7217001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7217002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7217003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7217501",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7217502",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7217504",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7218000",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7218001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7218002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7218003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7218501",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7218503",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721A001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721A004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721A500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721A501",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721B501",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721B502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7226000",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7227000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7227002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7227004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7227500",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7227501",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7227502",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7227503",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7228000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7228001",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7228002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7228500",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7228501",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7228502",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7228503",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7228504",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722A001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722A002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722A003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722A500",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722A501",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722A502",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7235000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7235001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7235002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7235500",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7236002",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7236501",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7236503",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7237001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7237002",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7237003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7237004",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7238000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7238001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7238002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7238003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7238500",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7238501",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7238502",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7238503",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "723A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "723A500",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "725B200",
     "precio_lista": 89019,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7292000",
     "precio_lista": 62946,
     "precio_publico": 115900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7296000",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7296001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7296005",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7296501",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7296502",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7297000",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7297001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7297002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7297006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7297007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7297501",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7297502",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7297503",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7297504",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7298500",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7298501",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729A000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729A501",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729A502",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7305901",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7305910",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7308500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "730A500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7313001",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7313006",
     "precio_lista": 28730,
     "precio_publico": 52900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7313801",
     "precio_lista": 19497,
     "precio_publico": 35900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7314002",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7315004",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7316000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7316002",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7316003",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7317000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7317001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7317002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7317003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7317004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7317005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7317501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7317504",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7317506",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7318000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7318001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7318002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7318003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7318501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7318502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7318504",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731A000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731A001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731A500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731A501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731A502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731B000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731B001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731B002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731B500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731B501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731B502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7324003",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7325000",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7326001",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327006",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327503",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7327504",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7328000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7328001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7328002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7328501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7328502",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7328503",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732A000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732A001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732A002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732A501",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732A502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732A503",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7332000",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7332001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7332002",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7332004",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7332005",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7333002",
     "precio_lista": 35791,
     "precio_publico": 65900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7333003",
     "precio_lista": 35791,
     "precio_publico": 65900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7334003",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7335000",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7335002",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7335500",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7335504",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7336005",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7337001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822C005",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7337002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7337003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7337004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7337005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7337006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7337502",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7338000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7338001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7338002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7338003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7338500",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7338502",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7338504",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "733A000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "733A001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "733A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "733A003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "733A500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "733A501",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "733A502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7392000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7392002",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7392004",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7393004",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7393005",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7394005",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7395000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7395001",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7395002",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7395505",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7396000",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7396001",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7396002",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7396003",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7396004",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7396005",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7396500",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7396501",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7396507",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397007",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397008",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397504",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397505",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397506",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397507",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7398000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7398500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739A000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739A001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739A003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739A004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739A500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739A501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739A502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739A503",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A002",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A501",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B503",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B504",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B505",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B509",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B510",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B511",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751B512",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7528000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A504",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A505",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A506",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A507",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A501",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A505",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A506",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A507",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7594508",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7598000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7598502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7598507",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A504",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A505",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A506",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A513",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "760A500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "760A501",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "760A502",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7617501",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A002",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A003",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A004",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A005",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A006",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A007",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A008",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A009",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A501",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A502",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A503",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A504",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A505",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A506",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761A507",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761B000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761B001",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761B002",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761B003",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761B004",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761B502",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761B503",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7627500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7627501",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7627504",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7627505",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7628000",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7628501",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7628502",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7628503",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A002",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A003",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A004",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A005",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A006",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A007",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A501",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A502",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A503",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A504",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A505",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762A506",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7636504",
     "precio_lista": 20041,
     "precio_publico": 36900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7638000",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7638001",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7638002",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7638500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7638502",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7638504",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A001",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A002",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A003",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A004",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A005",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A501",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A502",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A503",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7697502",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7697503",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7697504",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7698000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7698001",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7698002",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7698003",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7698004",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7698500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7698502",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7698503",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7698504",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769A000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769A001",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769A002",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769A003",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769A500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769A501",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769A502",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769A503",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7717000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7717001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7718000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7718001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7718002",
     "precio_lista": 18477,
     "precio_publico": 34900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7718501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7718502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771A000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771A500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771A501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771A502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771B000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771B500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771B501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7724002",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7725501",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7727000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7727001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7727002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7728000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7728501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7728502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772A003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772A500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772A501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772A502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7737000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7737001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7737002",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7737502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7738000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7738501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7738502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "773A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "773A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "773A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "773A500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "773A501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "773A502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7796000",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7796501",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7797000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7797001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7797002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7797003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7797501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7797502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7797503",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7798000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7798001",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7798002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7798003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7798500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7798501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7798502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779A000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779A500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779A501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779A502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779A503",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7825500",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7825502",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7825503",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7826500",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7826501",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7826502",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7826503",
     "precio_lista": 141153,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7826504",
     "precio_lista": 152015,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7827500",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7827501",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7827502",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B000",
     "precio_lista": 242919,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B001",
     "precio_lista": 275319,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B100",
     "precio_lista": 226719,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B101",
     "precio_lista": 242919,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B102",
     "precio_lista": 186219,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7888000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7888500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7895501",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7895502",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7895503",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7895504",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7896500",
     "precio_lista": 152015,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7896501",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7896502",
     "precio_lista": 152015,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7896503",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7896504",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7896505",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7896506",
     "precio_lista": 146584,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7896507",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7896508",
     "precio_lista": 152015,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "789A500",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "789A501",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "789A503",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7928003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "AQ0866",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "AQ1220",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "B37091",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "AMERICANINO",
     "ref": "B37092",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "AMERICANINO",
     "ref": "B37351",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "AMERICANINO",
     "ref": "B37434",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "B37727",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "B37728",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "B41547",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "B41996",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "BD7475",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "BD7962",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "BD7976",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "BY9271",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "BZ0086",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "BZ0087",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "C77124",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822C006",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622C001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "CG5463",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "CG6014",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "CG6449",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "DB3144",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "DB3537",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "EE5118",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "EE5853",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "EE5854",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "EE6034",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "EF9219",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "EG5794",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "F33900",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "F35018",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "G27706",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "G27722",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "G27946",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "M20324",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "M20325",
     "precio_lista": 153477,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7216500",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "723A000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "723A501",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "723A502",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7298000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7298001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7298002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7298003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729A500",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7516506",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7517501",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7517502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7517504",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7517508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7517510",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7518500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7518502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7518503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7518504",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7518505",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7518506",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7518507",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7518508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7518509",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A504",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A505",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A506",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751A508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7526503",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7527500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7527504",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7527505",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7527507",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7527508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7528501",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7528503",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7528504",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7528505",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7528506",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7528507",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A001",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A501",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A503",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752A510",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7537502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7537503",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7537505",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7537510",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7538000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7538001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7538503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7538504",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7538505",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7538506",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7538507",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7538508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7538509",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A503",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A504",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "753A509",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7596502",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597505",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597506",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597507",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597508",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597510",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597511",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597512",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7598500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7598501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7598504",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7598505",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7598506",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7598508",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A501",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A503",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A507",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A509",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A510",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A511",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759A512",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "DH1004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "ED8021",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "ED8668",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "F172550",
     "precio_lista": 478908,
     "precio_publico": 569900
    },
    {
     "marca": "AMERICANINO",
     "ref": "F172662",
     "precio_lista": 478908,
     "precio_publico": 569900
    },
    {
     "marca": "AMERICANINO",
     "ref": "F235100",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F235101",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510021",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510031",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510141",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510212",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510215",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510319",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510325",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510326",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510462",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510501",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510525",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510539",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510550",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510560",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510580",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510615",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510620",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510645",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510646",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510662",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F510664",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "F548142",
     "precio_lista": 369739,
     "precio_publico": 439990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F548320",
     "precio_lista": 369739,
     "precio_publico": 439990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F548558",
     "precio_lista": 369739,
     "precio_publico": 439990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F548607",
     "precio_lista": 369739,
     "precio_publico": 439990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F548633",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561141",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561215",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561319",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561325",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561420",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561462",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561527",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561550",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561615",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561660",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F561664",
     "precio_lista": 294109,
     "precio_publico": 349990
    },
    {
     "marca": "AMERICANINO",
     "ref": "F620580",
     "precio_lista": 327647,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "F621580",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C505",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C506",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C507",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C508",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C509",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "701C000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "701C001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762C000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762C001",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762C002",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762C500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762C501",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772C000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772C500",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772C501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829C000",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829C001",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829C002",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829C003",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829C004",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829C005",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829C006",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829C007",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629C000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629C005",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629C006",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689C000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B206",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B207",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B208",
     "precio_lista": 252092,
     "precio_publico": 299990
    },
    {
     "marca": "AMERICANINO",
     "ref": "620C000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "820C000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "820C001",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C506",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812C507",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800B703",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C026",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C022",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B016",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B017",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B018",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B019",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889C008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889C009",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B109",
     "precio_lista": 302513,
     "precio_publico": 359990
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C030",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C031",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C032",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689C007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "679C004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A014",
     "precio_lista": 6655,
     "precio_publico": 9900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A015",
     "precio_lista": 6655,
     "precio_publico": 9900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B012",
     "precio_lista": 285706,
     "precio_publico": 339990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B013",
     "precio_lista": 361336,
     "precio_publico": 429990
    },
    {
     "marca": "AMERICANINO",
     "ref": "709C000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "709C002",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "719C500",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "719C501",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729C500",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729C502",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729C503",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739C001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739C002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739C003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739C500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739C501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739C502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C504",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C505",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C506",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C507",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C509",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C510",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C511",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C513",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C514",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C515",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C516",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769C000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769C001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769C002",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769C003",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769C500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769C501",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769C502",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779C000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779C500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779C501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848C005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848C006",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C020",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C025",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849C026",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C025",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809C026",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C512",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819C010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "839C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B112",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B113",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B114",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B115",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B509",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B715",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B718",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B719",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B808",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B809",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B109",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B305",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B508",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B714",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B807",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B306",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B608",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B607",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629C008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689C008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800B704",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "750C500",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821D000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821D001",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821D002",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821D003",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821D004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C600",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C601",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C602",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C700",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C701",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848C007",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848C008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859C003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B014",
     "precio_lista": 302513,
     "precio_publico": 359990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B209",
     "precio_lista": 302513,
     "precio_publico": 359990
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C300",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C603",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538C604",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604B002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614B004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614B005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614B006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "804B001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814B005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "743A002",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "AMERICANINO",
     "ref": "743A003",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "AMERICANINO",
     "ref": "743A004",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "AMERICANINO",
     "ref": "743A005",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "AMERICANINO",
     "ref": "743A006",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7498512",
     "precio_lista": 78829,
     "precio_publico": 148900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7536507",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "1111109",
     "precio_lista": 5209,
     "precio_publico": 5209
    },
    {
     "marca": "AMERICANINO",
     "ref": "7310010",
     "precio_lista": 29816,
     "precio_publico": 39000
    },
    {
     "marca": "AMERICANINO",
     "ref": "7310011",
     "precio_lista": 32532,
     "precio_publico": 42000
    },
    {
     "marca": "AMERICANINO",
     "ref": "7399011",
     "precio_lista": 48825,
     "precio_publico": 63000
    },
    {
     "marca": "AMERICANINO",
     "ref": "7399013",
     "precio_lista": 32532,
     "precio_publico": 42000
    },
    {
     "marca": "AMERICANINO",
     "ref": "7399014",
     "precio_lista": 48825,
     "precio_publico": 63000
    },
    {
     "marca": "AMERICANINO",
     "ref": "748A000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752C510",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7620001",
     "precio_lista": 17325,
     "precio_publico": 31900
    },
    {
     "marca": "AMERICANINO",
     "ref": "322B008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3795003",
     "precio_lista": 108566,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "522B007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602B021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604B000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095033",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6095034",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6226004",
     "precio_lista": 168308,
     "precio_publico": 309900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622B009",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622B010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "623B005",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6295002",
     "precio_lista": 162877,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6295006",
     "precio_lista": 141153,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6297002",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6895005",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6895006",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "6895007",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "702B000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "702B001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7115500",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "712A502",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "712B500",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "712B501",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7135501",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7216000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7216002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722B000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722B500",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "725B201",
     "precio_lista": 100756,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7295001",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7295002",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7295004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7295500",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7295501",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7295503",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7326501",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732B501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732B502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7337500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7395003",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7395004",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7395506",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7397502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742B002",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742B003",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742B500",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "AMERICANINO",
     "ref": "745B000",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7516504",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7516507",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7516509",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7517503",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7517505",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7517509",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7528500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B501",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B502",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B503",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B504",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B505",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B506",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B509",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B511",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B512",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752B514",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7536505",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7595500",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7595501",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7595502",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7595503",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7595504",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7595505",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7595506",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7595507",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7595508",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7595509",
     "precio_lista": 29816,
     "precio_publico": 54900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7596500",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597509",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7597513",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7618501",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762B000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762B001",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762B002",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762B003",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762B500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762B501",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7636500",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7636503",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7636505",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A006",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A007",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "763A504",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7726001",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772B000",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772B002",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772B500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772B501",
     "precio_lista": 63477,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772B502",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7795000",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7795001",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7795500",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7795501",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7795502",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7825501",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B002",
     "precio_lista": 445294,
     "precio_publico": 529900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B003",
     "precio_lista": 285630,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B004",
     "precio_lista": 285630,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B005",
     "precio_lista": 285630,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B103",
     "precio_lista": 210000,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B104",
     "precio_lista": 252017,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B105",
     "precio_lista": 445294,
     "precio_publico": 529900
    },
    {
     "marca": "AMERICANINO",
     "ref": "7996000",
     "precio_lista": 19497,
     "precio_publico": 35900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8003800",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8003802",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016008",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016009",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016012",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016013",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016509",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8016510",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026501",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8026509",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8027509",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808B007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095005",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095011",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095013",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095015",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095016",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095017",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095027",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095028",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095029",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095500",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095512",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095513",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095515",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095516",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8095518",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096513",
     "precio_lista": 54256,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8096805",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8097525",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812B503",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "813B502",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195043",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195044",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195045",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195046",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195047",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8195048",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8216000",
     "precio_lista": 179170,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8216001",
     "precio_lista": 179170,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8216006",
     "precio_lista": 179170,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8226002",
     "precio_lista": 179170,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B006",
     "precio_lista": 344065,
     "precio_publico": 649900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B012",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822B013",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8236003",
     "precio_lista": 190032,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8236005",
     "precio_lista": 179170,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823B000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "823B001",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8295008",
     "precio_lista": 152015,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8295010",
     "precio_lista": 141153,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8296010",
     "precio_lista": 195463,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B017",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842B022",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "844B000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848B005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "883B005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8895005",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "8895007",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "749B000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B205",
     "precio_lista": 302437,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B009",
     "precio_lista": 285630,
     "precio_publico": 339900
    },
    {
     "marca": "AMERICANINO",
     "ref": "672C004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612C012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C018",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602C019",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308504",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "3308604",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308704",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "5308803",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C018",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C019",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802C020",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C800",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C801",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C802",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C803",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842C021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848C800",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848C801",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B010",
     "precio_lista": 302513,
     "precio_publico": 359990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B011",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B510",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B511",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B720",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538B609",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B214",
     "precio_lista": 251261,
     "precio_publico": 349000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B215",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B216",
     "precio_lista": 251261,
     "precio_publico": 349000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B217",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B218",
     "precio_lista": 276471,
     "precio_publico": 429000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B219",
     "precio_lista": 200840,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B220",
     "precio_lista": 200840,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B221",
     "precio_lista": 242857,
     "precio_publico": 289000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B222",
     "precio_lista": 200840,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A017",
     "precio_lista": 58765,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C035",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C036",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608C037",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848C009",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848C010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A018",
     "precio_lista": 35235,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609C038",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808C008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822D000",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822D001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822D002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822D003",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822D004",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822D005",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882D002",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622D002",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622D004",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621D001",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "745B001",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "AMERICANINO",
     "ref": "745B002",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C517",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759C518",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B015",
     "precio_lista": 361336,
     "precio_publico": 429990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B016",
     "precio_lista": 302513,
     "precio_publico": 359990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B110",
     "precio_lista": 285706,
     "precio_publico": 339990
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "701D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "711D500",
     "precio_lista": 50771,
     "precio_publico": 95900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761D000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761D001",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761D500",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761D501",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761D502",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721D500",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721D502",
     "precio_lista": 89471,
     "precio_publico": 169000
    },
    {
     "marca": "AMERICANINO",
     "ref": "731D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731D001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731D500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731D501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D504",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D505",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D506",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D507",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D508",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D509",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D510",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751D511",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771D000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771D001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771D500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771D501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D017",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801D018",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D100",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D504",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D505",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604D000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "804D000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D101",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D102",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D103",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D506",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D507",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D508",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D602",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D603",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D604",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D704",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D705",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538D706",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841D018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601D025",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D000",
     "precio_lista": 47000,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D001",
     "precio_lista": 117588,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D002",
     "precio_lista": 82294,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629D002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D000",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D001",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D002",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D003",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D004",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D005",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D020",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D000",
     "precio_lista": 268067,
     "precio_publico": 349000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D001",
     "precio_lista": 293277,
     "precio_publico": 409000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D002",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D003",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D004",
     "precio_lista": 251261,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D005",
     "precio_lista": 301681,
     "precio_publico": 359000
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D016",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B210",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B211",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "997A016",
     "precio_lista": 7000,
     "precio_publico": 11900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B212",
     "precio_lista": 504193,
     "precio_publico": 599990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B213",
     "precio_lista": 361336,
     "precio_publico": 429990
    },
    {
     "marca": "AMERICANINO",
     "ref": "621D000",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629D003",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D008",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "744D000",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D100",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785B017",
     "precio_lista": 378143,
     "precio_publico": 449990
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D008",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "702D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "712D500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722D500",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722D501",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732D001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732D500",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732D501",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D504",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D505",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D506",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D507",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D508",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D509",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D510",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752D511",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772D000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772D001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772D500",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D017",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D018",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D019",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D020",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D006",
     "precio_lista": 301680,
     "precio_publico": 359000
    },
    {
     "marca": "AMERICANINO",
     "ref": "629D001",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762D002",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762D500",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762D501",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762D502",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D020",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772D501",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D200",
     "precio_lista": 378143,
     "precio_publico": 449990
    },
    {
     "marca": "AMERICANINO",
     "ref": "812D051",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882D007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D016",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D017",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802D018",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D007",
     "precio_lista": 293277,
     "precio_publico": 379000
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D120",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D121",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D122",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D820",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D821",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D030",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D100",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D101",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D201",
     "precio_lista": 378143,
     "precio_publico": 449990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D300",
     "precio_lista": 504193,
     "precio_publico": 599990
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D520",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D025",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729D500",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729D501",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739D001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739D500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D504",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D505",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D506",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D507",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D508",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D510",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D511",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D512",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D513",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D514",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D515",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D517",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769D000",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769D001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769D500",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769D501",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D301",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "709D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "719D500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779D500",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758D500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758D501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758D502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842D018",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D202",
     "precio_lista": 378143,
     "precio_publico": 449990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D203",
     "precio_lista": 378143,
     "precio_publico": 449990
    },
    {
     "marca": "AMERICANINO",
     "ref": "829D020",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739D502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779D001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779D502",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779D503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D503",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800D005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "800D006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D010",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D011",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769D503",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D036",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608D031",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621E000",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621E002",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821E000",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821E001",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821E002",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821E003",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821E005",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "AMERICANINO",
     "ref": "606D000",
     "precio_lista": 83193,
     "precio_publico": 99000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606D001",
     "precio_lista": 88235,
     "precio_publico": 105000
    },
    {
     "marca": "AMERICANINO",
     "ref": "636D000",
     "precio_lista": 138655,
     "precio_publico": 165000
    },
    {
     "marca": "AMERICANINO",
     "ref": "726D100",
     "precio_lista": 60504,
     "precio_publico": 76000
    },
    {
     "marca": "AMERICANINO",
     "ref": "756D100",
     "precio_lista": 66387,
     "precio_publico": 79000
    },
    {
     "marca": "AMERICANINO",
     "ref": "756D101",
     "precio_lista": 61345,
     "precio_publico": 73000
    },
    {
     "marca": "AMERICANINO",
     "ref": "756D102",
     "precio_lista": 57983,
     "precio_publico": 69000
    },
    {
     "marca": "AMERICANINO",
     "ref": "846D100",
     "precio_lista": 99160,
     "precio_publico": 118000
    },
    {
     "marca": "AMERICANINO",
     "ref": "846D101",
     "precio_lista": 89916,
     "precio_publico": 107000
    },
    {
     "marca": "AMERICANINO",
     "ref": "846D102",
     "precio_lista": 101681,
     "precio_publico": 121000
    },
    {
     "marca": "AMERICANINO",
     "ref": "856D100",
     "precio_lista": 116807,
     "precio_publico": 139000
    },
    {
     "marca": "AMERICANINO",
     "ref": "856D101",
     "precio_lista": 95798,
     "precio_publico": 114000
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D013",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D014",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848D015",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818D521",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "819D521",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D008",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D009",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D010",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D011",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D012",
     "precio_lista": 293277,
     "precio_publico": 379000
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D021",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808D028",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814D001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "844D000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D003",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821E004",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D013",
     "precio_lista": 301681,
     "precio_publico": 359000
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D021",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809D023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "681E000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "726D101",
     "precio_lista": 81000,
     "precio_publico": 153000
    },
    {
     "marca": "AMERICANINO",
     "ref": "726D102",
     "precio_lista": 63529,
     "precio_publico": 120000
    },
    {
     "marca": "AMERICANINO",
     "ref": "746D100",
     "precio_lista": 30176,
     "precio_publico": 57000
    },
    {
     "marca": "AMERICANINO",
     "ref": "756D103",
     "precio_lista": 38647,
     "precio_publico": 73000
    },
    {
     "marca": "AMERICANINO",
     "ref": "756D104",
     "precio_lista": 39706,
     "precio_publico": 75000
    },
    {
     "marca": "AMERICANINO",
     "ref": "756D105",
     "precio_lista": 38647,
     "precio_publico": 73000
    },
    {
     "marca": "AMERICANINO",
     "ref": "756D106",
     "precio_lista": 38647,
     "precio_publico": 73000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D204",
     "precio_lista": 445370,
     "precio_publico": 529990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D205",
     "precio_lista": 394950,
     "precio_publico": 469990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D302",
     "precio_lista": 394950,
     "precio_publico": 469990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D303",
     "precio_lista": 352933,
     "precio_publico": 419990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D304",
     "precio_lista": 352933,
     "precio_publico": 419990
    },
    {
     "marca": "AMERICANINO",
     "ref": "621E001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "688D020",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "888D005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D022",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889D023",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D000",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D001",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D002",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D003",
     "precio_lista": 369739,
     "precio_publico": 439990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D004",
     "precio_lista": 243689,
     "precio_publico": 289990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D005",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D006",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D007",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D008",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D009",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D010",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D011",
     "precio_lista": 369739,
     "precio_publico": 439990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D012",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D013",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725D014",
     "precio_lista": 369739,
     "precio_publico": 439990
    },
    {
     "marca": "AMERICANINO",
     "ref": "848E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "859D005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997D005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609D032",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "311E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821E015",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D038",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849D039",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D206",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D207",
     "precio_lista": 504193,
     "precio_publico": 599990
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D305",
     "precio_lista": 394950,
     "precio_publico": 469900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D306",
     "precio_lista": 378143,
     "precio_publico": 449900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D014",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D015",
     "precio_lista": 343697,
     "precio_publico": 409000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D016",
     "precio_lista": 503361,
     "precio_publico": 599000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D017",
     "precio_lista": 318487,
     "precio_publico": 379000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D018",
     "precio_lista": 276471,
     "precio_publico": 299000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D019",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D020",
     "precio_lista": 326891,
     "precio_publico": 389000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D021",
     "precio_lista": 402521,
     "precio_publico": 479000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D022",
     "precio_lista": 478151,
     "precio_publico": 569000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D023",
     "precio_lista": 360504,
     "precio_publico": 429000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D024",
     "precio_lista": 293277,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "604D001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604D002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "804D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E020",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "844D001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D518",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D520",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D521",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D522",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759D523",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801E004",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D102",
     "precio_lista": 352101,
     "precio_publico": 419000
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E514",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E513",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E511",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E505",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E504",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E503",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721E501",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721E500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "711E500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "701E000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771E500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761E502",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761E501",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761E500",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761E003",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761E002",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761E001",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761E000",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E510",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E509",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E508",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E506",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731E500",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "422E001",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771E501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771E001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731E501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731E000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822E005",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822E004",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822E003",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822E002",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822E000",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751E507",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731E001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682E004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "622E000",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E010",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "422E002",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "892E000",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "423E005",
     "precio_lista": 291124,
     "precio_publico": 549900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811E021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "811E020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D026",
     "precio_lista": 360504,
     "precio_publico": 429000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D025",
     "precio_lista": 293277,
     "precio_publico": 349000
    },
    {
     "marca": "AMERICANINO",
     "ref": "882E003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841E012",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E020",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601E018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "481E000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D925",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D924",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D923",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D922",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D921",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "530D920",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785D208",
     "precio_lista": 378143,
     "precio_publico": 449990
    },
    {
     "marca": "AMERICANINO",
     "ref": "808E001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E705",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E704",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E605",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E604",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E502",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "538E501",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E509",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E508",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E507",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E506",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E505",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E504",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E503",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E502",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E501",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E500",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771E002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731E503",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731E502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E002",
     "precio_lista": 326891,
     "precio_publico": 389000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E001",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E000",
     "precio_lista": 242857,
     "precio_publico": 289000
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "462E001",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762E502",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762E501",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762E500",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762E002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762E001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762E000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E510",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E508",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E506",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E505",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E502",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E514",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E513",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E512",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E511",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E507",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E504",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E503",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E501",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E500",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808E022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808E021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808E020",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802E021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772E500",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772E001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772E000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752E515",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722E501",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722E500",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "712E500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "702E000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772E002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742E008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "742E007",
     "precio_lista": 100840,
     "precio_publico": 120000
    },
    {
     "marca": "AMERICANINO",
     "ref": "742E006",
     "precio_lista": 152941,
     "precio_publico": 182000
    },
    {
     "marca": "AMERICANINO",
     "ref": "742E005",
     "precio_lista": 84874,
     "precio_publico": 101000
    },
    {
     "marca": "AMERICANINO",
     "ref": "742E004",
     "precio_lista": 158824,
     "precio_publico": 189000
    },
    {
     "marca": "AMERICANINO",
     "ref": "742E003",
     "precio_lista": 152941,
     "precio_publico": 182000
    },
    {
     "marca": "AMERICANINO",
     "ref": "742E002",
     "precio_lista": 82353,
     "precio_publico": 98000
    },
    {
     "marca": "AMERICANINO",
     "ref": "742E001",
     "precio_lista": 80672,
     "precio_publico": 96000
    },
    {
     "marca": "AMERICANINO",
     "ref": "742E000",
     "precio_lista": 57143,
     "precio_publico": 68000
    },
    {
     "marca": "AMERICANINO",
     "ref": "722E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "820E000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "620E000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732E001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732E000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "AMERICANINO",
     "ref": "686E506",
     "precio_lista": 142773,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "686E505",
     "precio_lista": 100756,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "686E504",
     "precio_lista": 83950,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "186E500",
     "precio_lista": 117563,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E525",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E524",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E523",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E522",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E521",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E027",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E026",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812E025",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772E501",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772E502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732E502",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732E501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732E500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818E521",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "AMERICANINO",
     "ref": "818E020",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842E050",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829E007",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829E006",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829E004",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829E001",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602E050",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E516",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E515",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E514",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E513",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E512",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E511",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E510",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889E008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889E002",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E005",
     "precio_lista": 360504,
     "precio_publico": 429000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E004",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E003",
     "precio_lista": 410924,
     "precio_publico": 489000
    },
    {
     "marca": "AMERICANINO",
     "ref": "689E005",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758E517",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "884E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "844E000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "804E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "684E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604E001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604E000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889E006",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "AMERICANINO",
     "ref": "699E000",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829E002",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629E006",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629E005",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "629E001",
     "precio_lista": 211712,
     "precio_publico": 399900
    },
    {
     "marca": "AMERICANINO",
     "ref": "483E002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889E003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829E005",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E016",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "725E005",
     "precio_lista": 394950,
     "precio_publico": 469990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725E004",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725E003",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725E002",
     "precio_lista": 394950,
     "precio_publico": 469990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725E001",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "725E000",
     "precio_lista": 336126,
     "precio_publico": 399990
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E020",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "809E003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E050",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E051",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "889E030",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E204",
     "precio_lista": 259664,
     "precio_publico": 309000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E203",
     "precio_lista": 356303,
     "precio_publico": 424000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E202",
     "precio_lista": 356303,
     "precio_publico": 424000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E201",
     "precio_lista": 356303,
     "precio_publico": 424000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E200",
     "precio_lista": 356303,
     "precio_publico": 424000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E007",
     "precio_lista": 335294,
     "precio_publico": 399000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E006",
     "precio_lista": 318487,
     "precio_publico": 379000
    },
    {
     "marca": "AMERICANINO",
     "ref": "483E003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "829E000",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E500",
     "precio_lista": 242857,
     "precio_publico": 289000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E013",
     "precio_lista": 377311,
     "precio_publico": 449000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E012",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E011",
     "precio_lista": 276471,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E010",
     "precio_lista": 310084,
     "precio_publico": 369000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E009",
     "precio_lista": 310084,
     "precio_publico": 369000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E008",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "848E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "689E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997E001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E015",
     "precio_lista": 293277,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E014",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "8197029",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E508",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E507",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E506",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E505",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E504",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E503",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779E500",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779E000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769E503",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769E502",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769E501",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769E500",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769E004",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769E003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769E002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769E001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "769E000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739E502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739E501",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739E500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739E001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "739E000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729E500",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729E002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729E001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "719E501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "719E500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "709E001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "709E000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "779E501",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "759E509",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "729E501",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "849E040",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848E040",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "609E040",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608E040",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "844E001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814E000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "804E001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614E000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604E003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604E002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E017",
     "precio_lista": 310084,
     "precio_publico": 369000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E016",
     "precio_lista": 259664,
     "precio_publico": 309000
    },
    {
     "marca": "AMERICANINO",
     "ref": "997E003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997E002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F008",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "881F003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821F020",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821F000",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F020",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "691F000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "AMERICANINO",
     "ref": "621F001",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "821F001",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E205",
     "precio_lista": 335294,
     "precio_publico": 399000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E020",
     "precio_lista": 377311,
     "precio_publico": 449000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E019",
     "precio_lista": 310084,
     "precio_publico": 369000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E018",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E021",
     "precio_lista": 335294,
     "precio_publico": 399000
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "841F026",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848E041",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "856E002",
     "precio_lista": 126050,
     "precio_publico": 150000
    },
    {
     "marca": "AMERICANINO",
     "ref": "856E001",
     "precio_lista": 139496,
     "precio_publico": 166000
    },
    {
     "marca": "AMERICANINO",
     "ref": "856E000",
     "precio_lista": 139496,
     "precio_publico": 166000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E018",
     "precio_lista": 139496,
     "precio_publico": 166000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E017",
     "precio_lista": 139496,
     "precio_publico": 166000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E016",
     "precio_lista": 139496,
     "precio_publico": 166000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E015",
     "precio_lista": 114286,
     "precio_publico": 136000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E014",
     "precio_lista": 114286,
     "precio_publico": 136000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E013",
     "precio_lista": 114286,
     "precio_publico": 136000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E012",
     "precio_lista": 114286,
     "precio_publico": 136000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E011",
     "precio_lista": 126050,
     "precio_publico": 150000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E010",
     "precio_lista": 126050,
     "precio_publico": 150000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E009",
     "precio_lista": 126050,
     "precio_publico": 150000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E008",
     "precio_lista": 126050,
     "precio_publico": 150000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E007",
     "precio_lista": 126050,
     "precio_publico": 150000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E006",
     "precio_lista": 126050,
     "precio_publico": 150000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E005",
     "precio_lista": 126050,
     "precio_publico": 150000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E004",
     "precio_lista": 126050,
     "precio_publico": 150000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E003",
     "precio_lista": 126050,
     "precio_publico": 150000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E002",
     "precio_lista": 121008,
     "precio_publico": 144000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E001",
     "precio_lista": 121008,
     "precio_publico": 144000
    },
    {
     "marca": "AMERICANINO",
     "ref": "606E000",
     "precio_lista": 121008,
     "precio_publico": 144000
    },
    {
     "marca": "AMERICANINO",
     "ref": "601F017",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "786E622",
     "precio_lista": 277311,
     "precio_publico": 330000
    },
    {
     "marca": "AMERICANINO",
     "ref": "786E621",
     "precio_lista": 354622,
     "precio_publico": 422000
    },
    {
     "marca": "AMERICANINO",
     "ref": "786E620",
     "precio_lista": 321849,
     "precio_publico": 383000
    },
    {
     "marca": "AMERICANINO",
     "ref": "786E619",
     "precio_lista": 356303,
     "precio_publico": 424000
    },
    {
     "marca": "AMERICANINO",
     "ref": "786E617",
     "precio_lista": 309244,
     "precio_publico": 368000
    },
    {
     "marca": "AMERICANINO",
     "ref": "786E616",
     "precio_lista": 309244,
     "precio_publico": 368000
    },
    {
     "marca": "AMERICANINO",
     "ref": "786E615",
     "precio_lista": 285714,
     "precio_publico": 340000
    },
    {
     "marca": "AMERICANINO",
     "ref": "786E614",
     "precio_lista": 297479,
     "precio_publico": 354000
    },
    {
     "marca": "AMERICANINO",
     "ref": "786E623",
     "precio_lista": 285714,
     "precio_publico": 340000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E208",
     "precio_lista": 326891,
     "precio_publico": 389000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E207",
     "precio_lista": 326891,
     "precio_publico": 389000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E206",
     "precio_lista": 356303,
     "precio_publico": 424000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E025",
     "precio_lista": 370588,
     "precio_publico": 441000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E024",
     "precio_lista": 416807,
     "precio_publico": 496000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E023",
     "precio_lista": 335294,
     "precio_publico": 399000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E022",
     "precio_lista": 361345,
     "precio_publico": 430000
    },
    {
     "marca": "AMERICANINO",
     "ref": "822F003",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "AMERICANINO",
     "ref": "882F004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "602F007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822F001",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E212",
     "precio_lista": 276471,
     "precio_publico": 329000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E211",
     "precio_lista": 310084,
     "precio_publico": 369000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E210",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785E209",
     "precio_lista": 242857,
     "precio_publico": 289000
    },
    {
     "marca": "AMERICANINO",
     "ref": "821F004",
     "precio_lista": 158771,
     "precio_publico": 299900
    },
    {
     "marca": "AMERICANINO",
     "ref": "822F002",
     "precio_lista": 264653,
     "precio_publico": 499900
    },
    {
     "marca": "AMERICANINO",
     "ref": "682F003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "884E001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "844E002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814E001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771F502",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771F501",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "771F001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751F520",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751F519",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751F518",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751F517",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751F516",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751F515",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751F514",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751F513",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751F512",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "751F000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721F503",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721F502",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721F003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "721F002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "711F502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "711F501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "701F002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "701F001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "684E001",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "614E001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604E005",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761F504",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761F503",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761F502",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761F004",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761F003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "761F002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731F503",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731F502",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731F003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "731F004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "612F005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "618F003",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "608F006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "801F050",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758F503",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758F502",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758F500",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758F501",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758F505",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "758F504",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F005",
     "precio_lista": 364706,
     "precio_publico": 434000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F004",
     "precio_lista": 335294,
     "precio_publico": 399000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F003",
     "precio_lista": 318487,
     "precio_publico": 379000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F002",
     "precio_lista": 356303,
     "precio_publico": 424000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F001",
     "precio_lista": 402521,
     "precio_publico": 479000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F000",
     "precio_lista": 402521,
     "precio_publico": 479000
    },
    {
     "marca": "AMERICANINO",
     "ref": "848F025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F504",
     "precio_lista": 259664,
     "precio_publico": 309000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F503",
     "precio_lista": 410924,
     "precio_publico": 489000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F502",
     "precio_lista": 293277,
     "precio_publico": 349000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F501",
     "precio_lista": 326891,
     "precio_publico": 389000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F500",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F200",
     "precio_lista": 427731,
     "precio_publico": 509000
    },
    {
     "marca": "AMERICANINO",
     "ref": "848F027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "848F026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F028",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "802F020",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F202",
     "precio_lista": 377311,
     "precio_publico": 449000
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F201",
     "precio_lista": 402521,
     "precio_publico": 479000
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F030",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F029",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F527",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "812F027",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "808F009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997F500",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "997F001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "842F031",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F509",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F508",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F507",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F506",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F505",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F504",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F503",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F502",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F501",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F500",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F001",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F000",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722F501",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722F500",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "722F000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762F502",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762F501",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762F500",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762F001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "762F000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F511",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "752F510",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772F500",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "772F000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732F501",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732F500",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732F000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "732F001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "AMERICANINO",
     "ref": "884F000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "AMERICANINO",
     "ref": "844F000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "814F000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "AMERICANINO",
     "ref": "684F000",
     "precio_lista": 105829,
     "precio_publico": 199900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604F001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "604F000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "AMERICANINO",
     "ref": "785F006",
     "precio_lista": 226050,
     "precio_publico": 269000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F038",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645F103",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645F102",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645F101",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645F100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F037",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F026",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F050",
     "precio_lista": 121712,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715F000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715F001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F050",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "644F006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "644F005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "644F004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "644F003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "644F002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "644F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704F012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704F011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704F013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704F010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709F903",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709F902",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709F901",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709F900",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649F904",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649F903",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649F902",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649F901",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649F900",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689F900",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629F901",
     "precio_lista": 312300,
     "precio_publico": 589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629F900",
     "precio_lista": 275241,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639F900",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6510000",
     "precio_lista": 62946,
     "precio_publico": 82000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "284A400",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "284A401",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "310E800",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "310E801",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3116001",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311C102",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311C103",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311E001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311E003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311F000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "311F001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312D002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312E000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312E001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312E002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312F000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3138001",
     "precio_lista": 89947,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3138002",
     "precio_lista": 84653,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "313A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "313A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "313A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314A001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314A002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314A006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314A007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314A008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314A403",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314A404",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314D100",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314D200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314D201",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "315E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "315F000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3195001",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3198000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3198001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3198002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3198003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3198004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319B001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319B002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319B004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319D001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319D002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319D003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319D004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319E000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319E001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319E002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319E003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "319E920",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3204000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3208000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3208001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "320E000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "320E001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321B000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321B001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321C000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321C001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321D000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321D001",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321E000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321E001",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321E450",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321F000",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321F001",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "321F450",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3228000",
     "precio_lista": 147124,
     "precio_publico": 277900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322A000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322A001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322B000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322B200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322C000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322C001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322D000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322D001",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322E000",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322E002",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322E450",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322F000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322F001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3238000",
     "precio_lista": 132300,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "323A000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324A200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324A400",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324A401",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324A402",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324C200",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324C201",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324C900",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324D100",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324D200",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324D201",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324D771",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324D777",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "324D900",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3298001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3298004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329A000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329A001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329B000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329B003",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329B004",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329C000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329C001",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329C002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329D000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329D001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329D002",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329E000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329E001",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329E002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329E003",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329E450",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "329E920",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3300000",
     "precio_lista": 73265,
     "precio_publico": 95000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3300001",
     "precio_lista": 73265,
     "precio_publico": 134900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3300002",
     "precio_lista": 73265,
     "precio_publico": 134900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3301000",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3301001",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3301002",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3301003",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3301004",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3301005",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3304000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3304001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3304002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3304003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3304004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3304005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3304006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3304013",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3304014",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3308000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3308001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3308002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3308003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3308004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3308008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3308009",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3308010",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3308011",
     "precio_lista": 21773,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3308012",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330C013",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330C014",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330C015",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330C016",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330C017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330D017",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330D018",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330D022",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330D028",
     "precio_lista": 158251,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E003",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E005",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E006",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E007",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E009",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E116",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E122",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E216",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "330E222",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3310014",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3312007",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3313035",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3314009",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3314012",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3314013",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3314022",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3314030",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3315012",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3315017",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3316020",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3316032",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3317000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3317006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3317007",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3317015",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3317020",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318012",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318013",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318014",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318016",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318129",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A006",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A009",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A011",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A017",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A018",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A019",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A021",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A022",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A025",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A030",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A135",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331A139",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B006",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B013",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B014",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B016",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B017",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B018",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B019",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B021",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B022",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B023",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B024",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B025",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B026",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B027",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B028",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B029",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B030",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B031",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B032",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331B033",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C008",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C010",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C011",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C012",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C013",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C014",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C015",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C016",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C017",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C018",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C019",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C020",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C021",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C022",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C023",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C024",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C025",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C027",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C028",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C029",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C030",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C031",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C032",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C033",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C083",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C084",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C128",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331C129",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D002",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D007",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D008",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D011",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D012",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D013",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D014",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D015",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D016",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D017",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D018",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D019",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D020",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D021",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D022",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D023",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D024",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D025",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D026",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D027",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D028",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D029",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D030",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D031",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D032",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D033",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D034",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D037",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D038",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D039",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331D040",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E006",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E007",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E009",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E010",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E011",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E012",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E013",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E014",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E015",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E016",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E017",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E018",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E019",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E020",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E021",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E022",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E023",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E024",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E025",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E026",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E027",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E028",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E450",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E451",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E452",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E454",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331E455",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F003",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F005",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F006",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F007",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F008",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F009",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F010",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F011",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F012",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F013",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F014",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F015",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F016",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F017",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F018",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F019",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F020",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F021",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F022",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F023",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F024",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F025",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F026",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F450",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F451",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F452",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F453",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F454",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "331F455",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3324000",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3325014",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3326027",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3327005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3327007",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3327013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3327019",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3327025",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3327027",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328009",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328014",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328016",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328020",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328021",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328022",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328024",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328026",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328027",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328028",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328029",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328032",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328034",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3328035",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A007",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A010",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A012",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A013",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A014",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A016",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A017",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A018",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A019",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A023",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A026",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A031",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A032",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A034",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A035",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A036",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A037",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332A038",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B010",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B013",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B014",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B015",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B016",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B017",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B018",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B019",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B021",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B022",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B023",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B024",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B025",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B026",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B027",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B028",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B030",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B031",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B032",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B033",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B034",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B200",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B201",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332B202",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C009",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C010",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C012",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C013",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C014",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C015",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C016",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C017",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C018",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C019",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C020",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C021",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C022",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C023",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C024",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C025",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C026",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C027",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C028",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C029",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C030",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C031",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C032",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C033",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C034",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C035",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C036",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C037",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C038",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332C039",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D005",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D006",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D007",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D008",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D009",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D010",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D011",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D012",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D013",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D014",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D015",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D016",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D017",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D018",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D019",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D020",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D021",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D022",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D023",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D024",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D025",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D026",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D027",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D028",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D029",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D030",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D031",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D032",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332D033",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E000",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E001",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E003",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E004",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E005",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E006",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E007",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E009",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E010",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E012",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E013",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E014",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E015",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E016",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E017",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E018",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E019",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E020",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E021",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E022",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E023",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E024",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E026",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E028",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E029",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E030",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E031",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E032",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E033",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E450",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E451",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E452",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E453",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332E454",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F005",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F007",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F008",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F009",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F010",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F012",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F013",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F015",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F017",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F018",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F019",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F020",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F021",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F022",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F024",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F025",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F026",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F027",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F028",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F029",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F030",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F031",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F032",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F033",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F034",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F035",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F450",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F451",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F453",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F454",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "332F455",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3333005",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3333006",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3333030",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3334000",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3335012",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3335032",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3336002",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3336006",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3336007",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3336030",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3337008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3337011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3337026",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338020",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338028",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338037",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338038",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338040",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338046",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338048",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338053",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338057",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338060",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338061",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338063",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3338064",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A007",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A009",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A010",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A013",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A016",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A017",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A018",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A019",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A021",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A022",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A023",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A026",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A027",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A029",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A030",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A031",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A032",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A033",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A034",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A035",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A036",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A037",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A038",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "333A039",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3347102",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3348302",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334A104",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334A205",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334A401",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334A402",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334B100",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334B102",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334B104",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334B105",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C100",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C101",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C102",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C103",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C104",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C201",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C204",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C205",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C206",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C900",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C901",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C902",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C903",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C904",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C905",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334C907",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D100",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D101",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D102",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D103",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D104",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D105",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D200",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D201",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D202",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D203",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D204",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D900",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D901",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D902",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D903",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D904",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D905",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "334D906",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3357013",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3357020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3357021",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3357022",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3357027",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B007",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B009",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B010",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B013",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B016",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B017",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B018",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B019",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B020",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B021",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B022",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B023",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B024",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B025",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B026",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B027",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B028",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335B029",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C230",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C231",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C232",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C233",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C234",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C235",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C236",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C237",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C238",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C240",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C241",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C242",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C243",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C244",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C245",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C246",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C248",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C249",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C250",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C251",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C252",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C253",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C254",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C255",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C256",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C258",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C259",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C260",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C261",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C262",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C263",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C264",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C265",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C266",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C267",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335C268",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D012",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D013",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D014",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D015",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D016",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D017",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D018",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D019",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D020",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D021",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D022",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D023",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D024",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D025",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D026",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D027",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D028",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D029",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D031",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D033",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D034",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D035",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D036",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D037",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335D038",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E005",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E007",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E008",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E009",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E010",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E011",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E012",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E013",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E014",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E015",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E016",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E017",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E018",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E019",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E020",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E021",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335E022",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335F000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335F001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335F002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335F003",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "335F004",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3390036",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3391049",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3391051",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3392001",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3392025",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3393004",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3393005",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3393035",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3394002",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3394028",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3394030",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3394032",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3394034",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3394043",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3394044",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395001",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395002",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395003",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395004",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395007",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395011",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395015",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395020",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395028",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395030",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395036",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395039",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3395040",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3396011",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3396017",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3396028",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3396033",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3396036",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3397003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3397009",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3397017",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3397020",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3397036",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398010",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398011",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398012",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398013",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398014",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398015",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398016",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398017",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398018",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398019",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398022",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398023",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398024",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398025",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398026",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398027",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398028",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398029",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398030",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398031",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398032",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398033",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398035",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398037",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398039",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398042",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398044",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398045",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398047",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398048",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398049",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398051",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398053",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398057",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398058",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398059",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398060",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398061",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398062",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398063",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398064",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398065",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398066",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398067",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398073",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398468",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398479",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A006",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A007",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A009",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A011",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A013",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A014",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A016",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A017",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A018",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A019",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A020",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A021",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A022",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A023",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A024",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A025",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A026",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A027",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A028",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A029",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A031",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A032",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A033",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A034",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A035",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A036",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A037",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A038",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A039",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A040",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A041",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A042",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A043",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A044",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A045",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A046",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A047",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A048",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A049",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A050",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A051",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A052",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A053",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A054",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A055",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A056",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A057",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339A061",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B007",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B010",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B011",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B014",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B022",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B023",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B026",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B027",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B029",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B031",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B033",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B034",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B035",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B036",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B037",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B041",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B045",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B046",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B047",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B048",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B055",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B056",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B057",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B058",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B059",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B060",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B061",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B062",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B063",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B064",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B065",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B066",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B067",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B068",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B069",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B070",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B071",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B073",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B074",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B076",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B078",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B079",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B080",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B081",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B082",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B083",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B084",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B085",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B086",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339B487",
     "precio_lista": 117418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C006",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C007",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C008",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C009",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C011",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C012",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C013",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C014",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C015",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C016",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C017",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C018",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C019",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C020",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C021",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C022",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C023",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C024",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C025",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C026",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C027",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C028",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C029",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C030",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C031",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C032",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C033",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C034",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C035",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C036",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C037",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C038",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C039",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C040",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C041",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C042",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C043",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C044",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C445",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339C446",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D002",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D004",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D005",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D006",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D007",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D009",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D010",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D011",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D012",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D013",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D014",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D015",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D016",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D017",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D018",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D019",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D020",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D021",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D022",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D023",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D024",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D025",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D026",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D027",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D028",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D029",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D030",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D031",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D032",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D033",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D034",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D035",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D036",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D037",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D038",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D039",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D040",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D041",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D042",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D043",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D044",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D045",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D047",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D048",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339D049",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E004",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E005",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E006",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E007",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E008",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E009",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E010",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E011",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E012",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E013",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E014",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E015",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E016",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E017",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E018",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E019",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E020",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E021",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E022",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E023",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E024",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E025",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E026",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E027",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E028",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E029",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E030",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E031",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E032",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E033",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E034",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E035",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E036",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E037",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E038",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E450",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E451",
     "precio_lista": 306373,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E452",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E453",
     "precio_lista": 304067,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E454",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E455",
     "precio_lista": 294763,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "339E456",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "350C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "350C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "350E000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "350E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3515000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3518001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351E002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351E003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351F001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351F002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "351F003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352D004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352D006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352E003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352F001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352F002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "352F003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3538000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3538001",
     "precio_lista": 79359,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "353A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "353A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355C202",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355C203",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355C204",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355C206",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355D005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355D007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355D008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "355F000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3597000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359B005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359C004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359C005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359C706",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359D003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359D005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359D006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359E002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "359E004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411C102",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411C103",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411E000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411E002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411F000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "411F001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4126000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412E000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412E001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412E002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412F001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4137000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4138001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "413A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "414A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "414A001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "414A002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "414A006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "414A007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "414A008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "414D100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "414D250",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4197001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419D001",
     "precio_lista": 107416,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419E002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4204000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4208000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4208001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "420E000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "420E001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4218000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421B000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421B001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421B002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421D000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421E002",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421E103",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421E104",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421F000",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421F001",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421F002",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421F003",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421F004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422B000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422B200",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422C000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422C001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422D000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422D001",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422D002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422D003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422D004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422E000",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422E001",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422E002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422F001",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422F002",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4237002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "423A002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "424A400",
     "precio_lista": 418182,
     "precio_publico": 789900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "424A402",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "424A403",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "424C250",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "424C351",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "424C650",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "424D150",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "424D250",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "425F000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4298003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4298004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429A003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429B000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429C000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429C001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429C002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429C003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429C004",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429D000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429D001",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429D002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429D003",
     "precio_lista": 218262,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429E000",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429E001",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429E003",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429E004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429E005",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429E450",
     "precio_lista": 279202,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429E920",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4300001",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4300002",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4301000",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4301001",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4301002",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4301003",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4301004",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4301005",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4304000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4304001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4304002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4304003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4304004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4304005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4304010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4308000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4308001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4308002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4308003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4308004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4308006",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430A003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430A004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430A005",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430A006",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430A007",
     "precio_lista": 123967,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430A008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430C009",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430C010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430C011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430C012",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430C013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430C014",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430C015",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430C016",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430C017",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430D019",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430E003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430E005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430E006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430E007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430E008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "430E009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4310000",
     "precio_lista": 108023,
     "precio_publico": 140000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4312018",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4313040",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4315004",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4315012",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4315024",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4316000",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4316007",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4316012",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4316016",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4317002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4317003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4317005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4317008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4317011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4317015",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318011",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318014",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318018",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318019",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318022",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318031",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318034",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A006",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A007",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A008",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A009",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A010",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A011",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A014",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A016",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A017",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A018",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A019",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A021",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A022",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A024",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A025",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A027",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A028",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A132",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431A430",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B011",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B013",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B014",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B016",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B019",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B021",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B022",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B023",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B024",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B025",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B027",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B028",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B029",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B131",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C008",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C010",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C011",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C013",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C014",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C015",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C016",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C017",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C018",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C019",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C020",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C021",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C022",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C023",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C024",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C025",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C046",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C126",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431C127",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D008",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D011",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D012",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D013",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D014",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D015",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D016",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D017",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D018",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D019",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D020",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D021",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D022",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D023",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D024",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D025",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D026",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D030",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D031",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D032",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431D033",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E006",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E010",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E011",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E013",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E014",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E016",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E017",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E018",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E019",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E020",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E021",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E022",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E023",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E024",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E025",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E026",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E027",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E028",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E029",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E030",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E031",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E032",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E033",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E034",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E035",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E134",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E135",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E136",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E237",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E238",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E239",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E450",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431E451",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F005",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F006",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F009",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F012",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F013",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F014",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F015",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F016",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F017",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F018",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F019",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F021",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F022",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F023",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F024",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F025",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F026",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F027",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F028",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F029",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F030",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F031",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F032",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F450",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431F451",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4321015",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4323025",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4325003",
     "precio_lista": 104765,
     "precio_publico": 192900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4325015",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4325019",
     "precio_lista": 104765,
     "precio_publico": 192900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4326001",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4326016",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4326024",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4326030",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4326032",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4327011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4327028",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328010",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328012",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328015",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328016",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328020",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328035",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328036",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328038",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328040",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328045",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A008",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A010",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A015",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A018",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A019",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A021",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A023",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A026",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A027",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A032",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B009",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B010",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B012",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B013",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B014",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B016",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B017",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B019",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B020",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B021",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B023",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B024",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B025",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B026",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B201",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432B202",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C011",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C012",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C013",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C014",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C015",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C016",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C017",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C018",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C019",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C020",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C021",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C022",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C023",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C024",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C025",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C026",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C027",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C028",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C029",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C030",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432C031",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D010",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D013",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D014",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D015",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D016",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D017",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D018",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D019",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D020",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D021",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D022",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D023",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D024",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D025",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D026",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D027",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D028",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D029",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D030",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D031",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D032",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D033",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D034",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D035",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D036",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D037",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432D038",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E005",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E006",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E009",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E011",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E013",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E014",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E015",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E016",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E017",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E018",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E019",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E020",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E021",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E022",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E023",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E024",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E025",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E026",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E027",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E028",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E029",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E030",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E131",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E132",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E133",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E450",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E451",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432E452",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F006",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F009",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F010",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F011",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F012",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F013",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F014",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F016",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F017",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F018",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F019",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F021",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F022",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F023",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F024",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F025",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F026",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F027",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F028",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F029",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F030",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F031",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F032",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F033",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432F035",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4331023",
     "precio_lista": 104765,
     "precio_publico": 192900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4332001",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4333020",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4333021",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4333032",
     "precio_lista": 105851,
     "precio_publico": 194900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4335010",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4335012",
     "precio_lista": 105851,
     "precio_publico": 194900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4335029",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4336011",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4336012",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4336022",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4336023",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4336033",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4337003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4337029",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338033",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338035",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338036",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338037",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338038",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338040",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338041",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338042",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338044",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338045",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338046",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338048",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338049",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338050",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338051",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338052",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338053",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338057",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338059",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4338061",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A007",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A010",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A012",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A014",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A015",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A016",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A018",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A019",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A020",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A021",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A022",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A023",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A024",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A025",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A026",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A027",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A028",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A029",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "433A031",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4343004",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4348102",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4348301",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434A402",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434A404",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434A405",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434B100",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434B101",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434B102",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C100",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C101",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C102",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C201",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C250",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C350",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C351",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C650",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C651",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C900",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C901",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434C902",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D101",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D102",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D150",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D151",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D152",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D200",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D201",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D250",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D251",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D252",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D900",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D901",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434D902",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435C209",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435C220",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D009",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D013",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D014",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D015",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D016",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D017",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D018",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435D019",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E009",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435E011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435F000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435F001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435F002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "435F003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4390006",
     "precio_lista": 103135,
     "precio_publico": 113940
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4391032",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4393015",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4393019",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4393031",
     "precio_lista": 105851,
     "precio_publico": 194900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4394004",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4394030",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4394031",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395004",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395010",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395016",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395020",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395024",
     "precio_lista": 130291,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395025",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395026",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395029",
     "precio_lista": 113997,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395035",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395036",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4395038",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4396018",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4396025",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4396026",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4396027",
     "precio_lista": 119428,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4396033",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4396037",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397019",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397021",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397023",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397024",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397028",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397029",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397030",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397031",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397036",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397041",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4397455",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398006",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398015",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398017",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398018",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398020",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398021",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398022",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398023",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398024",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398025",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398026",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398027",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398028",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398036",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398037",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398038",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398045",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398046",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398048",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398049",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398050",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398051",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398052",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398053",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398054",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398055",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398056",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398057",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398058",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398062",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398067",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398068",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398069",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398070",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398071",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398072",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398073",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398076",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398476",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398477",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398478",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4398479",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A006",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A007",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A009",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A010",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A011",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A012",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A013",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A014",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A015",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A016",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A017",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A018",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A019",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A020",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A021",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A022",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A023",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A024",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A025",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A026",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A027",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A028",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A029",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A030",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A031",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A032",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A035",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A036",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A038",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A039",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A040",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A041",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A042",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A043",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A044",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A045",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A060",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A061",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A064",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A451",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B011",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B015",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B017",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B019",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B027",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B028",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B030",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B032",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B033",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B036",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B037",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B039",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B041",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B045",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B047",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B049",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B050",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B051",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B052",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B053",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B054",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B055",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B056",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B057",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B058",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B059",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B060",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B061",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B062",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B063",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B064",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B065",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B066",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B067",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B068",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B469",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C008",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C010",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C011",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C012",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C013",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C014",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C015",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C016",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C017",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C018",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C019",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C020",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C021",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C022",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C023",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C024",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C025",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C026",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C027",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C028",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C029",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C030",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C031",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C032",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C033",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C034",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C035",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C036",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C037",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C038",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C039",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C440",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439C441",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D004",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D009",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D010",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D011",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D012",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D013",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D014",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D015",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D016",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D017",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D018",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D019",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D021",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D022",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D023",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D024",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D025",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D026",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D027",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D028",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D029",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D030",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D031",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D032",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D033",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D034",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D035",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D036",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D037",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D038",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D039",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D040",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D041",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D042",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D043",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D044",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D045",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D046",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D047",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439D048",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E005",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E007",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E009",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E010",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E011",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E013",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E014",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E015",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E016",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E017",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E018",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E019",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E020",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E021",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E022",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E023",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E024",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E025",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E026",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E027",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E028",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E029",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E030",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E031",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E032",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E033",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E034",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E035",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E450",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E451",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439E452",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441E000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441F000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "441F001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4428000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4428002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "442B001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "442C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "442C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "442D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "442D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "442E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "442E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "442F000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "442F001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "443A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "443A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4498003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449B003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449B004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449C003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449D003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "449E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451B002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451E000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451E002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451E003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451F000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451F001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "451F002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452B002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452B200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "452F002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "453A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "453A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "454C450",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "454D250",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "454D251",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4597000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459A002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459C002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459C003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459D003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "459E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "462E450",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4716000",
     "precio_lista": 103135,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "471F000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "471F001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "471F002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4735000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "504B200",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "504B201",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "504B203",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "504B204",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "504B205",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6014009",
     "precio_lista": 39592,
     "precio_publico": 72900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6015002",
     "precio_lista": 25472,
     "precio_publico": 46900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6017007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6017008",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6017010",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6017011",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6017012",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6017013",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6017019",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6017024",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6017054",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018005",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018010",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018011",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018012",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018016",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018017",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018019",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018020",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018054",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A004",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A017",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A018",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A020",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A022",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A027",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A031",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A032",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A034",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A035",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B006",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B007",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B009",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B010",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B012",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B013",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B014",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B017",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B018",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B019",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B020",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B022",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B024",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B025",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B027",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B028",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C002",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C003",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C006",
     "precio_lista": 56065,
     "precio_publico": 105900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C007",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C008",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C009",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C011",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C014",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C015",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C017",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C018",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C019",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C022",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C024",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C025",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C026",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C027",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C028",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C029",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C030",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C054",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C055",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D011",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D012",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D023",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D027",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D028",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D029",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D030",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D054",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E012",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E018",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E023",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E054",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E060",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E061",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E062",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E063",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E064",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F017",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F060",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F061",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F062",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F063",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6022012",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6023002",
     "precio_lista": 24385,
     "precio_publico": 44900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6027009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6027011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6027021",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6027022",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6027024",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028006",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028009",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028023",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028025",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028030",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028034",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028035",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A005",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A010",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A011",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A029",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A032",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A036",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A037",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A038",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A039",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A040",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B004",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B007",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B008",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B010",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B011",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B012",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B015",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B016",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B017",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B018",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B019",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B020",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B021",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B022",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B025",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B026",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B028",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B040",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B041",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B042",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B054",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C002",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C004",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C005",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C007",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C009",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C011",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C012",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C013",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C014",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C015",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C016",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C017",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C018",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C019",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C021",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C022",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C024",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C025",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C026",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C027",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C028",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C029",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C030",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C054",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D012",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D016",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D019",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D024",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D025",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D028",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D038",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D050",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D051",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D052",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D053",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E012",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E016",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E024",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E026",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F018",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F019",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F022",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6033015",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6033017",
     "precio_lista": 37420,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6034002",
     "precio_lista": 24385,
     "precio_publico": 44900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6034016",
     "precio_lista": 37420,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6034055",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6035002",
     "precio_lista": 25472,
     "precio_publico": 46900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6035009",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6035018",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6035026",
     "precio_lista": 42851,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6035051",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6036005",
     "precio_lista": 41765,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6036012",
     "precio_lista": 51541,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6037019",
     "precio_lista": 36476,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038002",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038007",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038008",
     "precio_lista": 50241,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038010",
     "precio_lista": 50241,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038015",
     "precio_lista": 41771,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038017",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038018",
     "precio_lista": 36476,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038024",
     "precio_lista": 51300,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038027",
     "precio_lista": 47594,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038031",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038032",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038033",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038034",
     "precio_lista": 51300,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038038",
     "precio_lista": 51300,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038054",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A005",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A007",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A009",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A010",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A011",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A013",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A014",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A015",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A016",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A018",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A019",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A020",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A021",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A022",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A023",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A024",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A054",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "604C400",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A018",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A019",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A020",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A021",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A022",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A024",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A025",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C000",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C002",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C012",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C017",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C018",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C019",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C200",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C201",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C202",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C203",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C204",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C205",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C212",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C300",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C301",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C302",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C303",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C304",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C305",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C314",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D100",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D101",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D102",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D103",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D104",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D105",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D106",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D107",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D108",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D109",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D110",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D111",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D112",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D113",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D114",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D115",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D116",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D117",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D118",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D119",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D120",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D121",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D122",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D127",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D233",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D234",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D235",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D238",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D239",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D241",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D242",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D253",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D254",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D900",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D901",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D902",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D903",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D904",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E100",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E101",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E102",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E103",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E104",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E105",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E106",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E107",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E109",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E110",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E111",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E112",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E113",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E114",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E116",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E117",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E118",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E119",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F101",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F102",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F103",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F105",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F106",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F107",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F108",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F109",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F110",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605F111",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6092042",
     "precio_lista": 41765,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6093002",
     "precio_lista": 24385,
     "precio_publico": 44900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6093016",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6093023",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6093043",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094002",
     "precio_lista": 25472,
     "precio_publico": 46900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094005",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094009",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094014",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094033",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094037",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094044",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094045",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094046",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094058",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094059",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094062",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095007",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095009",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095011",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095013",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095014",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095018",
     "precio_lista": 42851,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095020",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095023",
     "precio_lista": 42851,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095024",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095025",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095027",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095033",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095038",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095039",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095041",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095043",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095044",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095048",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095049",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095053",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095057",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6096005",
     "precio_lista": 51541,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6096008",
     "precio_lista": 51541,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6096021",
     "precio_lista": 51541,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6096040",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6096054",
     "precio_lista": 52627,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6096057",
     "precio_lista": 27101,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6097010",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6097013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6097023",
     "precio_lista": 36476,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6097024",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6097042",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6097044",
     "precio_lista": 36476,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098004",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098005",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098007",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098011",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098018",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098020",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098022",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098024",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098027",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098028",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098029",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098031",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098032",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098033",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098038",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098039",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098040",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098041",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098043",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098045",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098046",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098048",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098050",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098051",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098052",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098054",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098056",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098060",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098068",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098070",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098073",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A003",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A004",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A007",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A008",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A010",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A011",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A012",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A017",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A018",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A019",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A020",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A021",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A022",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A023",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A024",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A025",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A027",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A028",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A030",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A031",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A033",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A034",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A035",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A036",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A037",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A038",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A039",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A040",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A041",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A042",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A043",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A044",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A045",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B005",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B006",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B007",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B009",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B010",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B011",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B013",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B014",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B017",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B018",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B025",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B026",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B027",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B040",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B044",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B045",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B048",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B054",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B055",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B056",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B057",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B058",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B059",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B060",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B061",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B062",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B063",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B064",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B065",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B066",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B067",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B068",
     "precio_lista": 54476,
     "precio_publico": 102900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B069",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B070",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B072",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C002",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C004",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C015",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C016",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C017",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C018",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C019",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C020",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C021",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C022",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C023",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C024",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C025",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C026",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C027",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C028",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C029",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C030",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C031",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C033",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C034",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C035",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C036",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C052",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C053",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C054",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C055",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C056",
     "precio_lista": 60829,
     "precio_publico": 114900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C057",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C058",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D016",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D024",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D026",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D027",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D028",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D029",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D032",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D035",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D036",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D037",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D041",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D042",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D043",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D045",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D048",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D050",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D051",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D054",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D055",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D056",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D057",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D058",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D059",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D060",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D061",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E016",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E018",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E019",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E024",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E026",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E027",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E028",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E029",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E030",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E031",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E032",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E033",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E039",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E041",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E043",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6104100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6108002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6108400",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "610B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "610B003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "610E026",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "610E027",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6114016",
     "precio_lista": 77609,
     "precio_publico": 142900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6114019",
     "precio_lista": 70006,
     "precio_publico": 128900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6114021",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6114026",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6114028",
     "precio_lista": 77609,
     "precio_publico": 142900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6115001",
     "precio_lista": 63489,
     "precio_publico": 116900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6115002",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6115004",
     "precio_lista": 70006,
     "precio_publico": 128900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6115012",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6115016",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6115033",
     "precio_lista": 74351,
     "precio_publico": 136900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6116018",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6116023",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6117000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6117005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6118001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6118002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6118003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6118004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6118005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6118009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6118011",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6118019",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6118020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6118025",
     "precio_lista": 80947,
     "precio_publico": 152900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A014",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A022",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A024",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A027",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A030",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A032",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A034",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A137",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B015",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B016",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B017",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B025",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B026",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B027",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B028",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B030",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B031",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B032",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B033",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B034",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B035",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B036",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B037",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B063",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C015",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C026",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C027",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C028",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C029",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C030",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C031",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C032",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C034",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C035",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C036",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C037",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C038",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C039",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C063",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D012",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D014",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D016",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D017",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D018",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D019",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D020",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D025",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D026",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D027",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D028",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D029",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D030",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D031",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D032",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E009",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E011",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E012",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E013",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E014",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E015",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E016",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E017",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E018",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E019",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E020",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E021",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E022",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E026",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E027",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E030",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E031",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F007",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F009",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F010",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F011",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F012",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F014",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F015",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F017",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F018",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F019",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F020",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F022",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F023",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F024",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F025",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F026",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F027",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F028",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F029",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F030",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F032",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6121009",
     "precio_lista": 74351,
     "precio_publico": 136900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6121010",
     "precio_lista": 74351,
     "precio_publico": 136900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6123006",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6126006",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6126007",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6126021",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6126023",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6126033",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6127001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6127003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6127006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6127007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6127008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128029",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128030",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128032",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128033",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128242",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A022",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A024",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A027",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A034",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B015",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B016",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B017",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B019",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B020",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B022",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B023",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B024",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B025",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B027",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B028",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B029",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B030",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B032",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B036",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B040",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B042",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B043",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B050",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C015",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C017",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C018",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C019",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C020",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C021",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C022",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C023",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C024",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C025",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C026",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C027",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C028",
     "precio_lista": 102124,
     "precio_publico": 192900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C034",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C035",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C036",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C037",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C038",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C039",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C040",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C041",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C063",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D015",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D016",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D017",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D018",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D019",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D020",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D021",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D023",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D025",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D026",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D027",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D028",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D029",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D035",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D036",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D038",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D042",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D043",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D044",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E007",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E009",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E011",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E012",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E014",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E016",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E017",
     "precio_lista": 126368,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E018",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E019",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E023",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E026",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E027",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E032",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E033",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E034",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E035",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E036",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E037",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F010",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F011",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F012",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F013",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F014",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F016",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F017",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F018",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F019",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F020",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F021",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F026",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F027",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F029",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F030",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F031",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6132022",
     "precio_lista": 74351,
     "precio_publico": 136900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6133014",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6135001",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6135012",
     "precio_lista": 86299,
     "precio_publico": 158900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6135023",
     "precio_lista": 70006,
     "precio_publico": 128900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6136016",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6136019",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6137008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138005",
     "precio_lista": 74065,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138016",
     "precio_lista": 79359,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138026",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138030",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A024",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A025",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A027",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A028",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A029",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A031",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A032",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A033",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A034",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A035",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A037",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A038",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A039",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "613A040",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "614A400",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "614A401",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615A006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C200",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D200",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D201",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D202",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D203",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D222",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D223",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D224",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D225",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D226",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E100",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E101",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E102",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E103",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E104",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E105",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E106",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E107",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E108",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E110",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E111",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E112",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E113",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E114",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E115",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E116",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E118",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E120",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6190004",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6190008",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6191024",
     "precio_lista": 86299,
     "precio_publico": 158900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6193040",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6193041",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6194001",
     "precio_lista": 63489,
     "precio_publico": 116900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6194003",
     "precio_lista": 79239,
     "precio_publico": 145900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6194019",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6194032",
     "precio_lista": 70006,
     "precio_publico": 128900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6194036",
     "precio_lista": 70006,
     "precio_publico": 128900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6194047",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6194048",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6194051",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6194058",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6195012",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6195021",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6195028",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6195036",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6195039",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6195047",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6195048",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6195049",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6195055",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6196000",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6196001",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6196006",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6196014",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6196025",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6196033",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6196038",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6196048",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197016",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197018",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197022",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197037",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6197052",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198020",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198030",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198033",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198038",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198040",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198041",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198042",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198045",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198047",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198048",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198049",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198053",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198056",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198058",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198059",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198060",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198062",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198065",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A022",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A024",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A027",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A028",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A029",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A030",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A031",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A032",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A033",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A034",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A035",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A036",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A037",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A038",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A039",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A040",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A042",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A043",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A044",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A045",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A046",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A047",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A048",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A049",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A050",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A051",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A052",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A053",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A054",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A055",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A058",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A059",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A060",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A061",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A062",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A063",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B016",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B027",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B032",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B035",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B038",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B052",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B063",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B067",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B070",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B071",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B072",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B073",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B074",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B075",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B077",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B079",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B080",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B082",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B083",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B085",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B086",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B087",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B089",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B090",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B092",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B094",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B095",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B096",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B097",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B099",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C014",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C015",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C017",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C018",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C019",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C020",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C021",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C022",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C024",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C025",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C026",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C027",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C028",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C029",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C030",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C031",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C033",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C035",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C036",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C037",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C044",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C047",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C048",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C049",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C050",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C063",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D007",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D011",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D014",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D015",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D016",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D017",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D018",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D019",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D020",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D021",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D022",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D023",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D024",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D025",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D026",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D027",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D028",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D029",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D030",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D031",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D032",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D033",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D034",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D036",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D037",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D039",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D041",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D043",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D049",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E007",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E008",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E009",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E010",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E011",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E013",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E014",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E015",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E016",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E017",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E018",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E020",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E021",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E022",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E023",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E024",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E025",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E026",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E027",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E028",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E029",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E030",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E031",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E033",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E035",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E036",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E037",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E041",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E042",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E043",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E044",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E045",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E047",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E048",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621A004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621C001",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621C003",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621C004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621C007",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D005",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D006",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D007",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D011",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621E004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621E005",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621E007",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621E008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621E009",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621E060",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F003",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F004",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F005",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F009",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6228006",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6228009",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622A005",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622B008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622B009",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622C007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622C009",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D006",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D007",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D010",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D050",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E001",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E003",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E005",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E009",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E010",
     "precio_lista": 248771,
     "precio_publico": 469900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E011",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E013",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F000",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F003",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F006",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F010",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F011",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F012",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6233001",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6235050",
     "precio_lista": 135722,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6237006",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6237008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6238004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "623A005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "623A006",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "623A009",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "623A010",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625E101",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625E102",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625E103",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625E104",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625E105",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625E107",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625E108",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "626B200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6297008",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6298012",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629A004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629A013",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629A014",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C003",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C007",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C009",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D002",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D008",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D009",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D011",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D013",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D014",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D016",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E002",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E007",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E009",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E060",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E900",
     "precio_lista": 312300,
     "precio_publico": 589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6301000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6301001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6301002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6306000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6306001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6308000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6308001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6316000",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6317000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6318000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6318001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6318003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631A020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631B001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631B002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631B003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631B005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631B006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631B007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631E000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631E001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631E002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631E003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631E004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631E005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631E006",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631F001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631F002",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631F003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631F004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631F005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631F006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "631F007",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6323002",
     "precio_lista": 86299,
     "precio_publico": 158900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6326005",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6327000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6327002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6327020",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6328000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6328001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6328005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6328007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6328020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632A020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632B001",
     "precio_lista": 100535,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632B002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632B003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632B004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632B005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632B006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632C005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632D000",
     "precio_lista": 106239,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632D003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632D005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632D006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632D007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632E001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632E002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632E004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632E005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632E006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632F001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632F002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632F003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632F005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632F007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632F009",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6332050",
     "precio_lista": 86299,
     "precio_publico": 158900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6333004",
     "precio_lista": 86299,
     "precio_publico": 158900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6334004",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6335000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6335009",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6336000",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6336008",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6337000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6337002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6338000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6338001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6338003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6338005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6338020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "633A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "633A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "633A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "633A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "633A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "633A006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635B005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635D100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635D101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635D202",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635D203",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635D205",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635D206",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E100",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E101",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E102",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E103",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E105",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E106",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E107",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E108",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E109",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E110",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E111",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E112",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E113",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6394008",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6394010",
     "precio_lista": 77609,
     "precio_publico": 142900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6394011",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6395000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6396000",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6397000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6397001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6397002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6397003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6397005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6398000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6398001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6398003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6398004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6398005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6398006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6398009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6398014",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6398020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6398410",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639A013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B012",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B014",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639B016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639C006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639C007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D006",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639D010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639E001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639E002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639E003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639E004",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639E005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639E006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "639E007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "640D040",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641A002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641A004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641A009",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641B003",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641B004",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641B005",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641B006",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641B008",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641B009",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641B012",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641B014",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641B019",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C000",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C001",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C002",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C014",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C021",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C022",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C023",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C024",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D007",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D010",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D011",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D012",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D013",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D014",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D015",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D016",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D017",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D018",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D019",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D022",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D023",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E007",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E011",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E014",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E017",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E024",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E060",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E061",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F015",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F023",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F024",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F040",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642A000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642A001",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642A002",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642A005",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642A007",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642A010",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B002",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B003",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B004",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B007",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B010",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B012",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B014",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B015",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B016",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C006",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C009",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C010",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C012",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C025",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C026",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C027",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D000",
     "precio_lista": 39653,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D001",
     "precio_lista": 39653,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D002",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D003",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D004",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D005",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D006",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D007",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D008",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D009",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D010",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D011",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D012",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D013",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D014",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D015",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D016",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D017",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D018",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D019",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D022",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D023",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D024",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D025",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D026",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D040",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D050",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D051",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E008",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E018",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E025",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E026",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E028",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E029",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E040",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F010",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F011",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F012",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F024",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F040",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "643A000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "643A001",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "643A007",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "643A009",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "643A010",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "643A011",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "643A012",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "643A014",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "643A015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "643A016",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "644A400",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "644C400",
     "precio_lista": 39653,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A000",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A005",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A007",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A025",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645A026",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C200",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C201",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C202",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C203",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C204",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C205",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C206",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C207",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C208",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C209",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C212",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C300",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C301",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C302",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C303",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645C304",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D100",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D101",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D102",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D103",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D104",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D105",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D106",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D107",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D109",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D110",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D111",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D112",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D113",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D114",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D115",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D118",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D220",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D221",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D222",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D223",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D225",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D227",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D228",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D230",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D900",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D901",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D902",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E101",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E103",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E104",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E106",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E107",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E108",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E109",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E110",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E202",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E203",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E204",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E205",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E206",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E207",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E208",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E209",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E210",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E211",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E212",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E213",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645E214",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "646B204",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A001",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A002",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A003",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A004",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A005",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A006",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A007",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A009",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A010",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A012",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A013",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A018",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A019",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A021",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A022",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A024",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649A029",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B001",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B004",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B005",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B006",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B007",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B009",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B010",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B012",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B013",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B030",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B031",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B032",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B033",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B034",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B035",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B038",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B040",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B045",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C004",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C009",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C010",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C011",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C012",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C013",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C017",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C018",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C019",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C022",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C025",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C026",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C027",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D002",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D003",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D007",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D009",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D010",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D011",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D012",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D013",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D014",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D015",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D017",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D018",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D019",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D023",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D024",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D025",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D026",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D027",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D028",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D029",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D030",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D031",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D032",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D033",
     "precio_lista": 55535,
     "precio_publico": 104900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D034",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D041",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D042",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D043",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D044",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D045",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D046",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D055",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D056",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D057",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E013",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E024",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E026",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E028",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E040",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E900",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E901",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E902",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E903",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "650D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6517006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6518002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6518005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651B003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651B006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651C003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651C004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651D003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651D004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651D006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651D007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651E000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651E002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651E003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651E004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651E005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651E006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651F000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651F001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651F002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651F003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651F004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651F005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "651F006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6527006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6528000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6528005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652A004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652B004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652B006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652C002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652C005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652D002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652D003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652D004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652D006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652D007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652D008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652E000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652E003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652E004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652F000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652F001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652F002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652F003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652F005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "652F006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6535006",
     "precio_lista": 70006,
     "precio_publico": 128900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6538000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6538001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6538002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6538003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6538004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "653A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "653A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655C300",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655D100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655D101",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655D102",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655D103",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655D205",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655D206",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655D207",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655E000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655E100",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "655E101",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6593001",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6594002",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6594006",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6595000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6597001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6598000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6598001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6598002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6598005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6598007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659A006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659A007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659B005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659B008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659B011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659B012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659C000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659C002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659C005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659C006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659D011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659E000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659E001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659E002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659E004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659E005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659E006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659E007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "659E008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6816002",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6818005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681B003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681B004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681B006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681C005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681D001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681D002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681D003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681D004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681D005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681D006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681E000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681E001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681E004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681E005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681E006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681E060",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681F000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681F001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681F002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681F003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681F004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681F005",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681F006",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681F007",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681F008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "681F009",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6827006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6828005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682A002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682A006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682B008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682C003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682C005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682C006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682C007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682C008",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682D001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682D002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682D003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682D004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682D005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682D006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682D007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682D008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682D050",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682E000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682E001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682E003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682E005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682E006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682E007",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682F000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682F001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682F002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682F004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682F005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "682F006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6836006",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6837001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6838000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6838007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6838008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "683A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "683A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "683A005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "683A006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "683A007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "683A008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "683A010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "684A400",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685D200",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685D201",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E103",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E104",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E105",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E106",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E107",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E109",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E110",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E111",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E112",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E113",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E114",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E115",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6896002",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6897000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6897004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6898001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6898006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6898007",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689A000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689A006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689A008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689B012",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689B013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689B016",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689C002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689C003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689C005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689C006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689C007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689C008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689C009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689C010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D006",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D007",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D009",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D010",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689D011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E005",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E007",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E009",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E010",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E011",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E013",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E900",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E901",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "689E902",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "700D050",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "700E051",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "700E052",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7012016",
     "precio_lista": 45023,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7015004",
     "precio_lista": 37420,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7015006",
     "precio_lista": 41765,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7015017",
     "precio_lista": 41765,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7016014",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7016016",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7017015",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7017017",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018000",
     "precio_lista": 38594,
     "precio_publico": 72900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018001",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018006",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018016",
     "precio_lista": 36476,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018017",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018018",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018020",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018025",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701A000",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701A001",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701A002",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701A004",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701A006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701A010",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701A011",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701A012",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701A016",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B001",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B002",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B003",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B004",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B006",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B011",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B012",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B014",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B016",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B123",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701B125",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C000",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C001",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C002",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C003",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C007",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C008",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C012",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C013",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C014",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C017",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C019",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C020",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C022",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C025",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C026",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C050",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C051",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C052",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701C053",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D014",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D016",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D018",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D019",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D021",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D025",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D026",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D027",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D028",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D050",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D051",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D052",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701D053",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E007",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E009",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E015",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E017",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E021",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E023",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E024",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E025",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E026",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E027",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E028",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E050",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E051",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E052",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E053",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701E054",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F006",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F023",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "701F052",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7026016",
     "precio_lista": 41765,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7027002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7027003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7027007",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7027013",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7027222",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7028005",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7028006",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7028008",
     "precio_lista": 38594,
     "precio_publico": 72900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7028013",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7028016",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7028017",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7028019",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7028022",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A002",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A003",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A004",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A006",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A008",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A014",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A015",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A017",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A019",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A024",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A026",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702A027",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B001",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B003",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B004",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B005",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B006",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B009",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B010",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B011",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B012",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B014",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B015",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702B021",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C011",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C012",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C014",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C015",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C016",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C017",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C025",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C026",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C027",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C051",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C052",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D013",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D014",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D015",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D019",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D021",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D022",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D024",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D025",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D026",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D027",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D028",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D029",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D030",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D031",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D032",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D033",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D034",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D050",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D051",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D052",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D053",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702D054",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E012",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E019",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E050",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E051",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702E052",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F014",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F018",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F020",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F052",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7033030",
     "precio_lista": 37420,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7036000",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7036012",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7036020",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7036021",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7037013",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7037016",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7037018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7037022",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7037325",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038002",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038004",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038008",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038010",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038011",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038015",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038017",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038018",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038019",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7038026",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A002",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A003",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A004",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A005",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A006",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A012",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A014",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A015",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A016",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A019",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A025",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A325",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "703A326",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704A401",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704B400",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704F000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704F001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704F002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D007",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D008",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D009",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D010",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D011",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705D012",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E010",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E012",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "705E013",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7095010",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7095024",
     "precio_lista": 42851,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7095025",
     "precio_lista": 42851,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7096010",
     "precio_lista": 41765,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7097001",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7097006",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7097016",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7097018",
     "precio_lista": 36476,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7097025",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7097031",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7097033",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7097036",
     "precio_lista": 28006,
     "precio_publico": 52900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098000",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098005",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098007",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098008",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098009",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098014",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098019",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098021",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098029",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098031",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098032",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098033",
     "precio_lista": 36476,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098034",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098037",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098039",
     "precio_lista": 36476,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A001",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A002",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A003",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A006",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A007",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A008",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A009",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A010",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A013",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A015",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A017",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A018",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A019",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A022",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A023",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A024",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A025",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A027",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A028",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A029",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A030",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A032",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A033",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A034",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A035",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A036",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A037",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A038",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709A039",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B004",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B005",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B006",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B007",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B008",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B011",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B012",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B016",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B033",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B034",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B035",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B036",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B038",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B039",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B040",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B041",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B043",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B044",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B045",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B046",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B047",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B049",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B050",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B051",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B052",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B056",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709B057",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C002",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C006",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C007",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C008",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C009",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C010",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C011",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C012",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C013",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C014",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C015",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C016",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C017",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C018",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C022",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C024",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C025",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C026",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C027",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C028",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C029",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C030",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C031",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C032",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C033",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C034",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C035",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C051",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C052",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C053",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D006",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D010",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D011",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D012",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D013",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D014",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D016",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D017",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D018",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D020",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D021",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D026",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D027",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D028",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D029",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D030",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D031",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D032",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D033",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D034",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D035",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D050",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D051",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D052",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D053",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E015",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E019",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E021",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E022",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E023",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E024",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E026",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E027",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E028",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E050",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E051",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E052",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E053",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E054",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E929",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E930",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709E931",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7107000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "710C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7115003",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7115007",
     "precio_lista": 77609,
     "precio_publico": 142900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7115009",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7116023",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7117008",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7117014",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118018",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A009",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A014",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A016",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A018",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711A022",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B119",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B120",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B121",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711B122",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C002",
     "precio_lista": 96829,
     "precio_publico": 182900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C003",
     "precio_lista": 96829,
     "precio_publico": 182900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C004",
     "precio_lista": 96829,
     "precio_publico": 182900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C014",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C016",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C019",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711C021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D007",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D012",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D018",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711D021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E006",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E014",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E015",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E017",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E018",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E020",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E021",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E022",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E023",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E024",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E025",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E050",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F014",
     "precio_lista": 96001,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F016",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F018",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711F019",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7127008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7127013",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7127014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7127022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128006",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128012",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7128023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A014",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A027",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A230",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712A231",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B009",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B011",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B013",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B015",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B020",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B024",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B026",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B027",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712B028",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C011",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C013",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C021",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C023",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712C025",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D014",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D015",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D016",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D017",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D018",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D019",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D024",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D026",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D027",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D031",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712D032",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E003",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E015",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E017",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712E022",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F012",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F017",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7133008",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7134009",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7134032",
     "precio_lista": 70006,
     "precio_publico": 128900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7135017",
     "precio_lista": 59687,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7136014",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137013",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137023",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137325",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7137326",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138005",
     "precio_lista": 63476,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138011",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138014",
     "precio_lista": 74065,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138017",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138018",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138020",
     "precio_lista": 74065,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7138023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A010",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A013",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A016",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A022",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A324",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "713A325",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "714A228",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "714A229",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "714A400",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D007",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D010",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D014",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D017",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D020",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D021",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715D022",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715E002",
     "precio_lista": 95141,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715E003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "715E004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7192003",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7193009",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7194001",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7194010",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7194012",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7194029",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7195004",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7195005",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7195007",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7195015",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7195017",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7195021",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7195027",
     "precio_lista": 70549,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7195037",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7196020",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7196024",
     "precio_lista": 70006,
     "precio_publico": 128900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7196025",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7197004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7197009",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7197013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7197015",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7197019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7197020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7197023",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7197025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7197026",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198009",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198010",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198011",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198013",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198023",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198025",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198026",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198028",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198029",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198036",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198037",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198043",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7198044",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A005",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A009",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A012",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A013",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A016",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A018",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A020",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A021",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A023",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A024",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A025",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A026",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A027",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A028",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A029",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A030",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A033",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A034",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A035",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A036",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A037",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A438",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719A439",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B015",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B023",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B029",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B032",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B036",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B037",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B038",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B039",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B040",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B043",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B044",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B045",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B046",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B047",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B048",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C008",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C011",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C012",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C013",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C015",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C018",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C020",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C022",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C024",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C025",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D011",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D012",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D015",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D018",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D019",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D020",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D022",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D023",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D024",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D025",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D027",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D028",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D029",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719D030",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E005",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E008",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E015",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E016",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E017",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E018",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E019",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719E021",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721B000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721B003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721B004",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721C001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721C004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721D004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721D005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721D007",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721D008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721E001",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721E002",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721E003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721F001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721F005",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721F007",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7228004",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722A003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722B005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722C003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722C004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722C006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722D003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722D004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722D007",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722E002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722E006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722F007",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722F009",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7238011",
     "precio_lista": 105300,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7238014",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "723A000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "725E000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "725E001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7297000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7297001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7297002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729A008",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729B002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729C008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D010",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729E001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729E002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729E007",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7306000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7307100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7307400",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "730E000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7316000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7317004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7318000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731A005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731B003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731B004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731B005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731B006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731C001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731C005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731E000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731E002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731E004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731F000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731F001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731F002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "731F003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7324009",
     "precio_lista": 77609,
     "precio_publico": 142900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7325001",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7326000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7327000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7327003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7327005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7328000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7328001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7328007",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732A003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732A005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732A006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732A020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732B000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732B004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732B005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732C001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732D000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732D002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732D005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732E000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732E001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732E002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732E003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732F000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732F001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732F002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7334000",
     "precio_lista": 75437,
     "precio_publico": 138900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7336000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7337000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7337003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7337006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7338000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7338002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7338006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "733A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "733A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "733A004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "733A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7348200",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "734F000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "735E000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7395000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7395003",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7395007",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7396000",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7396007",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7397000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7397007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7397009",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7398000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7398001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7398002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7398004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7398005",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7398020",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739A004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739A006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739A007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739A008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739B007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739B008",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739B009",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739C003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739C005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739D000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739D001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739D002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739D003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739D004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739E000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739E001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739E002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739E003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739E004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "739E005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "741B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "741C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "741C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "741E002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "741F000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "741F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "742A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "742A004",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "742C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "742D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "742D001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "742E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "742E001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "742F000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "743A000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749A001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749B004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749C000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749E001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "749E002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751B001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751B003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751E003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751F000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "751F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7525000",
     "precio_lista": 70006,
     "precio_publico": 128900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7528000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7528001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752B002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752B003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752D000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752D003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752E000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752F000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "752F001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7538000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7538002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "753A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "753A002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7593002",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7598001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759A001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759A003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759B000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759B004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759B006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759C000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759D000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759E001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759E002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "759E003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7717002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7717003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7718001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7718003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771A002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771C002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771D003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771D006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771E003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771E004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771E006",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771E007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771E008",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771E009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771F000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771F001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771F002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771F003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "771F004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7728000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7728006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772B002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772B004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772D001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772D003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772D004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772E001",
     "precio_lista": 124957,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772E003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772E004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772E005",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772F000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772F001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772F002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772F005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7735003",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7735006",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7736002",
     "precio_lista": 97704,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7737000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7737003",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7738004",
     "precio_lista": 68771,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7738005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "773A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "773A001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "773A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7797006",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7797007",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7798000",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7798002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7798003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7798004",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779A000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779A001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779A002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779A003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779A004",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779A007",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779A008",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779B000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779B003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779B009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779C002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779C005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779C006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779D000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779D001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779D002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779D003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779D005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779E000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779E001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779E002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779E005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779E006",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "779E007",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7816001",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781A001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781B003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781C001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781C003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781D002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781D003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781D004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781E000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781E001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781E003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781E005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781E006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781E007",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781F000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781F001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781F002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781F003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "781F004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7828002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782A005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782B002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782B003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782C001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782D000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782D003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782D004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782D005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782E001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782E004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782E005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782F000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782F001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782F002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7838002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7838005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "783A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "785E000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "785E001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "785E002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "785E004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "785E005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "785E006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7897001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7897003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7898004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7898005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789A004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789A005",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789A007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789B000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789C000",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789C002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789C005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D006",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D008",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789D011",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789E000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789E001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789E002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789E003",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789E004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789E005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "789E006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "801E100",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "801E101",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "801E102",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "801E105",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "801F000",
     "precio_lista": 58459,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "801F001",
     "precio_lista": 64961,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "801F003",
     "precio_lista": 64961,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "801F004",
     "precio_lista": 64961,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "802E000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "802E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "802E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "802E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "802E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "802F000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "802F001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "802F002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "802F003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D103",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D104",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D105",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D202",
     "precio_lista": 50744,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D401",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D402",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D403",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D404",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D405",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D406",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "809E000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "809E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "809E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "809E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "809E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "809E005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "811E100",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "811E101",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "812E002",
     "precio_lista": 78704,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "812E003",
     "precio_lista": 62994,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "812E004",
     "precio_lista": 62283,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814D100",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814D101",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814D200",
     "precio_lista": 54021,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814D201",
     "precio_lista": 54021,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814D400",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814D401",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814D402",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "819E000",
     "precio_lista": 60620,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "819E001",
     "precio_lista": 79315,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "819E002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "821E100",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "821F000",
     "precio_lista": 117568,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "821F002",
     "precio_lista": 100427,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "822E001",
     "precio_lista": 125232,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "822F000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "822F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "8248300",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824B202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824C202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824C300",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824C400",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824D100",
     "precio_lista": 87340,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824D200",
     "precio_lista": 87340,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824D400",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824D401",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "829E000",
     "precio_lista": 127357,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "829E001",
     "precio_lista": 88265,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "829E002",
     "precio_lista": 90904,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "831E100",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "831F004",
     "precio_lista": 64961,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "832E000",
     "precio_lista": 63086,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "832F000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D200",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D404",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D405",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "839E000",
     "precio_lista": 61498,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "839E001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "839E002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "841E100",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "841E101",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "841E102",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "841E103",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "841E104",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "841F000",
     "precio_lista": 41317,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "841F001",
     "precio_lista": 47228,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "841F003",
     "precio_lista": 47228,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "841F004",
     "precio_lista": 50775,
     "precio_publico": 85900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842E000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842E001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842E002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842E003",
     "precio_lista": 45476,
     "precio_publico": 85900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842E004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842E005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842F000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842F001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842F002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842F003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "842F004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D100",
     "precio_lista": 23433,
     "precio_publico": 42900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D101",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D102",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D103",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D104",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D105",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D106",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D107",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D200",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D201",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D202",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D203",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D204",
     "precio_lista": 35996,
     "precio_publico": 65900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D205",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D206",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D207",
     "precio_lista": 32718,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D400",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D401",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D402",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D403",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D404",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D405",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D406",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D407",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D408",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844D409",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "849E000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "849E001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "849E002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "849E003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "849E004",
     "precio_lista": 45476,
     "precio_publico": 85900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "849E005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "851E101",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "851E102",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "851F001",
     "precio_lista": 58459,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "852E001",
     "precio_lista": 54270,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "852E002",
     "precio_lista": 54021,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "852F002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854B202",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854C201",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854C202",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854C401",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D102",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D200",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D201",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D400",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D402",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D403",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "859E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "859E002",
     "precio_lista": 54021,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "881E100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "881E101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "881F000",
     "precio_lista": 94516,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "881F002",
     "precio_lista": 88605,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "882E000",
     "precio_lista": 74064,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "882E001",
     "precio_lista": 83768,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "882F000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "882F001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884B202",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884C201",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884C202",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884C203",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884C300",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884C301",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884C400",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884C401",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884C403",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D100",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D101",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D102",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D103",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D200",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D201",
     "precio_lista": 108643,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D202",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D203",
     "precio_lista": 108643,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D400",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D401",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884D402",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "889E000",
     "precio_lista": 85153,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "889E001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "889E002",
     "precio_lista": 98990,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "889E003",
     "precio_lista": 94516,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3118000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3128000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312F001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "312F003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "322F002",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318021",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3318133",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3396002",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3397025",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "3398476",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4118001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "412F002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4218001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "422F003",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4316001",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318009",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318012",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318017",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318020",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318021",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318026",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318032",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4318033",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "431B026",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328029",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4328034",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "432A025",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439B470",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4418000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "4718000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028002",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028033",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028040",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F016",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128011",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128012",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128015",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128031",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F034",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "632F004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6818004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018008",
     "precio_lista": 41771,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7018019",
     "precio_lista": 40712,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7028011",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7028015",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F017",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702F019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118015",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118019",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7118022",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "712F004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "732F003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7718002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "772F004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7796005",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7818004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7818005",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "782F004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "439A047",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "N/A",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "N/A",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "062F004",
     "precio_lista": 296410,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "062F003",
     "precio_lista": 296410,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "062F002",
     "precio_lista": 310192,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "062F001",
     "precio_lista": 305824,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "062F000",
     "precio_lista": 305824,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "250F000",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "050F001",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "050F000",
     "precio_lista": 14241,
     "precio_publico": 26900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "N/A",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "062F009",
     "precio_lista": 314561,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "062F008",
     "precio_lista": 305824,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "062F007",
     "precio_lista": 305824,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "062F006",
     "precio_lista": 314561,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "062F005",
     "precio_lista": 305824,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "N/A",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282F003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282F002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282F001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282F000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F007",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692F008",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692F006",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692F005",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692F004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692F003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692F001",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692F000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F012",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032F007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032F004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032F003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032F001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032F000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262F003",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262F002",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262F000",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022F003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022F002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022F001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022F000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012F002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012F001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012F000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092F003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092F002",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092F001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092F000",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032F006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032F005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032F002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212F001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212F000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "852F000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "832F003",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "832F002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "832F001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262F001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F412",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F411",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F410",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F409",
     "precio_lista": 6829,
     "precio_publico": 12900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F408",
     "precio_lista": 6829,
     "precio_publico": 12900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F407",
     "precio_lista": 11065,
     "precio_publico": 20900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F406",
     "precio_lista": 26947,
     "precio_publico": 50900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F405",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F404",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F403",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F402",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F401",
     "precio_lista": 6829,
     "precio_publico": 12900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F400",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F399",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F398",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F397",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F396",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F395",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F394",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F393",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F392",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F391",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F390",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F389",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F388",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F387",
     "precio_lista": 10006,
     "precio_publico": 18900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F386",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F385",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F384",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F383",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F382",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F381",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F380",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F379",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F378",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F377",
     "precio_lista": 9476,
     "precio_publico": 17900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F376",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F375",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F374",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F373",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F372",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F371",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F370",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F369",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F368",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F367",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F366",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F365",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F364",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F363",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F362",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F361",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F360",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F359",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F358",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F357",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F356",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F355",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F354",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F353",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F352",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F351",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F350",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F349",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F348",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F347",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F346",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F345",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F344",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F343",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F342",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F341",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F340",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F339",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F338",
     "precio_lista": 210000,
     "precio_publico": 210000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F337",
     "precio_lista": 223000,
     "precio_publico": 223000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F336",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F335",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F334",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F333",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F332",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F331",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F330",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F329",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F328",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F327",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F326",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F325",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F324",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F323",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F322",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F321",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F320",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F319",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F318",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F317",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F316",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F315",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F314",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F313",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F312",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F311",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F310",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F309",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F308",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F307",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F306",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F305",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F304",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F303",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F302",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F301",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F300",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002F002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002F001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002F000",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "010F002",
     "precio_lista": 66653,
     "precio_publico": 125900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "010F000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "090F105",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "090F104",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "090F103",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "090F102",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F413",
     "precio_lista": 59900,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F429",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F428",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F427",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F426",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F425",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F424",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F423",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F422",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F421",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F420",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F419",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F418",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F417",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F416",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F415",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F414",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "N/A",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252F005",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252F004",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252F003",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252F002",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252F001",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252F000",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052F009",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052F008",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052F007",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052F006",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052F005",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052F004",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052F003",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052F002",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052F001",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052F000",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0938002",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727F501",
     "precio_lista": 687653,
     "precio_publico": 1298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055F000",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F456",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F455",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F454",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F453",
     "precio_lista": 14241,
     "precio_publico": 26900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F452",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F451",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F450",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F449",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F448",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F447",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F446",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F445",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F444",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F443",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F442",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F441",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F440",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F439",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F438",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F437",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F436",
     "precio_lista": 6829,
     "precio_publico": 12900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F435",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F434",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F433",
     "precio_lista": 7359,
     "precio_publico": 13900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F432",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514F431",
     "precio_lista": 11594,
     "precio_publico": 21900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039F903",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039F902",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039F901",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039F900",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5810004",
     "precio_lista": 31989,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "007B201",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "061F000",
     "precio_lista": 275241,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "061F001",
     "precio_lista": 275241,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "061F002",
     "precio_lista": 288176,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "061F003",
     "precio_lista": 288176,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "061F004",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "061F005",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "061F006",
     "precio_lista": 275241,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "061F007",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064C300",
     "precio_lista": 230000,
     "precio_publico": 395900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064C301",
     "precio_lista": 245744,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064C302",
     "precio_lista": 245744,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064C303",
     "precio_lista": 245744,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D200",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D202",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D204",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D400",
     "precio_lista": 293471,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D401",
     "precio_lista": 275241,
     "precio_publico": 519900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D402",
     "precio_lista": 293471,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D403",
     "precio_lista": 293471,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D404",
     "precio_lista": 293471,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D405",
     "precio_lista": 293471,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D406",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E200",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E201",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E202",
     "precio_lista": 296418,
     "precio_publico": 559900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E203",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E204",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E205",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E206",
     "precio_lista": 293471,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E207",
     "precio_lista": 293471,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E208",
     "precio_lista": 293471,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E209",
     "precio_lista": 293471,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E210",
     "precio_lista": 293471,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E211",
     "precio_lista": 314433,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E300",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E301",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E302",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E303",
     "precio_lista": 253535,
     "precio_publico": 478900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E304",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064E305",
     "precio_lista": 296418,
     "precio_publico": 559900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0675201",
     "precio_lista": 95517,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0675202",
     "precio_lista": 128000,
     "precio_publico": 294900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0675203",
     "precio_lista": 190000,
     "precio_publico": 432900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0675400",
     "precio_lista": 226000,
     "precio_publico": 543900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676100",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676101",
     "precio_lista": 297000,
     "precio_publico": 515900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676203",
     "precio_lista": 272000,
     "precio_publico": 478900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676204",
     "precio_lista": 307006,
     "precio_publico": 579900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676302",
     "precio_lista": 190000,
     "precio_publico": 358900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676304",
     "precio_lista": 236000,
     "precio_publico": 395900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676305",
     "precio_lista": 267000,
     "precio_publico": 478900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676309",
     "precio_lista": 143000,
     "precio_publico": 299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676310",
     "precio_lista": 157000,
     "precio_publico": 323900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676311",
     "precio_lista": 205000,
     "precio_publico": 395900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676312",
     "precio_lista": 205000,
     "precio_publico": 358900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0677100",
     "precio_lista": 163000,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0677101",
     "precio_lista": 213000,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0677102",
     "precio_lista": 223000,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0677103",
     "precio_lista": 123000,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0677104",
     "precio_lista": 213000,
     "precio_publico": 368900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0677105",
     "precio_lista": 230000,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0677106",
     "precio_lista": 155444,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0677400",
     "precio_lista": 203700,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0677402",
     "precio_lista": 283000,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0677403",
     "precio_lista": 326000,
     "precio_publico": 598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0678100",
     "precio_lista": 164900,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0678101",
     "precio_lista": 182000,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0678102",
     "precio_lista": 164900,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0678103",
     "precio_lista": 273000,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0678104",
     "precio_lista": 255900,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0678105",
     "precio_lista": 164900,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0678300",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0678303",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0678304",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0678305",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067A200",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067A201",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067A202",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067A203",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067A204",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067A401",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067A402",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B200",
     "precio_lista": 178900,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B201",
     "precio_lista": 141000,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B202",
     "precio_lista": 156000,
     "precio_publico": 328900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B203",
     "precio_lista": 226666,
     "precio_publico": 459900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B204",
     "precio_lista": 183333,
     "precio_publico": 358900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B205",
     "precio_lista": 156000,
     "precio_publico": 328900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B206",
     "precio_lista": 154810,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B400",
     "precio_lista": 174124,
     "precio_publico": 328900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B401",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B402",
     "precio_lista": 174124,
     "precio_publico": 328900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "067B403",
     "precio_lista": 174124,
     "precio_publico": 328900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0846103",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0875412",
     "precio_lista": 114000,
     "precio_publico": 203900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0878102",
     "precio_lista": 103900,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0878304",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087A401",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087A402",
     "precio_lista": 84124,
     "precio_publico": 158900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087A405",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087A406",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087B200",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087B202",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087C300",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087C301",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087D200",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087D201",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087D202",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087E200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087E201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087E400",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087E401",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087E402",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087F201",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087F400",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087F401",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "087F402",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2848301",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "284A101",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "421D001",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5076200",
     "precio_lista": 402328,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5076201",
     "precio_lista": 402328,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "507C400",
     "precio_lista": 419900,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5448300",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5448301",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5472007",
     "precio_lista": 74304,
     "precio_publico": 178900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5475000",
     "precio_lista": 125000,
     "precio_publico": 299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5475003",
     "precio_lista": 125000,
     "precio_publico": 299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5478400",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5478403",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5478404",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5478405",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5478406",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5478407",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5478408",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5478409",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "547A400",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "547A401",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "547A402",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "547A403",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "547A404",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "547A405",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "547A406",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "547A407",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "547A408",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "547A409",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5670005",
     "precio_lista": 105620,
     "precio_publico": 238900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5671005",
     "precio_lista": 275312,
     "precio_publico": 624900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5673410",
     "precio_lista": 314268,
     "precio_publico": 752900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5673416",
     "precio_lista": 187553,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5674401",
     "precio_lista": 149311,
     "precio_publico": 327900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5674405",
     "precio_lista": 291230,
     "precio_publico": 695900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5874204",
     "precio_lista": 59231,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5972107",
     "precio_lista": 130000,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5972108",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5972109",
     "precio_lista": 76440,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5972120",
     "precio_lista": 186300,
     "precio_publico": 351900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5972121",
     "precio_lista": 186300,
     "precio_publico": 351900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6001000",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "600A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "600B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6016000",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018000",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6018001",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A000",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601A001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B000",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601B001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601C001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601D001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E006",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601E020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "601F015",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6023001",
     "precio_lista": 42851,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6025000",
     "precio_lista": 46109,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6026000",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6026001",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6027001",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028000",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6028001",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A000",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602A001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B000",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602B001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602C006",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D000",
     "precio_lista": 65130,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D001",
     "precio_lista": 63570,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D029",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D030",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602D035",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "602F001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6033010",
     "precio_lista": 45023,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6034001",
     "precio_lista": 42851,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6034054",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6036000",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6037001",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038000",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6038001",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A000",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "603A001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "607A400",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6093000",
     "precio_lista": 41765,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094001",
     "precio_lista": 45023,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094020",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6094054",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6095001",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6096000",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6096001",
     "precio_lista": 50454,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6097000",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6097001",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098000",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6098001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A000",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609A001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B000",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609B001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C032",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C037",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C038",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C039",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C040",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C041",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C042",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C043",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C044",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C045",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C046",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C047",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C048",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C049",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C050",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609C051",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D006",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D017",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D033",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D046",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609D047",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "609E001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "610E005",
     "precio_lista": 213167,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6116022",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A018",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611A028",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B019",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B022",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B100",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B101",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B102",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B103",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C042",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C043",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C044",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611C045",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D021",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D022",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D023",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611D024",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E023",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E024",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E025",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E028",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611E029",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F033",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F034",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611F036",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6128019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A014",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A015",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A016",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A028",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A100",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A101",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A102",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A103",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A104",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612A105",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B037",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B038",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B039",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B041",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C029",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C030",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C031",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C032",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612C033",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D022",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D030",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D031",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D032",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D033",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D034",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E024",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E025",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E029",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612E030",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F024",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612F028",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6135029",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138020",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6138021",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6146204",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6146401",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C201",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6175214",
     "precio_lista": 114900,
     "precio_publico": 203900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6175217",
     "precio_lista": 117055,
     "precio_publico": 227900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6176207",
     "precio_lista": 124000,
     "precio_publico": 273900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6177105",
     "precio_lista": 99324,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6177403",
     "precio_lista": 121708,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6177406",
     "precio_lista": 134421,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178100",
     "precio_lista": 117000,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178101",
     "precio_lista": 106900,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178102",
     "precio_lista": 122423,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178104",
     "precio_lista": 117000,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178105",
     "precio_lista": 117000,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178106",
     "precio_lista": 117000,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178107",
     "precio_lista": 130000,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178300",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178301",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178302",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178303",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178305",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178306",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178310",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178311",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6178312",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A201",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A202",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A204",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A205",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A206",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A208",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A210",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A219",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A400",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A401",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A402",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A403",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A405",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A406",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A408",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A409",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617A411",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617B200",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617B206",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617B207",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617B209",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617B210",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617B211",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617B212",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617B215",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617C200",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617C201",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617C202",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617C300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617C301",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617C302",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617C303",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617C304",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617C305",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617C306",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E201",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E202",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E203",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E204",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E205",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E400",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E401",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E402",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E403",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E404",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E405",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E406",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617E407",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617F200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617F201",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617F202",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617F203",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617F204",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617F205",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6196018",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198019",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198035",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198036",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198037",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6198050",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A010",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A014",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A018",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A041",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A056",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A057",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A400",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A401",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A402",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A403",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A404",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619A405",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B055",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B056",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B057",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B058",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B059",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B076",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619B088",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C008",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C010",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C023",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C038",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C039",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C040",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C041",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C042",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D008",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D038",
     "precio_lista": 151472,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D040",
     "precio_lista": 151472,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D046",
     "precio_lista": 151472,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D047",
     "precio_lista": 151472,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619D048",
     "precio_lista": 151472,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E005",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E012",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E019",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E032",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E038",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619E039",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "620B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "620D001",
     "precio_lista": 135720,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "620E000",
     "precio_lista": 127704,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6214006",
     "precio_lista": 157446,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6217002",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6217005",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621A000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621A005",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621A006",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621A008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621B003",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621B004",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621B007",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621B009",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621C000",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621C002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621C005",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621C006",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621C008",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D002",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D003",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D008",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D012",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621E000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621E001",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621E002",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621E003",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F000",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F001",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F002",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F006",
     "precio_lista": 312300,
     "precio_publico": 589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F008",
     "precio_lista": 296418,
     "precio_publico": 559900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F010",
     "precio_lista": 352797,
     "precio_publico": 629900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621F012",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6228000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6228001",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6228003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622A000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622A001",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622A004",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622A008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622B000",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622C000",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622C001",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622C003",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622C005",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622C006",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622C008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622C011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D000",
     "precio_lista": 162254,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D002",
     "precio_lista": 235159,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D004",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D005",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D008",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D009",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D012",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E000",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E002",
     "precio_lista": 333476,
     "precio_publico": 629900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E004",
     "precio_lista": 317594,
     "precio_publico": 599900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E006",
     "precio_lista": 311127,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622E007",
     "precio_lista": 343758,
     "precio_publico": 599900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F001",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F002",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F004",
     "precio_lista": 333476,
     "precio_publico": 629900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F007",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622F009",
     "precio_lista": 333476,
     "precio_publico": 629900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6236000",
     "precio_lista": 190032,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6236002",
     "precio_lista": 190032,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6237002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6237003",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6237004",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6238000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6238007",
     "precio_lista": 148182,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6238008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "623A001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "623A003",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "623A007",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "623A008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "623A011",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "624A100",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "624A400",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6274205",
     "precio_lista": 369000,
     "precio_publico": 884900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6274409",
     "precio_lista": 318198,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6275264",
     "precio_lista": 220000,
     "precio_publico": 499900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6275400",
     "precio_lista": 420000,
     "precio_publico": 984900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6276201",
     "precio_lista": 444000,
     "precio_publico": 1294900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6276300",
     "precio_lista": 408413,
     "precio_publico": 1079900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6276311",
     "precio_lista": 625000,
     "precio_publico": 1425900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277100",
     "precio_lista": 440000,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277101",
     "precio_lista": 440000,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277106",
     "precio_lista": 403333,
     "precio_publico": 789900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277109",
     "precio_lista": 427000,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277111",
     "precio_lista": 568000,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277112",
     "precio_lista": 416000,
     "precio_publico": 789900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277113",
     "precio_lista": 400000,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277114",
     "precio_lista": 400000,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277115",
     "precio_lista": 362000,
     "precio_publico": 619900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277150",
     "precio_lista": 195000,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277154",
     "precio_lista": 190000,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277156",
     "precio_lista": 248000,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277158",
     "precio_lista": 279900,
     "precio_publico": 464900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277400",
     "precio_lista": 413900,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277401",
     "precio_lista": 366000,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277402",
     "precio_lista": 410900,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277405",
     "precio_lista": 511900,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277407",
     "precio_lista": 517207,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277410",
     "precio_lista": 656000,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277451",
     "precio_lista": 270000,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277453",
     "precio_lista": 537000,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277455",
     "precio_lista": 613000,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6277462",
     "precio_lista": 674000,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278102",
     "precio_lista": 589900,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278103",
     "precio_lista": 630000,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278104",
     "precio_lista": 392000,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278106",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278107",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278111",
     "precio_lista": 389120,
     "precio_publico": 849900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278153",
     "precio_lista": 245900,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278158",
     "precio_lista": 336000,
     "precio_publico": 419900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278159",
     "precio_lista": 252000,
     "precio_publico": 419900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278161",
     "precio_lista": 231111,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278162",
     "precio_lista": 421000,
     "precio_publico": 619900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278302",
     "precio_lista": 414900,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278303",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278304",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278305",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278306",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278308",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278310",
     "precio_lista": 407065,
     "precio_publico": 768900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278311",
     "precio_lista": 407065,
     "precio_publico": 768900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278312",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278314",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278315",
     "precio_lista": 846000,
     "precio_publico": 1598000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278316",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278317",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278318",
     "precio_lista": 532900,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278351",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278356",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278357",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278362",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278363",
     "precio_lista": 317065,
     "precio_publico": 598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278365",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278368",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278369",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6278370",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A201",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A202",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A204",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A208",
     "precio_lista": 370006,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A210",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A211",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A252",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A253",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A256",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A259",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A264",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A265",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A400",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A401",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A402",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A403",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A404",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A405",
     "precio_lista": 687653,
     "precio_publico": 1298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A409",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A413",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A415",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A416",
     "precio_lista": 370006,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A418",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A420",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A422",
     "precio_lista": 370006,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A427",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A428",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A430",
     "precio_lista": 687653,
     "precio_publico": 1298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A433",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A435",
     "precio_lista": 370006,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627A436",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B200",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B201",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B202",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B203",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B204",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B205",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B207",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B208",
     "precio_lista": 528829,
     "precio_publico": 998900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B209",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B210",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B250",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B252",
     "precio_lista": 312300,
     "precio_publico": 589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B255",
     "precio_lista": 312300,
     "precio_publico": 589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B256",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B258",
     "precio_lista": 370006,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B260",
     "precio_lista": 338771,
     "precio_publico": 639900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B400",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B403",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B405",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B406",
     "precio_lista": 846000,
     "precio_publico": 1598000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B407",
     "precio_lista": 846476,
     "precio_publico": 1598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B408",
     "precio_lista": 793535,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627B409",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C200",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C201",
     "precio_lista": 794065,
     "precio_publico": 1499900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C202",
     "precio_lista": 794065,
     "precio_publico": 1499900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C203",
     "precio_lista": 794065,
     "precio_publico": 1499900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C204",
     "precio_lista": 794065,
     "precio_publico": 1499900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C205",
     "precio_lista": 741124,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C206",
     "precio_lista": 794065,
     "precio_publico": 1499900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C207",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C250",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C251",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C253",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C254",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C300",
     "precio_lista": 741124,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C301",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C302",
     "precio_lista": 899947,
     "precio_publico": 1699900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C303",
     "precio_lista": 1000535,
     "precio_publico": 1889900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C304",
     "precio_lista": 952888,
     "precio_publico": 1799900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C350",
     "precio_lista": 978900,
     "precio_publico": 1689900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C351",
     "precio_lista": 978900,
     "precio_publico": 1689900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C352",
     "precio_lista": 881000,
     "precio_publico": 1589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C353",
     "precio_lista": 881000,
     "precio_publico": 1589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C354",
     "precio_lista": 881000,
     "precio_publico": 1589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C355",
     "precio_lista": 978900,
     "precio_publico": 1689900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C356",
     "precio_lista": 978900,
     "precio_publico": 1689900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C357",
     "precio_lista": 773000,
     "precio_publico": 1389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C358",
     "precio_lista": 793535,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C359",
     "precio_lista": 794065,
     "precio_publico": 1499900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C360",
     "precio_lista": 794065,
     "precio_publico": 1499900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627C361",
     "precio_lista": 979359,
     "precio_publico": 1849900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D200",
     "precio_lista": 1000535,
     "precio_publico": 1889900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D201",
     "precio_lista": 947594,
     "precio_publico": 1789900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D202",
     "precio_lista": 894653,
     "precio_publico": 1689900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D203",
     "precio_lista": 894653,
     "precio_publico": 1689900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D204",
     "precio_lista": 841712,
     "precio_publico": 1589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D205",
     "precio_lista": 947594,
     "precio_publico": 1789900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D206",
     "precio_lista": 1000535,
     "precio_publico": 1889900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D207",
     "precio_lista": 894653,
     "precio_publico": 1689900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D208",
     "precio_lista": 894653,
     "precio_publico": 1689900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D209",
     "precio_lista": 1000535,
     "precio_publico": 1889900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D210",
     "precio_lista": 1000535,
     "precio_publico": 1889900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D211",
     "precio_lista": 1005300,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D250",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D251",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D252",
     "precio_lista": 764105,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D253",
     "precio_lista": 634712,
     "precio_publico": 1198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D254",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D255",
     "precio_lista": 634712,
     "precio_publico": 1198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D400",
     "precio_lista": 927971,
     "precio_publico": 1698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D401",
     "precio_lista": 1075450,
     "precio_publico": 1968900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D402",
     "precio_lista": 1075450,
     "precio_publico": 1968900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D403",
     "precio_lista": 1075450,
     "precio_publico": 1968900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D404",
     "precio_lista": 1075450,
     "precio_publico": 1968900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D405",
     "precio_lista": 1075450,
     "precio_publico": 1968900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D406",
     "precio_lista": 1037214,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D408",
     "precio_lista": 982592,
     "precio_publico": 1798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D409",
     "precio_lista": 1037214,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D410",
     "precio_lista": 982592,
     "precio_publico": 1798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D450",
     "precio_lista": 529231,
     "precio_publico": 968900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D451",
     "precio_lista": 540702,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D452",
     "precio_lista": 305828,
     "precio_publico": 559900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D453",
     "precio_lista": 1037214,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D454",
     "precio_lista": 1037214,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D455",
     "precio_lista": 1037214,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D456",
     "precio_lista": 1053476,
     "precio_publico": 1989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D457",
     "precio_lista": 1053476,
     "precio_publico": 1989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D458",
     "precio_lista": 846476,
     "precio_publico": 1598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627D459",
     "precio_lista": 952359,
     "precio_publico": 1798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E100",
     "precio_lista": 1053476,
     "precio_publico": 1989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E101",
     "precio_lista": 846476,
     "precio_publico": 1598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E102",
     "precio_lista": 899418,
     "precio_publico": 1698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E103",
     "precio_lista": 1053476,
     "precio_publico": 1989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E160",
     "precio_lista": 1053476,
     "precio_publico": 1989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E161",
     "precio_lista": 1005300,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E162",
     "precio_lista": 1053476,
     "precio_publico": 1989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E163",
     "precio_lista": 1053476,
     "precio_publico": 1989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E164",
     "precio_lista": 1005300,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E165",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E166",
     "precio_lista": 846476,
     "precio_publico": 1598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E167",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E168",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E169",
     "precio_lista": 740594,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E170",
     "precio_lista": 740594,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E171",
     "precio_lista": 740594,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E200",
     "precio_lista": 947594,
     "precio_publico": 1789900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E201",
     "precio_lista": 947594,
     "precio_publico": 1789900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E202",
     "precio_lista": 1053476,
     "precio_publico": 1989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E203",
     "precio_lista": 1053476,
     "precio_publico": 1989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E204",
     "precio_lista": 1053476,
     "precio_publico": 1989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E205",
     "precio_lista": 788771,
     "precio_publico": 1489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E206",
     "precio_lista": 846476,
     "precio_publico": 1598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E250",
     "precio_lista": 846476,
     "precio_publico": 1598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E251",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E252",
     "precio_lista": 740594,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E253",
     "precio_lista": 1005300,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E400",
     "precio_lista": 1005300,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E401",
     "precio_lista": 899418,
     "precio_publico": 1698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E402",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E403",
     "precio_lista": 508182,
     "precio_publico": 959900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E404",
     "precio_lista": 508182,
     "precio_publico": 959900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E405",
     "precio_lista": 518771,
     "precio_publico": 979900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E406",
     "precio_lista": 683418,
     "precio_publico": 1290900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E407",
     "precio_lista": 683418,
     "precio_publico": 1290900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E408",
     "precio_lista": 683418,
     "precio_publico": 1290900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E409",
     "precio_lista": 683418,
     "precio_publico": 1290900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E410",
     "precio_lista": 683418,
     "precio_publico": 1290900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E411",
     "precio_lista": 736359,
     "precio_publico": 1390900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E451",
     "precio_lista": 952888,
     "precio_publico": 1799900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E452",
     "precio_lista": 788771,
     "precio_publico": 1489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E453",
     "precio_lista": 1032300,
     "precio_publico": 1949900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E454",
     "precio_lista": 947594,
     "precio_publico": 1789900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E455",
     "precio_lista": 788771,
     "precio_publico": 1489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E456",
     "precio_lista": 651124,
     "precio_publico": 1229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E457",
     "precio_lista": 894653,
     "precio_publico": 1689900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E458",
     "precio_lista": 741124,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E459",
     "precio_lista": 741124,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E460",
     "precio_lista": 741124,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E461",
     "precio_lista": 1005829,
     "precio_publico": 1899900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E463",
     "precio_lista": 370535,
     "precio_publico": 699900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E464",
     "precio_lista": 952888,
     "precio_publico": 1799900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E465",
     "precio_lista": 741124,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E466",
     "precio_lista": 741124,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E467",
     "precio_lista": 741124,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E468",
     "precio_lista": 1005829,
     "precio_publico": 1899900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627E469",
     "precio_lista": 740594,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F200",
     "precio_lista": 730186,
     "precio_publico": 1099900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F201",
     "precio_lista": 906653,
     "precio_publico": 1299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F202",
     "precio_lista": 1023456,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F203",
     "precio_lista": 806655,
     "precio_publico": 1199900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F204",
     "precio_lista": 906653,
     "precio_publico": 1299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F205",
     "precio_lista": 846988,
     "precio_publico": 1199900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F206",
     "precio_lista": 928500,
     "precio_publico": 1299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F207",
     "precio_lista": 952873,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F208",
     "precio_lista": 964637,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F209",
     "precio_lista": 1017574,
     "precio_publico": 1399900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F210",
     "precio_lista": 816739,
     "precio_publico": 1199900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F254",
     "precio_lista": 635241,
     "precio_publico": 1199900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F257",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F259",
     "precio_lista": 635241,
     "precio_publico": 1199900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6298000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6298002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6298008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629A001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629A007",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629A008",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629A015",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629B001",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629B002",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629B021",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629B022",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629B025",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C000",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C001",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C002",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C004",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C005",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C006",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C008",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629C010",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D000",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D001",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D003",
     "precio_lista": 238182,
     "precio_publico": 449900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D004",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D005",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D007",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D010",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D012",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629D015",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E000",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E001",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E004",
     "precio_lista": 296418,
     "precio_publico": 559900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E005",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E006",
     "precio_lista": 333476,
     "precio_publico": 629900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E008",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E010",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E011",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "629E013",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "640C020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641B020",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641C020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641D020",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E020",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E021",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641E022",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F020",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "641F022",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642B020",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C021",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642C022",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D020",
     "precio_lista": 48902,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642D021",
     "precio_lista": 49468,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E020",
     "precio_lista": 64363,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642E021",
     "precio_lista": 64978,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F020",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "642F220",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "644A020",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649B020",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C020",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649C021",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D020",
     "precio_lista": 49530,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D021",
     "precio_lista": 50030,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649D022",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E020",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E021",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "649E022",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6878301",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6878302",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6878305",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6878306",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687B201",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687B202",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687B203",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687B204",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687D450",
     "precio_lista": 136500,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687D451",
     "precio_lista": 141962,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687E250",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687E251",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687E452",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687E453",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687E454",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687E455",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687E460",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687F200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687F201",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687F202",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687F250",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "687F251",
     "precio_lista": 280535,
     "precio_publico": 529900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "690E000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691E000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691E004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691E005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691E006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691E007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691F004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691F005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692D350",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692D351",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692E006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692E007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6970001",
     "precio_lista": 76764,
     "precio_publico": 171900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6975203",
     "precio_lista": 103000,
     "precio_publico": 233900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6975401",
     "precio_lista": 138706,
     "precio_publico": 314900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6976302",
     "precio_lista": 172000,
     "precio_publico": 273900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6977101",
     "precio_lista": 92000,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6977103",
     "precio_lista": 150000,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6977106",
     "precio_lista": 168000,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6977108",
     "precio_lista": 127000,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6978102",
     "precio_lista": 148000,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6978300",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6978301",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6978303",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6978304",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6978305",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6978306",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6978307",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6978308",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697A200",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697A204",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697A206",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697A400",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697A402",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697A408",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697B200",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697B201",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697C200",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697C201",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697C300",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697C301",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697D300",
     "precio_lista": 163265,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697D301",
     "precio_lista": 163265,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697D302",
     "precio_lista": 163265,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697E200",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697E201",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697E202",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697E400",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697E401",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697E402",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697E450",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697F200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697F201",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "697F202",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699C350",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699C351",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699D000",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699D001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699D007",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699E000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699E001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7013013",
     "precio_lista": 42851,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7016003",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7025014",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "702C050",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7035003",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7035013",
     "precio_lista": 42851,
     "precio_publico": 78900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7035020",
     "precio_lista": 51541,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7046100",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7048100",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7048101",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7095023",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7095031",
     "precio_lista": 46109,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7098023",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709C050",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7125023",
     "precio_lista": 81411,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "714A100",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "714A101",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "714A102",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7172000",
     "precio_lista": 184436,
     "precio_publico": 311900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7195023",
     "precio_lista": 75980,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B400",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719B402",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "720B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "720E000",
     "precio_lista": 125234,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7218002",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721A002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721A005",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721B002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721B005",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721C000",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721C002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721C003",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721D000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721D001",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721D002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721D003",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721E000",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721E004",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721E005",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721F000",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721F002",
     "precio_lista": 214149,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721F003",
     "precio_lista": 214149,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721F004",
     "precio_lista": 214149,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "721F006",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7226001",
     "precio_lista": 200894,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7227001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7228003",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722A002",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722A006",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722B001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722B003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722C000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722C001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722C002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722C005",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722D000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722D001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722D002",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722D006",
     "precio_lista": 213525,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722E000",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722E001",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722E004",
     "precio_lista": 242659,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722E005",
     "precio_lista": 275575,
     "precio_publico": 499900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722E007",
     "precio_lista": 213724,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722F000",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722F001",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722F002",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722F003",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7237000",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7238003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "723A003",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7247400",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7248403",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724A100",
     "precio_lista": 418182,
     "precio_publico": 789900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724A101",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724A102",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724A103",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724A104",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724A106",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724C400",
     "precio_lista": 688182,
     "precio_publico": 1299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724C401",
     "precio_lista": 688182,
     "precio_publico": 1299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724C402",
     "precio_lista": 688182,
     "precio_publico": 1299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724C403",
     "precio_lista": 794065,
     "precio_publico": 1499900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724C404",
     "precio_lista": 688182,
     "precio_publico": 1299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724C405",
     "precio_lista": 688182,
     "precio_publico": 1299900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D400",
     "precio_lista": 764105,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D401",
     "precio_lista": 818727,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D402",
     "precio_lista": 764105,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D403",
     "precio_lista": 818727,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D404",
     "precio_lista": 740594,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D405",
     "precio_lista": 764105,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D406",
     "precio_lista": 818727,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D407",
     "precio_lista": 818727,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D408",
     "precio_lista": 818727,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D409",
     "precio_lista": 818727,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D410",
     "precio_lista": 1037214,
     "precio_publico": 1898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D411",
     "precio_lista": 982592,
     "precio_publico": 1798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "724D412",
     "precio_lista": 982592,
     "precio_publico": 1798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7271003",
     "precio_lista": 378930,
     "precio_publico": 874900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7272028",
     "precio_lista": 305330,
     "precio_publico": 569900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7273007",
     "precio_lista": 370176,
     "precio_publico": 815900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7275200",
     "precio_lista": 306000,
     "precio_publico": 682900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7275202",
     "precio_lista": 306000,
     "precio_publico": 682900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7275302",
     "precio_lista": 330900,
     "precio_publico": 839900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7275306",
     "precio_lista": 351012,
     "precio_publico": 767900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7275307",
     "precio_lista": 307135,
     "precio_publico": 707900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7276104",
     "precio_lista": 333000,
     "precio_publico": 1006900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7276105",
     "precio_lista": 341000,
     "precio_publico": 1006900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7276108",
     "precio_lista": 381111,
     "precio_publico": 1079900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7276300",
     "precio_lista": 325555,
     "precio_publico": 862900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7276302",
     "precio_lista": 440000,
     "precio_publico": 1150900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7276303",
     "precio_lista": 264000,
     "precio_publico": 705900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7277100",
     "precio_lista": 316000,
     "precio_publico": 589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7277101",
     "precio_lista": 367000,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7277102",
     "precio_lista": 576000,
     "precio_publico": 968900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7277103",
     "precio_lista": 474000,
     "precio_publico": 819900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7277105",
     "precio_lista": 718000,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7277106",
     "precio_lista": 464000,
     "precio_publico": 878900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7277401",
     "precio_lista": 408272,
     "precio_publico": 789900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7278102",
     "precio_lista": 357000,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7278103",
     "precio_lista": 323000,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7278104",
     "precio_lista": 315000,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7278105",
     "precio_lista": 532000,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7278110",
     "precio_lista": 417777,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7278300",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7278301",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7278302",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7278304",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7278307",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727A204",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727A205",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727A208",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727A209",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727A400",
     "precio_lista": 422947,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727A401",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727A402",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727A404",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727A407",
     "precio_lista": 740594,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727B200",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727B201",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727B202",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727B203",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727B204",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727B205",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727B400",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727B401",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727B402",
     "precio_lista": 793535,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727B404",
     "precio_lista": 793535,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E200",
     "precio_lista": 952359,
     "precio_publico": 1798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E201",
     "precio_lista": 846476,
     "precio_publico": 1598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E202",
     "precio_lista": 952358,
     "precio_publico": 1798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E203",
     "precio_lista": 899418,
     "precio_publico": 1698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E400",
     "precio_lista": 793535,
     "precio_publico": 1498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E401",
     "precio_lista": 683418,
     "precio_publico": 1290900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E402",
     "precio_lista": 846476,
     "precio_publico": 1598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E403",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E404",
     "precio_lista": 475888,
     "precio_publico": 898900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E405",
     "precio_lista": 740594,
     "precio_publico": 1398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E406",
     "precio_lista": 846476,
     "precio_publico": 1598900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E407",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E408",
     "precio_lista": 630476,
     "precio_publico": 1190900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727E409",
     "precio_lista": 687653,
     "precio_publico": 1298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727F101",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727F102",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727F103",
     "precio_lista": 993276,
     "precio_publico": 1298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727F104",
     "precio_lista": 449947,
     "precio_publico": 849900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727F105",
     "precio_lista": 439359,
     "precio_publico": 829900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727F106",
     "precio_lista": 449947,
     "precio_publico": 849900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "727F107",
     "precio_lista": 449947,
     "precio_publico": 849900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7295004",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7298002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7298004",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7298006",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7298010",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729A000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729A003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729A005",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729A006",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729A007",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729B000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729B004",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729B013",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729C000",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729C001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729C002",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729C004",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729C005",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729C006",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D002",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D003",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D006",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D007",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D009",
     "precio_lista": 212337,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D012",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729E000",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729E003",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729E006",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729E008",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729E009",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "737F100",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "757F101",
     "precio_lista": 344065,
     "precio_publico": 649900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7748100",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791C470",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D021",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D022",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D025",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E002",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E009",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E012",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E013",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E014",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791F006",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7928010",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792C472",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E009",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E010",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792F000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "794A402",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7998010",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7998011",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799B010",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799B350",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799B352",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C020",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C023",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C024",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D020",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D021",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D022",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D023",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D024",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E006",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E007",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E020",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804A100",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804B200",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C202",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C300",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C301",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C302",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C400",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C403",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C404",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C405",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D100",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D101",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D102",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D200",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D201",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D203",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804D400",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "820B000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "820E000",
     "precio_lista": 109650,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "822E000",
     "precio_lista": 150458,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824C201",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824C401",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824D402",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0015000",
     "precio_lista": 80868,
     "precio_publico": 148900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0018003",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001B000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001B003",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001C000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001D000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001D001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001E000",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001E001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001E002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001F000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001F001",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "001F002",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002B000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002B001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002B002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002C000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002D000",
     "precio_lista": 148643,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002D001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002D002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002E000",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "002E002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0038000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0038002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0096000",
     "precio_lista": 124859,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0098001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009A000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009A004",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009B000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009B400",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009C000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009C001",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009C002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009C003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009C004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009D000",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009D001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009D002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009D003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009D004",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009E000",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009E001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009E002",
     "precio_lista": 116824,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "009E003",
     "precio_lista": 214117,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0101000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0101001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0101002",
     "precio_lista": 66653,
     "precio_publico": 125900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0115007",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011A000",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011A001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011B001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011D000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011D001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011D002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011E000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011E001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011E002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011F000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011F001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "011F002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0126000",
     "precio_lista": 40678,
     "precio_publico": 74900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0126001",
     "precio_lista": 41765,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0126002",
     "precio_lista": 45023,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012B000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012B001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012B002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012D000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012D001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012D002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "012E002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0135000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0136003",
     "precio_lista": 41765,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0137002",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0138000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0138001",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0138003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0138005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "013A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "013A003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0147201",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0147202",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0147300",
     "precio_lista": 14241,
     "precio_publico": 26900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0147301",
     "precio_lista": 14241,
     "precio_publico": 26900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0147302",
     "precio_lista": 14241,
     "precio_publico": 26900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "014A200",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "015D000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "015D001",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "015D002",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0195000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0195002",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0195004",
     "precio_lista": 40135,
     "precio_publico": 73900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0196001",
     "precio_lista": 41765,
     "precio_publico": 76900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0196003",
     "precio_lista": 45023,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0197000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0198001",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0198002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0198003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0198004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0198005",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019A000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019A001",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019C000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019C001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019C002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019C003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019D000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019D001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019D002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019D003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019E000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019E001",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "019E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0218003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0218004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021A001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021A003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021B001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021B002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021B004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021C002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021D000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021D001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021D002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021D003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021E000",
     "precio_lista": 68764,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021E001",
     "precio_lista": 64926,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021E002",
     "precio_lista": 71541,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021E003",
     "precio_lista": 66116,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021F000",
     "precio_lista": 75298,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021F001",
     "precio_lista": 75298,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021F002",
     "precio_lista": 67970,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "021F003",
     "precio_lista": 69502,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0228001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022B000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022B004",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022C001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022C002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022D000",
     "precio_lista": 71063,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022D001",
     "precio_lista": 64552,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022D002",
     "precio_lista": 64552,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022E000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022E002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "022E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0237004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0238000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0238002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0298002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0298003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0298004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0298005",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029A000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029C000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029C001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029C002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029C003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029C004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029C005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029D000",
     "precio_lista": 71134,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029D001",
     "precio_lista": 64647,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029D002",
     "precio_lista": 64647,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029D003",
     "precio_lista": 71134,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029D004",
     "precio_lista": 62800,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029D005",
     "precio_lista": 67507,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029E000",
     "precio_lista": 70368,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029E001",
     "precio_lista": 64943,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029E002",
     "precio_lista": 68786,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029E003",
     "precio_lista": 64943,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029E004",
     "precio_lista": 66134,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "029E005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031A002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031B000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031B001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031B003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031C000",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031C001",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031C003",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031C004",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031C005",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031D000",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031D001",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031D002",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031D003",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031D004",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031D005",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031D006",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031E000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031E001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031E002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031E003",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031E004",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031E005",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031F000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031F001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031F002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031F003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031F004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031F005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "031F006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0325000",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0328003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0328005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032A002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032B004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032C000",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032C001",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032C002",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032C003",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032C004",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032C005",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032D000",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032D001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032D002",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032D003",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032D004",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032D005",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032D006",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032D007",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032E005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032E006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "032E007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "033A001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "033A006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "034C300",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "034C301",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C102",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C107",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C110",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C112",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C202",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C203",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C204",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C205",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C208",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C211",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C212",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C213",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C214",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C402",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C403",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C404",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C406",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C407",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035D100",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035D101",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035D102",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035D103",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035D104",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035D105",
     "precio_lista": 49182,
     "precio_publico": 92900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035E000",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035E001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035E002",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035E003",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035E004",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0398000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0398003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0398006",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039A000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039A003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039A005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039B000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039B001",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039C000",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039C001",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039C002",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039C003",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039C004",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039C005",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039C006",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039C007",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039D000",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039D001",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039D002",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039D003",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039D004",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039D005",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039D006",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039D007",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039D008",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039D009",
     "precio_lista": 51300,
     "precio_publico": 96900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E002",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E003",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E005",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E006",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E007",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E008",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E009",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E920",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E921",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E922",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "039E923",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "050A000",
     "precio_lista": 14241,
     "precio_publico": 26900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "050A001",
     "precio_lista": 17914,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051A000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051A002",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051A004",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051B000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051B001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051B002",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051B003",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051B005",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051B007",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051C000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051C003",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051C005",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051C006",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051D000",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051D001",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051D002",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051D003",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051D004",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051D005",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051D006",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051D007",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051E000",
     "precio_lista": 13712,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051E001",
     "precio_lista": 13712,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051E002",
     "precio_lista": 13712,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051E003",
     "precio_lista": 13712,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051E004",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051E005",
     "precio_lista": 19915,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051E006",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F000",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F001",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F002",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F003",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F004",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F005",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F006",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F007",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F008",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F009",
     "precio_lista": 21006,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F010",
     "precio_lista": 21006,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F011",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "051F012",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0528000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0528006",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0528007",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052A006",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052A008",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052B004",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052B005",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052B006",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052B007",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052B008",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052B009",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052C000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052C001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052C002",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052C003",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052C004",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052C005",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052C006",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052C007",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052D000",
     "precio_lista": 13712,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052D001",
     "precio_lista": 13712,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052D002",
     "precio_lista": 13712,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052D003",
     "precio_lista": 13712,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052D004",
     "precio_lista": 36245,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052D005",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052D006",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052D007",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052E000",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052E001",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052E002",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052E003",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052E004",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052E005",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052E006",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "052E007",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0537007",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0538001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0538003",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0538005",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "053A004",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "053A005",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "053A006",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "053A008",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C100",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C101",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C109",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C110",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C115",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C117",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C121",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C200",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C203",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C204",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C205",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C212",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C215",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C218",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C219",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C220",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C223",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055C228",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D100",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D102",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D103",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D104",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D105",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D106",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D107",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D108",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D109",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D110",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D112",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D113",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055D114",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055E000",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055E001",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055E002",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055E003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055E004",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055E005",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055E006",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "055E007",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0597002",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0598000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0598003",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0598004",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0598006",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0598008",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0598009",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0598010",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059A001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059A002",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059A003",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059A004",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059A007",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059A008",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059C000",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059C001",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059C002",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059C003",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059C004",
     "precio_lista": 12124,
     "precio_publico": 22900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059C005",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059C006",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059C007",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059C008",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059C009",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059D000",
     "precio_lista": 14244,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059D001",
     "precio_lista": 14244,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059D002",
     "precio_lista": 14244,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059D003",
     "precio_lista": 14244,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059D004",
     "precio_lista": 14244,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059D005",
     "precio_lista": 14244,
     "precio_publico": 25900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059D006",
     "precio_lista": 38809,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059D007",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059D008",
     "precio_lista": 21367,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059D009",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059E000",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059E001",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059E002",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059E003",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059E004",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059E005",
     "precio_lista": 15300,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059E006",
     "precio_lista": 35965,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059E007",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059E008",
     "precio_lista": 21006,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "059E009",
     "precio_lista": 20594,
     "precio_publico": 38900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0647200",
     "precio_lista": 200000,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0647202",
     "precio_lista": 181000,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0647203",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D203",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D205",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "064D300",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0676201",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "069E000",
     "precio_lista": 342043,
     "precio_publico": 589900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "090A100",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "090A101",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "090A102",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "090A103",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "090A104",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "090A105",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0917000",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0917003",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0918002",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091A000",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091A002",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091A003",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091A005",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091B002",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091C000",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091C001",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091C002",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091C003",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091D000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091D001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091D002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091D003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091E000",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091E001",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091E002",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091E003",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091F000",
     "precio_lista": 34430,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091F001",
     "precio_lista": 34430,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "091F002",
     "precio_lista": 34430,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0928000",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0928003",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092A000",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092B000",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092B001",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092B003",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092C000",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092C001",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092C002",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092C003",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092D000",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092D001",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092D002",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092D003",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092E000",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092E001",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092E002",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "092E003",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0938003",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0938004",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "093A000",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "093A002",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C200",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C201",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C202",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C203",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C204",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C205",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C206",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C207",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C208",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C209",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C210",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "094C211",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0996005",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0997002",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0998001",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0998004",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0998005",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099A000",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099A001",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099A002",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099A003",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099A004",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099B005",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099B006",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099C000",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099C001",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099C002",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099C003",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099C004",
     "precio_lista": 34359,
     "precio_publico": 64900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099D000",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099D001",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099D002",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099D003",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099D004",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099D005",
     "precio_lista": 40182,
     "precio_publico": 75900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099E000",
     "precio_lista": 33829,
     "precio_publico": 63900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099E001",
     "precio_lista": 33829,
     "precio_publico": 63900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099E002",
     "precio_lista": 33829,
     "precio_publico": 63900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099E003",
     "precio_lista": 33829,
     "precio_publico": 63900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099E004",
     "precio_lista": 33829,
     "precio_publico": 63900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099E005",
     "precio_lista": 33829,
     "precio_publico": 63900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "099E006",
     "precio_lista": 33829,
     "precio_publico": 63900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "104C000",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "1058000",
     "precio_lista": 4834,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2018001",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "201A001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "201A005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "201B002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2027002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "202A004",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "202B000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "202B002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "202C000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "202C001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2037005",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "203A001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "203A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2095004",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2096004",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2096005",
     "precio_lista": 152015,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2096007",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2098001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2098006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "209A000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "209A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "209A002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "209A003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "209A004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "209C000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "209C001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2118002",
     "precio_lista": 44947,
     "precio_publico": 84900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211A000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211A002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211B000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211B002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211C000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211C001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211C002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211D000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211D001",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211D002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211E000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211E001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211E002",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211F000",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "211F001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2126000",
     "precio_lista": 45023,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2126001",
     "precio_lista": 43394,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2126002",
     "precio_lista": 45023,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2128000",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2128002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212A001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212A002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212B000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212B001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212B002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212C000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212C001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212C002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212D000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212D001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212E000",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "212E001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2137000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2138001",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2138002",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "213A000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "213A001",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "213A002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "213A003",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2145100",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2145101",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2145102",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2145103",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2145200",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2145202",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2196003",
     "precio_lista": 48825,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2197003",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2198002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2198003",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219A000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219A001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219A002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219C000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219C001",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219C002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219D000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219D001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219D002",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219E000",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219E001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "219E002",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2228000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2236003",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2298000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "250D000",
     "precio_lista": 17475,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2518000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251A003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251B001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251B003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251C001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251C002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251D000",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251D001",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251D002",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251D003",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251E000",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251E001",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251E002",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251E003",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251E004",
     "precio_lista": 18552,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251F000",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251F001",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251F002",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251F003",
     "precio_lista": 20122,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251F004",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "251F005",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2528000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2528001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2528003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252A000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252A004",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252B000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252B002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252B003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252C000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252C001",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252C002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252C003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252C004",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252C005",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252D000",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252D001",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252D002",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252D003",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252D004",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252E000",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252E001",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252E002",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252E003",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "252E004",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2537000",
     "precio_lista": 13182,
     "precio_publico": 24900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2537001",
     "precio_lista": 9476,
     "precio_publico": 17900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "253A000",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "253A003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2597000",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2598001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2598002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2598004",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2598005",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259A000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259A001",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259A002",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259A003",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259A004",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259A006",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259A060",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259C000",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259C001",
     "precio_lista": 26418,
     "precio_publico": 49900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259C002",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259C003",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259C004",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259C005",
     "precio_lista": 17418,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259C006",
     "precio_lista": 22712,
     "precio_publico": 42900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259D000",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259D001",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259D002",
     "precio_lista": 20473,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259D003",
     "precio_lista": 17904,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259D004",
     "precio_lista": 19602,
     "precio_publico": 32900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259E000",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259E001",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259E002",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259E003",
     "precio_lista": 20122,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "259E004",
     "precio_lista": 19006,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261C000",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261C001",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261C002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261C003",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261D000",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261D001",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261D002",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261D003",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261D004",
     "precio_lista": 179947,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261E000",
     "precio_lista": 252158,
     "precio_publico": 439900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261E001",
     "precio_lista": 253959,
     "precio_publico": 439900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261E002",
     "precio_lista": 229556,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261E003",
     "precio_lista": 229557,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261E004",
     "precio_lista": 231143,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261F000",
     "precio_lista": 243476,
     "precio_publico": 459900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261F001",
     "precio_lista": 222300,
     "precio_publico": 419900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261F002",
     "precio_lista": 254065,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "261F003",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262B200",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262B201",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262B202",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262C000",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262C001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262C002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262D000",
     "precio_lista": 206632,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262D001",
     "precio_lista": 224026,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262D002",
     "precio_lista": 190697,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262D003",
     "precio_lista": 191339,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262E000",
     "precio_lista": 272817,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262E001",
     "precio_lista": 240945,
     "precio_publico": 409900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262E002",
     "precio_lista": 234312,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "262E003",
     "precio_lista": 290159,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2646100",
     "precio_lista": 165000,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2646102",
     "precio_lista": 165000,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2646300",
     "precio_lista": 206325,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2646301",
     "precio_lista": 190032,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2647101",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2647400",
     "precio_lista": 152958,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2647401",
     "precio_lista": 194381,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2647402",
     "precio_lista": 215714,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2647403",
     "precio_lista": 215714,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2647404",
     "precio_lista": 175815,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2647405",
     "precio_lista": 145815,
     "precio_publico": 275900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2648201",
     "precio_lista": 163401,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2648205",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2648206",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2648300",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264A107",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264A200",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264A201",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264A202",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264A203",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264A300",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264A301",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264A302",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264A303",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264A305",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264B100",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264B101",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "264B102",
     "precio_lista": 185241,
     "precio_publico": 349900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269C000",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269C001",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269C002",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269C003",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269D000",
     "precio_lista": 282294,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269D001",
     "precio_lista": 233526,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269D002",
     "precio_lista": 193039,
     "precio_publico": 339900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269D003",
     "precio_lista": 216076,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269D004",
     "precio_lista": 241998,
     "precio_publico": 439900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269E000",
     "precio_lista": 277616,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269E001",
     "precio_lista": 230356,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269E002",
     "precio_lista": 234312,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269E003",
     "precio_lista": 238267,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269E004",
     "precio_lista": 285520,
     "precio_publico": 479900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "269E005",
     "precio_lista": 255401,
     "precio_publico": 439900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281C002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281C003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281D001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281D002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281D003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281E000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281E001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281E002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281E003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281F000",
     "precio_lista": 44717,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281F001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281F002",
     "precio_lista": 45321,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "281F003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2828001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2828003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282C001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282C002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282C003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282D001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282D002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282D003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282E000",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282E001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282E002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "282E003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "283A003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2846301",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2846302",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2846303",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2846304",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2898000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2898001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2898005",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289A002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289A005",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289C000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289C001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289C002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289C003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289C004",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289C005",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289D000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289D001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289D002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289D003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289E000",
     "precio_lista": 42958,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289E001",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289E002",
     "precio_lista": 44549,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "289E003",
     "precio_lista": 31712,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2915001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2915005",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2918000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2918005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291A000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291A001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291A003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291A005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291B000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291B002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291C000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291C001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291C002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291C003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "291C004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2928001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2928005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292A000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292A001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292A002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292A004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292B000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292B002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292B003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292B004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292C000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292C001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292C002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292C003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "292C004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2937002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2937004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2938000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2938002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2938003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2938004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2938005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "293A001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "293A002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "293A004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2997001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2997003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2997004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2998000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2998001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2998002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2998003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2998004",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2998005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2998006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2998007",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299A000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299A001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299A002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299A003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299A005",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299A006",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299C000",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299C001",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299C002",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299C003",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "299C004",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314E003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314E004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "314E005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "320E802",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419D004",
     "precio_lista": 216542,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "419D005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429D004",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429D005",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "429D006",
     "precio_lista": 280766,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "434A403",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5033002",
     "precio_lista": 65118,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5033006",
     "precio_lista": 92273,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "504C206",
     "precio_lista": 8947,
     "precio_publico": 16900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5093012",
     "precio_lista": 53713,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5094003",
     "precio_lista": 108023,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5111012",
     "precio_lista": 39592,
     "precio_publico": 72900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5112005",
     "precio_lista": 34161,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5124000",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5124001",
     "precio_lista": 40135,
     "precio_publico": 73900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5131014",
     "precio_lista": 23299,
     "precio_publico": 42900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5132000",
     "precio_lista": 37420,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5133015",
     "precio_lista": 37420,
     "precio_publico": 68900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5134003",
     "precio_lista": 37963,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5147203",
     "precio_lista": 4101,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5147204",
     "precio_lista": 1401,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5147207",
     "precio_lista": 4601,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5147208",
     "precio_lista": 1601,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5147209",
     "precio_lista": 1601,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148300",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148301",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148302",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148303",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148305",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148306",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148307",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148308",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148309",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148310",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148311",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148315",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148317",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148320",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148322",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148323",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148324",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148325",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148326",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148327",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148329",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148330",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148332",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148400",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148401",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148402",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148403",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148404",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148405",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148406",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148407",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148408",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A104",
     "precio_lista": 8995,
     "precio_publico": 16990
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A105",
     "precio_lista": 9524,
     "precio_publico": 17990
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A106",
     "precio_lista": 7406,
     "precio_publico": 13990
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A107",
     "precio_lista": 15877,
     "precio_publico": 29990
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A108",
     "precio_lista": 8995,
     "precio_publico": 16990
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A109",
     "precio_lista": 7936,
     "precio_publico": 14990
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A110",
     "precio_lista": 11642,
     "precio_publico": 21990
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A400",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A401",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A402",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A403",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A404",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A405",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A406",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A407",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A408",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A409",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514A410",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B100",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B102",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B103",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B104",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B105",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B106",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B107",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B108",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B109",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B110",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B111",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B112",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B113",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B114",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B115",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B116",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B117",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B118",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B119",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B120",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B121",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B122",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B123",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B124",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B125",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B126",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B127",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B128",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B129",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B130",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B131",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B132",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B133",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B134",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B135",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B136",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B137",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B138",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B139",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B140",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B141",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B142",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B143",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B144",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B145",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B146",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B147",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B148",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B149",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B150",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B151",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B152",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B153",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B154",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B155",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B156",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B157",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B158",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B159",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B160",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B161",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B162",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B165",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B166",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B167",
     "precio_lista": 14241,
     "precio_publico": 26900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B268",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C000",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C200",
     "precio_lista": 12653,
     "precio_publico": 23900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C201",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C202",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C203",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C204",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C205",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C206",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C207",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C208",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C209",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C210",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C211",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C212",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C213",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C214",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C215",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C216",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C217",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C218",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C219",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C220",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C221",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C222",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C223",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C224",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C225",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C226",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C227",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C228",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C229",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C230",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C231",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C232",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C233",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C234",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C235",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C300",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C301",
     "precio_lista": 29328,
     "precio_publico": 34900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C336",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C337",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C338",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C339",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C340",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C341",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C342",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C343",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C344",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C345",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C346",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C347",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C348",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C349",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C350",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C351",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C352",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C353",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C354",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C355",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C356",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C357",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C358",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C359",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C360",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C361",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C362",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C363",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C364",
     "precio_lista": 6300,
     "precio_publico": 11900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C365",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C366",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C367",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C368",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514C369",
     "precio_lista": 5241,
     "precio_publico": 9900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D200",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D201",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D202",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D203",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D204",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D205",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D206",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D207",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D208",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D209",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D210",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D211",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D212",
     "precio_lista": 8418,
     "precio_publico": 15900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D213",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D214",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D215",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D216",
     "precio_lista": 10006,
     "precio_publico": 18900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D217",
     "precio_lista": 6829,
     "precio_publico": 12900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D218",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D219",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D220",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D221",
     "precio_lista": 4182,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D222",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D223",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D224",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D225",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D226",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D227",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D228",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D229",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D230",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D231",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D232",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D233",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D234",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D235",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D236",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D237",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D238",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D239",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D240",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D241",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D242",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D243",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D244",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D245",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D246",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D247",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D248",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D256",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D257",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D258",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D259",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D260",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D261",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D262",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D263",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D264",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D265",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D266",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D267",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D272",
     "precio_lista": 24900,
     "precio_publico": 24900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D273",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D274",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D275",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D276",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D277",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D278",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D279",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D280",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D281",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D282",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D283",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D284",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D285",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D286",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D287",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D288",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D289",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D290",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D293",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D294",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D295",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D296",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D297",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D298",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D299",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D300",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D301",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D302",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D303",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D313",
     "precio_lista": 5771,
     "precio_publico": 10900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D314",
     "precio_lista": 3653,
     "precio_publico": 6900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D315",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D316",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D317",
     "precio_lista": 3124,
     "precio_publico": 5900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D318",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D319",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D320",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D321",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514D322",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E301",
     "precio_lista": 29328,
     "precio_publico": 34900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E323",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E324",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E325",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E326",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E327",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E328",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E329",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E330",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E331",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E332",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E333",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E334",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E335",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E336",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E337",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E338",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E339",
     "precio_lista": 2594,
     "precio_publico": 4900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E340",
     "precio_lista": 2065,
     "precio_publico": 3900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E342",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E343",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E344",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E345",
     "precio_lista": 22182,
     "precio_publico": 41900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E346",
     "precio_lista": 22182,
     "precio_publico": 41900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E347",
     "precio_lista": 22182,
     "precio_publico": 41900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E348",
     "precio_lista": 4712,
     "precio_publico": 8900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E349",
     "precio_lista": 22182,
     "precio_publico": 41900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E350",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E351",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E352",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E353",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E354",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E355",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E356",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E357",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514E999",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "524D400",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "524D401",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "524D402",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "524D403",
     "precio_lista": 7888,
     "precio_publico": 14900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "524D404",
     "precio_lista": 52888,
     "precio_publico": 99900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "524D405",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5394007",
     "precio_lista": 30903,
     "precio_publico": 56900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5445000",
     "precio_lista": 3259,
     "precio_publico": 6000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5445001",
     "precio_lista": 5974,
     "precio_publico": 11000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5445002",
     "precio_lista": 4291,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5445003",
     "precio_lista": 4291,
     "precio_publico": 7900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5501059",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5501060",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5501061",
     "precio_lista": 10535,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5743400",
     "precio_lista": 12934,
     "precio_publico": 25000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5744100",
     "precio_lista": 10808,
     "precio_publico": 19900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5746200",
     "precio_lista": 21670,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5746400",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5747400",
     "precio_lista": 20428,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5747401",
     "precio_lista": 20428,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5748402",
     "precio_lista": 15829,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "574A403",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "574A404",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "574C200",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5774400",
     "precio_lista": 741443,
     "precio_publico": 1187900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5774401",
     "precio_lista": 741443,
     "precio_publico": 1187900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5846100",
     "precio_lista": 16239,
     "precio_publico": 29900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5901058",
     "precio_lista": 24829,
     "precio_publico": 46900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5901060",
     "precio_lista": 24829,
     "precio_publico": 46900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5901061",
     "precio_lista": 24829,
     "precio_publico": 46900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5932003",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5972110",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5992010",
     "precio_lista": 22756,
     "precio_publico": 41900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5994001",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5994003",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5994004",
     "precio_lista": 32532,
     "precio_publico": 59900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5994009",
     "precio_lista": 24928,
     "precio_publico": 45900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6037006",
     "precio_lista": 50241,
     "precio_publico": 94900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6048000",
     "precio_lista": 30000,
     "precio_publico": 35900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6048001",
     "precio_lista": 24210,
     "precio_publico": 28900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "604E200",
     "precio_lista": 44600,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "604E201",
     "precio_lista": 36386,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A027",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A028",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A029",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605A030",
     "precio_lista": 56594,
     "precio_publico": 106900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C013",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C014",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C015",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C016",
     "precio_lista": 59771,
     "precio_publico": 112900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C207",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C208",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C209",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C210",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C307",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C308",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C309",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C310",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C311",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605C312",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D123",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D124",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D125",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D126",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D129",
     "precio_lista": 63000,
     "precio_publico": 119000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D130",
     "precio_lista": 63000,
     "precio_publico": 119000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D131",
     "precio_lista": 63000,
     "precio_publico": 119000
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D236",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D237",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D240",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D243",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D244",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D245",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D246",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D247",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D251",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D255",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605D256",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E120",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E121",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E122",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "605E123",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "610C004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "610E004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "611B139",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B013",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B018",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B034",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612B035",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D014",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "612D039",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "614D200",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615B000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615B001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615B002",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615B003",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C300",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C301",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C302",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C304",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C305",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C306",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C308",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C309",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C311",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C312",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C313",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C314",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C315",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C316",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C317",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615C318",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D204",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D205",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D206",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D207",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D208",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D209",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D210",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D211",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D213",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D214",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D215",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D216",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D217",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D218",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D219",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D220",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D221",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D227",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D228",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D229",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D230",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D231",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D232",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D233",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D234",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D235",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615D236",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "615E109",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C032",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C043",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C045",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "619C046",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "621D013",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6228005",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6228007",
     "precio_lista": 201124,
     "precio_publico": 379900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "622D011",
     "precio_lista": 232888,
     "precio_publico": 439900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6238006",
     "precio_lista": 190535,
     "precio_publico": 359900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625D100",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625D101",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625D203",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625D204",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625D205",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625D206",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625D207",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "625D208",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F250",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F251",
     "precio_lista": 264124,
     "precio_publico": 498900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F252",
     "precio_lista": 227594,
     "precio_publico": 429900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F253",
     "precio_lista": 211182,
     "precio_publico": 398900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "634A400",
     "precio_lista": 206418,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635B001",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635B002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635B003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635B004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "635E104",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645B004",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D224",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D226",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D229",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D232",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D233",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "645D238",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E100",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E101",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "685E102",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6916000",
     "precio_lista": 86842,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6917002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6918000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6918004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6918005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6918006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691A002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691B001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691B002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691C000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691C001",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691C003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691C004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691C005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691D000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691D001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691D002",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691D003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691D004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691D005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691E001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691E002",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691E003",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691F000",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691F001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691F002",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691F003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691F006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691F007",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "691F008",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6927004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6928003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6928005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692A000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692A003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692A004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692B004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692B005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692C000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692C001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692C002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692C003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692C004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692C005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692D000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692D001",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692D002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692D003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692D004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692D005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692E000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692E001",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692E002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692E003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692E004",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692E005",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6937000",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6937001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6937002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6937003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6937006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6938001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "693A004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "693A005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "693A006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "695D001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "695D002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "695D003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "695D004",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "695D005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6997005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6997006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6997007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6998000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6998001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6998002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6998003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6998004",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6998006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6998007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699A000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699A001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699A002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699A004",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699A005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699A006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699A007",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699B002",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699B004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699B005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699B006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699C000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699C001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699C002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699C003",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699C004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699C005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699C006",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699D002",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699D003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699D004",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699D005",
     "precio_lista": 148182,
     "precio_publico": 279900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699D006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699E002",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699E003",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699E004",
     "precio_lista": 164065,
     "precio_publico": 309900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699E005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "699E006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7048000",
     "precio_lista": 29000,
     "precio_publico": 34900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7048001",
     "precio_lista": 23150,
     "precio_publico": 27900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704E200",
     "precio_lista": 42260,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "704E201",
     "precio_lista": 35386,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D036",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D037",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "709D038",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "710E001",
     "precio_lista": 128244,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E051",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "711E052",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "719C023",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722D005",
     "precio_lista": 213523,
     "precio_publico": 389900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722D011",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "722D012",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "725D000",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "725D001",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "725D002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "725D004",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D011",
     "precio_lista": 195829,
     "precio_publico": 369900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D013",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D014",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729D015",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "729E005",
     "precio_lista": 259359,
     "precio_publico": 489900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7918005",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791B000",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791B002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791C000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791C001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791C003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791C004",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791C005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791C007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D001",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D004",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D005",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D006",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D008",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791D009",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E000",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E007",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E008",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E010",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791E011",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791F000",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791F001",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791F002",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791F003",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791F004",
     "precio_lista": 169359,
     "precio_publico": 319900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791F005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791F007",
     "precio_lista": 158241,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791F008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "791F009",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7928004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7928005",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7928254",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7928259",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792B001",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792B003",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792B005",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792B250",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792C000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792C001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792C002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792C003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792C004",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792C005",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792C006",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792C007",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792D000",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792D001",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792D002",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792D003",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792D004",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792D005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792D006",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792D007",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792D008",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E000",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E001",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E003",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E005",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E006",
     "precio_lista": 153476,
     "precio_publico": 289900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "792E008",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7938002",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7938003",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "793A000",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "793A002",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "793B009",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7997003",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7998005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "7998006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799A002",
     "precio_lista": 79359,
     "precio_publico": 149900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799A003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799A004",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799A005",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799A006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799A351",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799A353",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799A355",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799A356",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799B000",
     "precio_lista": 111124,
     "precio_publico": 209900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799B002",
     "precio_lista": 105300,
     "precio_publico": 198900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799B361",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C000",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C001",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C002",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C003",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C005",
     "precio_lista": 121712,
     "precio_publico": 229900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C006",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C007",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799C008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D000",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D001",
     "precio_lista": 142359,
     "precio_publico": 268900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D002",
     "precio_lista": 132300,
     "precio_publico": 249900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D003",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D004",
     "precio_lista": 95241,
     "precio_publico": 179900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D005",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D006",
     "precio_lista": 142888,
     "precio_publico": 269900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D007",
     "precio_lista": 127006,
     "precio_publico": 239900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D008",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799D010",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E000",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E001",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E002",
     "precio_lista": 174653,
     "precio_publico": 329900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E003",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E004",
     "precio_lista": 116418,
     "precio_publico": 219900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E008",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E009",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "799E010",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804B201",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804B202",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804B203",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C201",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C203",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "804C204",
     "precio_lista": 33300,
     "precio_publico": 62900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "8148301",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814B200",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814B201",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814C200",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814C201",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814C300",
     "precio_lista": 46006,
     "precio_publico": 86900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814C301",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "814C401",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "820C001",
     "precio_lista": 84653,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "820D001",
     "precio_lista": 94932,
     "precio_publico": 159900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824A100",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824B200",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824B201",
     "precio_lista": 74065,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "824C200",
     "precio_lista": 74064,
     "precio_publico": 139900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "831E101",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "831E102",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "831E103",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "831F000",
     "precio_lista": 76783,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "831F002",
     "precio_lista": 76783,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "831F003",
     "precio_lista": 76783,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "832E001",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "832E002",
     "precio_lista": 75495,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "832E003",
     "precio_lista": 76412,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "832E004",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834A101",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834A302",
     "precio_lista": 47594,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834B203",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C200",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C201",
     "precio_lista": 56065,
     "precio_publico": 105900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C202",
     "precio_lista": 56065,
     "precio_publico": 105900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C203",
     "precio_lista": 56065,
     "precio_publico": 105900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C204",
     "precio_lista": 56065,
     "precio_publico": 105900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C300",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C301",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C302",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C400",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C401",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C402",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834C403",
     "precio_lista": 58182,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D100",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D101",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D102",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D103",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D104",
     "precio_lista": 60029,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D201",
     "precio_lista": 65492,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D202",
     "precio_lista": 65492,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D203",
     "precio_lista": 65492,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D204",
     "precio_lista": 65492,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D205",
     "precio_lista": 65492,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D400",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D401",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D402",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "834D403",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "839E003",
     "precio_lista": 76215,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "839E004",
     "precio_lista": 75975,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "839E006",
     "precio_lista": 75735,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "839E007",
     "precio_lista": 75975,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "839E008",
     "precio_lista": 75659,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844A101",
     "precio_lista": 18476,
     "precio_publico": 34900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844A300",
     "precio_lista": 18476,
     "precio_publico": 34900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844A302",
     "precio_lista": 18476,
     "precio_publico": 34900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844A303",
     "precio_lista": 18476,
     "precio_publico": 34900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844B200",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C200",
     "precio_lista": 22712,
     "precio_publico": 42900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C201",
     "precio_lista": 22712,
     "precio_publico": 42900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C203",
     "precio_lista": 19535,
     "precio_publico": 36900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C300",
     "precio_lista": 21124,
     "precio_publico": 39900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C301",
     "precio_lista": 25888,
     "precio_publico": 48900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C302",
     "precio_lista": 25888,
     "precio_publico": 48900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C303",
     "precio_lista": 25888,
     "precio_publico": 48900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C304",
     "precio_lista": 25888,
     "precio_publico": 48900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C400",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C401",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C402",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C403",
     "precio_lista": 22712,
     "precio_publico": 42900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C404",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "844C405",
     "precio_lista": 31182,
     "precio_publico": 58900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "851E100",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "851F000",
     "precio_lista": 64961,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "852E000",
     "precio_lista": 67325,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "8548300",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "8548301",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854A100",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854A301",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854A302",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854B200",
     "precio_lista": 37006,
     "precio_publico": 69900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854C200",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854C203",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854C300",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854C400",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854C402",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D100",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D101",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D202",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D203",
     "precio_lista": 49105,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D401",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "854D404",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "859E000",
     "precio_lista": 67418,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "884A301",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "894A100",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "894A101",
     "precio_lista": 68771,
     "precio_publico": 129900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692F002",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "692F007",
     "precio_lista": 137594,
     "precio_publico": 259900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0228000",
     "precio_lista": 63476,
     "precio_publico": 119900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0228002",
     "precio_lista": 52359,
     "precio_publico": 98900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "0328002",
     "precio_lista": 42300,
     "precio_publico": 79900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "035C400",
     "precio_lista": 43888,
     "precio_publico": 82900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "2198000",
     "precio_lista": 47594,
     "precio_publico": 89900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148319",
     "precio_lista": 1006,
     "precio_publico": 1900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5148321",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "514B164",
     "precio_lista": 1535,
     "precio_publico": 2900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5675201",
     "precio_lista": 181250,
     "precio_publico": 366900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "5874404",
     "precio_lista": 61564,
     "precio_publico": 109900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6928000",
     "precio_lista": 89947,
     "precio_publico": 169900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "6928001",
     "precio_lista": 100535,
     "precio_publico": 189900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "617F206",
     "precio_lista": 190894,
     "precio_publico": 298900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F255",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F256",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F258",
     "precio_lista": 524065,
     "precio_publico": 989900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F260",
     "precio_lista": 557216,
     "precio_publico": 798900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F261",
     "precio_lista": 370006,
     "precio_publico": 698900
    },
    {
     "marca": "CHEVIGNON",
     "ref": "627F262",
     "precio_lista": 422947,
     "precio_publico": 798900
    }
   ]