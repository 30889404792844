<template>
    <footer className="py-5 bg-black">
        <div className="container">
          <div className="row">
            <div className="col-12 d-md-flex d-block justify-content-between">
              <img className="img-fluid mx-auto d-block imgMediana" src="img/marcas.png" alt="Americanino | Chevignon | Esprit"/>
            </div>
            <div className="col-12 mt-3">
              <p className="text-uppercase text-white text-center mb-0">Todos los derechos reservados Americanino | CHEVIGNON | Esprit © 2024</p>
            </div>
          </div>
        </div>
      </footer> 
</template>

<script>

export default {
  name: "Footer",
};
</script>
