import storesJSON from "../stores.json"

const trimArrayObjectsProps = (storesJSON) => {
    const trimmedArray = storesJSON.map(obj =>  
        Object.keys(obj).reduce((acc, key) => {
          acc[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
          return acc;
        }, {})
    );

    return trimmedArray
}

const orderArrayAscByProp = (array, prop) => {
  const orderedArray = array.slice().sort((a, b) => {

    const propA = a[prop].toLowerCase(); // Convertir a minúsculas para una comparación sin distinción entre mayúsculas y minúsculas
    const propB = b[prop].toLowerCase();
  
    if (propA < propB) {
      return -1;
    }
    if (propA > propB) {
      return 1;
    }
    return 0; // Los nombres son iguales
  });

  return orderedArray
}

export const fetchStoresJSON = () => {
     const stores = storesJSON
     const storesTrimmed = trimArrayObjectsProps(stores)
     const storesOrdered = orderArrayAscByProp(storesTrimmed, 'ciudad')
     return storesOrdered
}