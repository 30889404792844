import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Install BootstrapVue
// // Optionally install the BootstrapVue icon components plugin
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// Optionally install the BootstrapVue icon components plugin
createApp(App).use(router).mount('#app')
 