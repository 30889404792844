export const premiosDiccionarioImg = {
    'Combo 1: compra 2 jeans obsequio 1 camisa': 'img/combo01.png',
    'Combo 2: compra 1 jean o un pantalon + 1 camisa obsequio 1 polo': 'img/combo02.png',
    'Combo 3: compra 2 camisas o 2 polos obsequio 1 camiseta': 'img/combo03.png',
    //'Obsequio de la marca': 'img/Obsequio.png',
    '50% descuento: en la segunda prenda de menor valor': 'img/descuento50.png',
    '25% descuento: en todas las referencias': 'img/descuento25.png'
}

export const premiosDiccionario = {
    '50': '50% descuento: en la segunda prenda de menor valor',
    '25': '25% descuento: en todas las referencias',
    //'Obsequio': 'Obsequio de la marca'
}